import {Component, OnInit, ViewChild} from '@angular/core';
import {ModulePracticeSection} from "northstar-foundation";
import {PracticeBaseOverviewSlideComponent} from "../practice-base-overview-slide.component";

/**
 * Only needed as something separate from PracticeBaseOverviewSlideComponent so that section reviews that require
 * an overview slide (e.g. Career Search has a section review where it gives background info on Deka), don't
 * revert back to the responseCollectionService's `default` namespace.
 */
@Component({
  templateUrl: '../base-overview-slide.component.html'
})
export class SectionReviewBaseOverviewSlideComponent extends PracticeBaseOverviewSlideComponent {

  getNamespaceForSlideType() {
    return this.responseCollectionService.getNamespaceForSectionReview(
      <ModulePracticeSection>this.navigation.getCurrentSection()
    );
  }
}
