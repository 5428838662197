import {PipeTransform, Pipe} from "@angular/core";

@Pipe({
  name: 'math'
})
export class MathPipe implements PipeTransform {
  transform(num: number, methodName: string): number {

    if (typeof Math[methodName] !== 'function') {
      throw Error(`No Math function '${methodName}'.`);
    }

    return Math[methodName](num);
  }
}
