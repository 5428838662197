import {FadeInOut} from "../../animations";
import {Component, OnInit, OnDestroy, Input, HostBinding, ElementRef} from "@angular/core";
import {PopoverService} from "../../../core/popover/popover.service";

@Component({
  selector: 'content-bubble',
  templateUrl: './content-bubble.component.html',
  styleUrls: [
    './content-bubble.component.scss'
  ],
  animations: [FadeInOut(400)]
})
export class ContentBubbleComponent implements OnInit, OnDestroy {
  @Input()
  hidden: boolean = true;

  /**
   * Behaves differently if is part of a composite, i.e. popover, vs standalone.
   * @type {boolean}
   */
  @Input()
  standalone: boolean = false;

  @Input()
  staticVisibility: boolean = false;

  /**
   * Whether popover should draw attention to itself at a predefined interval, e.g. jiggle
   *
   * @type {boolean}
   */
  @HostBinding('class.noisy')
  @Input()
  noisy: boolean = true;

  /**
   * Property to easily filter/find in parent components via ViewChildren and .forEach
   * Should only be considered in cases where standalone = true, though, since the parent PopoverComponent
   * would be registering the same ID w/PopoverService.
   */
  @Input()
  id: string;

  constructor(
    public popoverService: PopoverService,
    public elRef: ElementRef,
  ) {
  }

  ngOnInit() {
    if (this.standalone && !this.staticVisibility) {
      this.popoverService.register(this);
    } else {
      // if part of a composite, e.g. popover, never hide this low-level element; popover will control overall appearance
      this.hidden = false;
    }
  }

  ngOnDestroy() {
    if (this.standalone) {
      this.popoverService.unregister(this);
    }
  }

  show() {
    this.hidden = false;
  }

  hide() {
    this.hidden = true;
  }

  scrollIntoView() {
    this.elRef.nativeElement.scrollIntoView();
  }
}
