import {Injectable, Inject} from "@angular/core";
import {LoggerService} from "../services/logger.service";
import {ToastrService} from 'ngx-toastr';
import {ResponseModel, DontKnowVariant} from "./response.model";
import {Subject, Observable} from "rxjs";
import {IAppConfig, APP_CONFIG, IModuleTask} from "../../app.config";
import {countBy, forEach, snakeCase} from "lodash";
import {BootstrapDataService} from "../bootstrap/bootstrap-data.service";
import {ModulePracticeSection, ModulePracticeLesson} from "northstar-foundation";
import {translate, TranslocoService} from "@ngneat/transloco";
import {GoogleTagManagerService} from "northstar-foundation/angular";
import {Angulartics2} from 'angulartics2';

// what's needed by server different that
export interface IResponseSerialized {
  question: number,
  order: number,
  timing: Date,
  success: boolean,
  variant: string
}

@Injectable()
export class ResponseCollectionService {
  private taskToTaskNumHash: any = {};
  private newResponse: Subject<any> = new Subject<any>();
  response$: Observable<any> = this.newResponse.asObservable();

  defaultNamespace: string = 'default';
  currentNamespace: string = null;

  /**
   * Collection of responses. Responses namespaced according to context, e.g. module or section review.
   *
   * @type {{}}
   */
  private responses: any = {};

  constructor(
    protected loggerService: LoggerService,
    protected toastr: ToastrService,
    protected angulartics2: Angulartics2,
    protected gtmService: GoogleTagManagerService,
    protected bootstrapDataService: BootstrapDataService,
    @Inject(APP_CONFIG) protected appConfig: IAppConfig,
    protected translocoService: TranslocoService,
  ) {
    this.responses[this.defaultNamespace] = {};
    this.activateNamespace(this.defaultNamespace);
  }

  /**
   * Associate tasks with a readable number. Optional namespace allows for multiple slide groups from nav service
   * to have independent response collection groups.
   *
   * @param task
   * @param taskReadableNum
   * @param namespace
   */
  public registerTask(task: any, taskReadableNum: number, namespace: string = null) {
    if (!task.id) {
      return;
    }

    const relevantNamespace = namespace ? namespace : this.defaultNamespace;

    if (typeof this.taskToTaskNumHash[relevantNamespace] === 'undefined') {
      this.taskToTaskNumHash[relevantNamespace] = {};
    }

    this.taskToTaskNumHash[relevantNamespace][task.id] = taskReadableNum;
    this.prefillDontKnow(task, relevantNamespace);
  }

  /**
   * A bit of a workaround to the fact that SlideNavigationService checks allSlidesVisited() and if true,
   * navigates to submission-confirmation slide, because has a bug in that at times the user might not have submitted
   * the last slide's answer, e.g. visits last slide, then clicks Back and resubmits (last slide - 1) slide,
   * so API submission fails due to incorrect # of answers provided for the assessment.
   *
   * @param task
   */
  protected prefillDontKnow(task: any, namespace: string) {
    if (typeof this.responses[namespace] === 'undefined') {
      this.responses[namespace] = {};
    }

    if (this.appConfig.debug) {
      // this.loggerService.log([`Prefilling dont know response for namespace ${namespace}`, task]);
    }

    this.responses[namespace][task.id] = new ResponseModel(task, false, DontKnowVariant, null);
  }

  public activateNamespace(namespace: string) {
    this.loggerService.log([`Activating responses namespace ${namespace}`]);
    this.currentNamespace = namespace;
  }

  public getNamespaceForLesson(lesson: ModulePracticeLesson|boolean) {
    if (!lesson) {
      return this.defaultNamespace;
    }

    return `${(<ModulePracticeLesson>lesson).section.slug}-${(<ModulePracticeLesson>lesson).slug}`;
  }

  public getNamespaceForSectionReview(section: ModulePracticeSection) {
    return `${section.slug}-review`;
  }

  public addResponse(
    taskSlideComponent,
    task,
    success: boolean = true,
    variant: string = null, // note server only stores first 64 characters of variant
    meta: any = null // catch-all solution for storing other info about user's interaction/response
  ) {
    const hasAnsweredPreviously = typeof this.responses[task.id] !== 'undefined';
    const previousResponse = this.responses[task.id];

    if (hasAnsweredPreviously && !previousResponse.variantIsDontKnow() && !taskSlideComponent.allowAnswerAgain) {
      this.loggerService.error(`Cannot provide answer for task ${taskSlideComponent.nsComponentId} again.`);
      return;
    }

    // disallow user from changing a previous answer to don't know; otherwise user could get
    // around the allowAnswerAgain = false
    if (hasAnsweredPreviously && !previousResponse.variantIsDontKnow() && variant === DontKnowVariant) {
      this.toastr.error(translate('common.cannotChangeAnswerToDontKnow'));
      return;
    }

    const response = new ResponseModel(task, success, variant, meta);

    if (this.appConfig.debug) {
      this.loggerService.log([success ? 'Correct answer' : 'Incorrect answer', response]);
    }

    this.angulartics2.eventTrack.next({
      action: 'Slide Response',
      properties: {
        category: 'Assessment',
        label: `${this.bootstrapDataService.getModule().id} (${this.currentNamespace}) - ${task.id >= 10 ? task.id : '0' + task.id} - ${task.slug}`,
        value: success ? 100 : (variant === DontKnowVariant ? 0 : 50)
      }
    });

    this.gtmService.trackEvent('slide_response', {
      module: this.bootstrapDataService.getModule().id,
      slide: snakeCase(`${this.bootstrapDataService.getModule().id} (${this.currentNamespace}) - ${task.id >= 10 ? task.id : '0' + task.id} - ${task.slug}`),
      slideCorrect: success,
      lang: this.translocoService.getActiveLang(),
    });

    // store locally
    this.responses[this.currentNamespace][task.id] = response;

    // this.loggerService.log(['Response + current stack of responses', response, this.responses[this.currentNamespace]]);

    // update subject so that observers are notified
    this.newResponse.next(response);
  }

  public addSuccess(
    taskSlideComponent,
    task,
    variant: string = null,
    meta: any = null
  ) {
    this.addResponse(taskSlideComponent, task, true, variant, meta);
  }

  public addFailure(
    taskSlideComponent,
    task,
    variant: string = null,
    meta: any = null
  ) {
    this.addResponse(taskSlideComponent, task, false, variant, meta);
  }

  public addDontKnow(
    taskSlideComponent,
    task,
    meta: any = null,
    event
  ) {
    // in dev mode, count as correct answer if holding Shift, for speedy troubleshooting
    if (this.appConfig.debug && event.shiftKey) {
      this.addSuccess(taskSlideComponent, task, 'troubleshooting', meta);
      return;
    }

    this.addResponse(taskSlideComponent, task, false, DontKnowVariant, meta);
  }

  public getResponeForTask(task): ResponseModel|boolean {
    return this.responses[this.currentNamespace][task.id] || false;
  }

  /**
   * Convenience function for testing. Likely unused in actual production.
   */
  public getNumCorrect() {
    return countBy(this.responses[this.currentNamespace], (responseModel: ResponseModel) => {
      return responseModel.getSuccess() ? 1 : 0;
    })['1'];
  }

  public serialize() {
    let serialized: IResponseSerialized[] = [];

    forEach(this.responses[this.currentNamespace], (response: ResponseModel) => {
      const taskId: number = response.getTask().id;

      serialized.push({
        question: taskId,
        order: this.taskToTaskNumHash[this.currentNamespace][taskId],
        timing: response.getTime(),
        success: response.getSuccess(),
        variant: response.getVariant()
      });
    });

    // this.loggerService.log(['serialized', serialized]);

    return serialized;
  }
}
