import {Component, OnInit, Input} from '@angular/core';
import {IModuleTask} from "../../../app.config";
import {SlideNavigationService} from "../../../core/engagement/slide-navigation.service";
import {TranslocoService} from "@ngneat/transloco";
import {L10nNode} from "../../../core/i18n/l10n-node";
import {defaultsDeep} from "lodash";
import {baseL10nParams} from "../../../core/i18n/constants";

@Component({
  selector: 'task-title',
  templateUrl: './task-title.component.html',
  styleUrls: ['./task-title.component.scss']
})
export class TaskTitleComponent implements OnInit {
  @Input() task: IModuleTask;
  @Input() l10nNode: L10nNode;

  /**
   * Additional key-value pairs that should be passed to translate()
   *
   * cf. https://ngneat.github.io/transloco/docs/translation-api
   */
  @Input() l10nParams: any = {};

  text: string;

  @Input() showNumber: boolean = true;

  constructor(
    protected navigation: SlideNavigationService,
    protected translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    const taskNum = this.navigation.getReadableTaskNum(this.task);

    this.text = this.translocoService.translate(
      this.l10nNode.getL10NPath('title'),
      defaultsDeep({},
        this.l10nParams,
        baseL10nParams
      )
    );

    if (this.showNumber) {
      this.text = `${taskNum}. ${this.text}`;
    }
  }

}
