import {FadeInOut, FadeOut} from "../animations";
import {Component, Input, ElementRef, ViewChild, ViewChildren} from "@angular/core";
import {MediaQueryService} from "../../core/environment/media-query.service";
import {ContentShadowManagerService} from "../../core/engagement/content-shadow-manager.service";
import {ContentShadowComponent} from "./slide-wrapper/content-shadow.component";
import {CaptionsSettingsService} from "../../core/settings/captions-settings.service";

@Component({
  templateUrl: './base-slide-wrapper.component.html',
  styleUrls: ['./base-slide-wrapper.component.scss'],
  animations: [FadeInOut(500), FadeOut(250)]
})
export class BaseSlideWrapperComponent {
  @Input() config;

  @ViewChild('mainContent')
  mainContent: ElementRef;

  @ViewChildren(ContentShadowComponent)
  contentShadows: ContentShadowComponent[];

  constructor(
    public mediaQueryService: MediaQueryService,
    public contentShadowManagerService: ContentShadowManagerService,
    public captionsSettingsService: CaptionsSettingsService
  ) { }

  ngAfterViewInit() {
    this.contentShadowManagerService.track(this.mainContent, this.contentShadows);
  }
}
