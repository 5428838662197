import {Directive, HostListener} from '@angular/core';
import {CaptionsSettingsService} from "../../../core/settings/captions-settings.service";

@Directive({
  selector: '[ccToggle]'
})
export class CCToggleDirective {

  constructor(public captionsSettingsService: CaptionsSettingsService) {
  }

  @HostListener('click', ['$event'])
  onClick(event) {
    this.captionsSettingsService.toggleCaptions(event);
  }

  @HostListener('keydown.space', ['$event'])
  onSpacebarClick(event) {
    this.onClick(event);
  }

}
