import {PipeTransform, Pipe} from "@angular/core";
import {PlatformService} from "../../core/environment/platform.service";

@Pipe({
  name: 'xPlatformShortcut'
})
export class CrossPlatformShortcutPipe implements PipeTransform {

  constructor(
    protected platformService: PlatformService
  ) {}

  transform(keyCombo: string[]): string {
    return `${this.platformService.isMac ? ('⌘' + (keyCombo.length > 1 ? '+' : '')) : 'Ctrl+'}${keyCombo.join('')}`;
  }
}
