import {Component} from "@angular/core";
import {BaseTaskSlideComponent} from "./base-task-slide.component";
import {ResponseModel} from "../../core/engagement/response.model";
import {ModulePracticeLessonCompleteResolve} from "../../core/resolves/module-practice-lesson-complete.resolve";
import {of, Observable} from "rxjs";
import {ModulePracticeUserCompletion} from "northstar-foundation";
import { IRecordedClick } from "app/core/engagement/interface-interaction-stager.service";


@Component({
  templateUrl: './base-task-slide.component.html'
})
export class PracticeBaseTaskSlideComponent extends BaseTaskSlideComponent {
  showIDontKnowButton: boolean = false;
  showNextButton: boolean = true;
  delayBetweenSlidesMs: number = 1000;
  recordLessonCompleteRecorded: boolean = false;

  ngOnInit() {
    super.ngOnInit();

    this.responseCollectionService.activateNamespace(
      this.responseCollectionService.getNamespaceForLesson(this.navigationService.getLessonForCurrentComponent())
    );
  }

  handleClickNext() {
    if (!this.hasCorrectAnswer()) {
      this.onIncorrectAnswer();
      return;
    }

    this.setResponseAsSuccess();
  }

  /**
   * Audio files for NOL are beneath a 'practice' folder to separate from assessment audio.
   */
  getAudioPathSubfolder() {
    return 'practice/';
  }

  onIncorrectAnswer() {
    this.popoverService.showAll();
  }

  onResponseComplete(response: ResponseModel) {
    this.popoverService.hideAll();
    super.onResponseComplete(response);
  }

  configureSlideWrapperParameters() {
    super.configureSlideWrapperParameters();

    this.config.askUserToConfirmRetry = false;
  }

  hasCorrectAnswer() {
    // may want to override in children classes ;-)
    return false;
  }

  recordLessonComplete(): Observable<boolean | ModulePracticeUserCompletion> {
    if (this.recordLessonCompleteRecorded) {
      return of(false);
    }

    this.recordLessonCompleteRecorded = true;

    const lessonCompleteResolver = this.injector.get(ModulePracticeLessonCompleteResolve);
    return lessonCompleteResolver.resolve(this.route.snapshot);
  }
}
