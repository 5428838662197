import {Router, NavigationEnd} from "@angular/router";
import {Injectable, Inject} from "@angular/core";
import {LoggerService} from "./logger.service";
import {APP_CONFIG, IAppConfig} from "../../app.config";
import {CustomDataStore} from "../data-store/custom-data-store.service";
import {ModuleTopic, AssessmentModule, Sponsor, getUrlForLanguage} from "northstar-foundation";
import {RouterHistoryService, IPageView} from "./router-history.service";
import {SessionService} from "./session.service";
import {MS_PER_SECOND, MS_PER_MINUTE} from "../utilities/time";
import {apiEndpoints, ApiService} from "./api.service";

/**
 * Track user activity, 'time on task', in order for organizations to be able to track end-user usage times and
 * get reimbursed accordingly.
 */
@Injectable()
export class TimeOnTaskService {
  static saveIntervalSeconds: number = 60;

  idleSessionEndMinutes: number; // set from server value
  lastSubmissionTime: Date;

  /**
   * Interval to be set by setInterval() and able to be cleared via clearInterval().
   */
  saveInterval: number;

  /**
   * Flag for whether to make API request to server for sole purpose of keeping session alive. Intended for setting
   * for any user interaction: typing, clicking, navigating.
   *
   * @type {boolean}
   */
  shouldKeepAliveRemote: boolean = false; // is set to true by app.component.ts behavior

  currentSponsorId: number;
  sponsor: Sponsor;

  currentLocation: 'onsite'|'offsite';

  /**
   * The seconds-between-saves interval converted to milliseconds for use in class without risk of typo each time.
   * @returns {number}
   */
  static getSaveInterval() {
    return TimeOnTaskService.saveIntervalSeconds * MS_PER_SECOND;
  }

  constructor(
    protected routerHistoryService: RouterHistoryService,
    protected loggerService: LoggerService,
    @Inject(APP_CONFIG) protected appConfig: IAppConfig,
    protected dataStore: CustomDataStore,
    protected router: Router,
    protected sessionService: SessionService,
    protected apiService: ApiService
  ) {
    // either save page views every once in a while (to cut down on constant API calls)
    this.saveInterval = setInterval(this.checkForSaveRemote.bind(this), TimeOnTaskService.getSaveInterval());
  }

  public setIdleSessionEndMinutes(mins: number) {
    this.loggerService.log(['Setting TimeOnTaskService.idleSessionEndMinutes', mins]);
    this.idleSessionEndMinutes = mins;
  }

  /**
   * Save recent page views to server. Reference regular interval in constructor.
   */
  protected checkForSaveRemote() {
    if (!this.sessionService.userIsLoggedIn()) {
      clearInterval(this.saveInterval);
      if (this.appConfig.debug) {
        // this.loggerService.log(['Skip saving time-on-task page views since user not logged in.']);
      }
      return;
    }

    if (!this.currentSponsorId || !this.currentLocation) {
      // this.loggerService.log(['Skip saving time-on-task since user has not identified current sponsor or location.']);
      return;
    }

    const appStarting = typeof this.lastSubmissionTime === 'undefined';
    const nearingSessionEnd = (new Date()) > this.getTimeForNearingTaskSessionEnd();

    if (!this.shouldKeepAliveRemote) {
      // this.loggerService.log(['Skip saving time-on-task since user does not appear to have interacted w/time-on-task pages.']);
      return; // not giving any warning to user because their concern should be re session logout, not time-on-task
    }

    if (!nearingSessionEnd && !appStarting) {
      // this.loggerService.log(['Skip saving time-on-task since neither near task-session end nor app recently started. Time to consider near session end', this.getTimeForNearingTaskSessionEnd()]);
      return;
    }

    if (nearingSessionEnd) {
      // this.loggerService.log(['Saving time-on-task since nearing time-on-task session end...']);

      const timeForTaskSessionEnd = this.getTimeForTaskSessionEnd();
      if ((new Date()) > timeForTaskSessionEnd) {
        // this.loggerService.warn(['Likely starting new time-on-task session since now submitting past', timeForTaskSessionEnd]);
      }
    }

    if (appStarting) {
      // this.loggerService.log(['Saving time-on-task since app just started w/valid URLs...']);
    }

    // user has demonstrated activity, so keep their session alive
    this.attemptKeepAlive();
  }

  protected attemptKeepAlive() {
    const pageViewsRecent = this.routerHistoryService.getPageViewsSince(this.lastSubmissionTime);
    const pageViewsRecentAndValid = pageViewsRecent.filter((pageView: IPageView) => {
      return this.urlIsValidForTimeOnTask(pageView.url);
    });

    // this.loggerService.log(['Time-on-task pages viewed recently.', pageViewsRecentAndValid]);

    if (this.remoteSessionKeepAliveOccurredRecently()) {
      // possible that this code is never run, but seems dependent upon race conditions' likelihood, due to how we're
      // tracking both route changes and clicks in app.component.ts, and both occur when clicking on a link
      // this.loggerService.warn(['Time-on-task save stopped from executing at last moment, preventing double-submission']);
      return;
    }

    this.shouldKeepAliveRemote = false; // require user interact before remote keep-alive again
    this.lastSubmissionTime = new Date();

    this.apiService.httpPost(apiEndpoints.timeOnTask, {
      user: this.sessionService.user.id,
      numRecentPageViews: pageViewsRecentAndValid.length
    })
    .subscribe(
      () => {
      },
      error => {
        // this.loggerService.warn('Error attempting remote time-on-task keep-alive.');
      }
    );
  }

  urlIsValidForTimeOnTask(url: string) {
    // user is in LMS
    let valid = url.indexOf('/account') === 0;

    if (valid) {
      return valid;
    }

    const topics = this.dataStore.getAll('module_topic');

    // user is in practice exercises
    topics.forEach((topic: ModuleTopic) => {
      // non-software-versioned practice, e.g. /career-search-skills/practice
      if (url.indexOf(`/${topic.slug}/practice`) === 0) {
        valid = true;
      }

      topic.modules.forEach((module: AssessmentModule) => {
        // software-versioned practice, e.g. /ms-word-office2016/practice
        if (url.indexOf(`/${topic.slug}-${module.softwareVersion.slug}/practice`) === 0) {
          valid = true;
        }
      })
    });

    return valid;
  }

  protected getTimeForNearingTaskSessionEnd() {
    // user hasn't ever started tracking session yet
    if (typeof this.lastSubmissionTime === 'undefined') {
      return new Date();
    }

    return new Date(this.lastSubmissionTime.getTime() + this.idleSessionEndMinutes * MS_PER_MINUTE - TimeOnTaskService.saveIntervalSeconds * MS_PER_SECOND * 4);
  }

  protected getTimeForTaskSessionEnd() {
    // user hasn't ever started tracking session yet
    if (typeof this.lastSubmissionTime === 'undefined') {
      return new Date();
    }

    return new Date(this.lastSubmissionTime.getTime() + this.idleSessionEndMinutes * MS_PER_MINUTE);
  }

  protected remoteSessionKeepAliveOccurredRecently() {
    if (typeof this.lastSubmissionTime === 'undefined') {
      return false;
    }

    return (new Date()) < (new Date(this.lastSubmissionTime.getTime() + TimeOnTaskService.saveIntervalSeconds * MS_PER_SECOND));
  }

  /**
   * For other components/services to call, flagging that user is active and deserves time-on-task credit.
   */
  registerKeptAliveLocal() {
    if (!this.sessionService.userIsLoggedIn()) {
      // this.loggerService.log(['Skipping time-on-task flag for keep-alive since user already seems logged out.']);
      return;
    }

    if (!this.shouldKeepAliveRemote) {
      // this.loggerService.log(['Flagging local time-on-task session to be kept alive.']);
    }

    this.shouldKeepAliveRemote = true;

    // if almost done w/time-on-task session, don't wait for the interval that `checkForSaveRemote` is on - just do it
    if ((new Date()) >= this.getTimeForNearingTaskSessionEnd()) {
      this.checkForSaveRemote();
    }
  }

  setSponsor(sponsorId: number) {
    this.loggerService.log(['Setting sponsor for time-on-task service', sponsorId]);
    this.currentSponsorId = sponsorId;
    this.sponsor = this.dataStore.get('sponsor', this.currentSponsorId);
  }

  setLocation(location: 'onsite'|'offsite') {
    this.loggerService.log(['Setting location for time-on-task service', location]);
    this.currentLocation = location;
  }

  confirmLocationInfoInSessionOrRedirect() {
    // don't care about anon users
    if (!this.sessionService.userIsLoggedIn()) {
      return;
    }

    if (this.currentLocation && this.currentSponsorId) {
      // user's good to go
      return;
    }

    const baseUrl = getUrlForLanguage(this.appConfig.mainSiteBase, this.sessionService.user.language);

    // user needs to set config for time
    const timeOnTaskConfigUrlWithRedirect = baseUrl + `configure-time-on-task-settings/?next=${window.location}`;

    window.location.href = timeOnTaskConfigUrlWithRedirect;
  }
}
