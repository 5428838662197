import {Component, OnInit, Input, Output, EventEmitter, Inject} from '@angular/core';
import {SlideNavLinkComponent} from "./slide-nav-link.component";
import {ModulePracticeLesson} from "northstar-foundation";
import {getKebabCoreNameForComponent} from "../../slides/slide-helper";

@Component({
  selector: 'slide-nav-link-for-practice',
  templateUrl: './slide-nav-link-for-practice.component.html',
  styleUrls: ['./slide-nav-link.component.scss']
})
export class SlideNavLinkForPracticeComponent extends SlideNavLinkComponent {
  @Input()
  lesson: ModulePracticeLesson;

  ngOnInit() {
    this.task = this.navigation.getTaskForSlideComponent(this.component);

    if (this.task) {
      // practice slide
      this.summary = this.translocoService.translate(this.navigation.getL10nNodeForComponent(this.component.nsComponentId).getL10NPath('summary'));
    } else {
      // instruction slide
      this.summary = this.component.title;
    }

    if (!this.summary) {
      // fallback if no l10n `summary` or `title` property filled out
      this.summary = getKebabCoreNameForComponent(this.component.nsComponentId);
    }
  }

  goIfAllowed() {
    // close menu if user clicks current slide link
    if (this.navigation.slideComponentCurrentlyViewed(this.component)) {
      this.toggleSelf.emit(event);
      return;
    }

    // if in debug mode, won't throw error
    if (!this.navigation.slideComponentIsVisited(this.component) && !this.appConfig.debug) {
      this.toastr.warning(`Can't visit that slide yet.`);
      return;
    }

    this.navigation.goToSlideComponentForLesson(this.component, this.lesson);
  }
}
