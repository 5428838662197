import {Injectable} from '@angular/core';
import {Router, Resolve, ActivatedRouteSnapshot} from '@angular/router';
import {ApiService, apiEndpoints} from "../services/api.service";
import {ResponseCollectionService, IResponseSerialized} from "../engagement/response-collection.service";
import {of} from "rxjs";
import {catchError, switchMap} from "rxjs/operators";
import {SlideNavigationService} from "../engagement/slide-navigation.service";
import {BootstrapDataService} from "../bootstrap/bootstrap-data.service";
import {EnvironmentService} from "../environment/environment.service";
import {assign, snakeCase} from "lodash";
import {ToastrService} from 'ngx-toastr';
import {LoggerService} from "../services/logger.service";
import {
  analyticsValues,
  Assessment,
  ModulePracticeUserCompletion,
  ModulePracticeLesson,
  AssessmentModuleStandardRelation,
  AssessmentModuleStandard,
  ModulePracticeSection
} from "northstar-foundation";
import {CustomDataStore} from "../data-store/custom-data-store.service";
import {CookieService} from 'ngx-cookie-service';
import {MeResolve} from './me.resolve';
import {SessionService} from "../services/session.service";
import {AssessmentTokenService} from "../services/assessment-token.service";
import {translate, TranslocoService} from '@ngneat/transloco';
import {Angulartics2} from "angulartics2";
import {GoogleTagManagerService} from "northstar-foundation/angular";

declare var trackJs: any;

export interface IAssessmentSubmission {
  module: number,
  responses: IResponseSerialized[],
  start: string,
  name?: string,
  site?: number,
  token?: string
}

export interface IApiAssessmentCreateResponse {
  currentAssessment: Assessment,
  lessonCompletions: ModulePracticeUserCompletion[],
  lessons: ModulePracticeLesson[],
  sections: ModulePracticeSection[],
  standardRelations: AssessmentModuleStandardRelation[],
  standards: AssessmentModuleStandard[]
}

@Injectable()
export class ResultsDataResolve implements Resolve<any> {

  constructor(
    private router: Router,
    private apiService: ApiService,
    private responseCollection: ResponseCollectionService,
    private navigationService: SlideNavigationService,
    private bootstrapData: BootstrapDataService,
    private environmentService: EnvironmentService,
    private angulartics2: Angulartics2,
    private gtmService: GoogleTagManagerService,
    private toastr: ToastrService,
    protected dataStore: CustomDataStore,
    private loggerService: LoggerService,
    private cookieService: CookieService,
    protected meResolver: MeResolve,
    protected sessionService: SessionService,
    protected assessmentTokenService: AssessmentTokenService,
    protected translocoService: TranslocoService,
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    const module = this.bootstrapData.getModule();

    // set outside callbacks in order to pass into second switchMap
    let assessmentData: IAssessmentSubmission = {
      module: module.id,
      responses: this.responseCollection.serialize(),
      start: this.bootstrapData.getStartTime(),
    };

    // get user login status and set to sessionService in order to determine whether to show practice links, how to handle badging, etc.
    return this.meResolver.resolve(route)
      .pipe(
        switchMap(me => {
          if (this.sessionService.getSponsor()) {
            assessmentData = assign({}, assessmentData, {
              name: this.sessionService.getUserFullName(),
              site: this.sessionService.getSponsor().id,
              token: this.assessmentTokenService.getToken()
            });
          }

          return this.apiService.httpPostWithRetry(
            apiEndpoints.recordAssessment,
            assessmentData
          );
        }),
        switchMap(this.onApiResponseSuccess.bind(this, assessmentData)),
        catchError(this.onApiResponseError.bind(this)
      ));
  }

  onApiResponseSuccess(assessmentData: IAssessmentSubmission, response: IApiAssessmentCreateResponse) {
    // increment completions in session by 1
    // https://support.google.com/analytics/answer/2709828?hl=en#example-metrics
    (<any>window).ga('set', analyticsValues.metrics.assessmentCompletion.analyticsKey, 1);

    this.saveUserCompletionToCookie(response);

    this.angulartics2.eventTrack.next({
      action: 'Completion',
      properties: {
        category: 'Assessment',
        label: assessmentData.module,
        value: response.currentAssessment.passed ? 1 : 0
      }
    });

    this.gtmService.trackEvent('assessment_complete', {
      module: assessmentData.module,
      location: response.currentAssessment.sponsorId ? response.currentAssessment.sponsorId : 0,
      assessmentPassed: response.currentAssessment.passed,
      lang: this.translocoService.getActiveLang(),
    });

    if (!response.currentAssessment) {
      return of(false);
    }

    this.dataStore.add('module_standard_relation', response.standardRelations);
    this.dataStore.add('module_practice_lesson', response.lessons);
    this.dataStore.add('module_practice_section', response.sections);
    this.dataStore.add('module_practice_user_completion', response.lessonCompletions);
    this.dataStore.add('module_standard', response.standards);

    return of(this.dataStore.add('assessment', assign(response.currentAssessment, {'historical': false})));
  }

  onApiResponseError(assessmentData: IAssessmentSubmission, error) {
    this.toastr.error(translate('common.thereWasAnErrorSubmittingYourAssessment'));
    this.loggerService.log(error);

    if (typeof trackJs !== 'undefined') {
      trackJs.track('Assessment submission error');
    }

    return of(false);
  }

  /**
   * Track that user completed assessment in order to limit #/day on homepage.
   *
   * @param response
   */
  saveUserCompletionToCookie(response: IApiAssessmentCreateResponse) {
    let maxed = this.getMaxedModules();
    maxed.push(response.currentAssessment.moduleId);
    this.saveMaxedModules(maxed);
  }

  /**
   * Retrieve IDs of the assessments user has already taken today.
   *
   * @returns {Array}
   */
  getMaxedModules() {
    const cookieValues = this.cookieService.get('modules_maxed');

    if (cookieValues) {
        return cookieValues.split('|').map(function(moduleNumAsString) {
            return parseInt(moduleNumAsString, 10);
        });
    }

    return [];
  }

  /**
   * Save to cookie the assessment IDs user has taken today.
   *
   * @param moduleNums
   */
  saveMaxedModules(moduleNums: number[]) {
    let expirationTime = new Date();
    expirationTime.setHours(24, 0, 0, 0); // tonight midnight local time to browser

    // from assessment.digitalliteracyassessment.org, get .digitalliteracyassessment.org
    // ref https://stackoverflow.com/a/3089332/4185989
    const mainHostname = '.' + window.location.hostname.split('.').slice(1).join('.');

    // track that user has taken this assessment for limiting # per day
    this.cookieService.set(
      'modules_maxed',
      moduleNums.join('|'),
      expirationTime,
      '/',
      mainHostname
    );
  }
}
