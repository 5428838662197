import {Component, OnInit, Input, Output, EventEmitter, Inject} from '@angular/core';
import {SlideNavigationService} from "../../../core/engagement/slide-navigation.service";
import {IModuleTask, IAppConfig, APP_CONFIG} from "../../../app.config";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from 'ngx-toastr';
import {ModulePracticeSection} from "northstar-foundation";

@Component({
  selector: 'section-nav-link',
  templateUrl: './section-nav-link.component.html',
  styleUrls: [
    './section-nav-link.component.scss'
  ]
})
export class SectionNavLinkComponent {
  @Input() section: ModulePracticeSection;

  @Output('onToggleSelf')
  toggleSelf: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    protected navigation: SlideNavigationService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected toastr: ToastrService,
    @Inject(APP_CONFIG) protected appConfig: IAppConfig
  ) {}
}
