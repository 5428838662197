import {PipeTransform, Pipe} from "@angular/core";

const formats: any = {
  years: 'years'
};

@Pipe({
  name: 'timeDelta'
})
export class TimeDeltaPipe implements PipeTransform {
  formatsToMethods = {
    years: 'getFullYear'
  };

  transform(num: number, format: string): number {

    if (typeof formats[format] === 'undefined') {
      throw Error('Not a known format.');
      return;
    }

    const now = new Date();

    return now[this.formatsToMethods[format]]() + num;
  }
}
