import {Component, OnInit, Input} from '@angular/core';
import {SlideNavigationService} from "../../../core/engagement/slide-navigation.service";
import {ResponseCollectionService} from "../../../core/engagement/response-collection.service";

@Component({
  selector: 'i-dont-know-button',
  templateUrl: './i-dont-know-button.component.html',
  styleUrls: [
    './_shared-controls-button.scss',
    './i-dont-know-button.component.scss'
  ]
})
export class IDontKnowButtonComponent implements OnInit {

  constructor(
    protected slideNavigationService: SlideNavigationService,
    protected responseCollectionService: ResponseCollectionService
  ) {}

  ngOnInit() {
  }

  recordClick(event) {
    event.preventDefault();

    const component = this.slideNavigationService.getCurrentComponent();
    const task = this.slideNavigationService.getTaskForSlideComponent(component);

    this.responseCollectionService.addDontKnow(component, task, null, event);
  }

}
