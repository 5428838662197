import {Injectable} from "@angular/core";
import {LoggerService} from "../services/logger.service";
import {ApiService} from "../services/api.service";
import {Subscription} from "rxjs";
import {ToastrService} from 'ngx-toastr';
import {CustomDataStore} from "../data-store/custom-data-store.service";
import {Assessment} from "northstar-foundation";
import {GoogleTagManagerService} from "northstar-foundation/angular";
import {Angulartics2} from "angulartics2";

interface IBadgeFindOrCreateResponse {
  content?: string|any,
  success?: boolean
}

@Injectable()
export class BadgingService {
  constructor(
    protected logger: LoggerService,
    protected apiService: ApiService,
    protected toastr: ToastrService,
    protected dataStore: CustomDataStore,
    protected angulartics2: Angulartics2,
    protected gtmService: GoogleTagManagerService
  ) {}

  /**
   * Claim a badge. Requests a badge-assertion URL from NS API and then sends that URL on to the
   * OpenBadges issuer API.
   *
   * @param email
   * @param verificationCode
   * @param assessmentId
   * @returns {Subscription}
   */
  claim(
    email: string,
    verificationCode: string,
    assessmentId: number
  ): Subscription {
    return this.apiService
               .httpPost(ApiService.endpoints.findOrCreateBadge, {
                 email: email,
                 assessmentId: assessmentId,
                 verificationCode: verificationCode
               })
               .subscribe(
                 this.onFindOrCreateBadgeSuccess.bind(this, assessmentId),
                 this.onFindOrCreateBadgeError.bind(this)
               );
  }

  /**
   * Handler of successful API response for finding/making a badge. Note that while "successful" in the HTTP
   * sense, the actual validation of the user's badging request may come back negative - check `success` property
   * within the response.
   *
   * @param response
   */
  protected onFindOrCreateBadgeSuccess(assessmentId: number, response: IBadgeFindOrCreateResponse): void {
    if (!response.success) {
      this.toastr.info(response.content, null, {
        timeOut: 10000
      });
      return;
    }

    this.toastr.success('Your badge has been awarded!', null, {
      timeOut: 10000
    });

    let assessment: Assessment = this.dataStore.get('assessment', assessmentId);

    if (!assessment) {
      return;
    }

    this.angulartics2.eventTrack.next({
      action: assessment.badge ? 'Migrate' : 'Claim',
      properties: {
        category: 'Badge',
        label: assessment.moduleId,
        value: assessment.sponsorId ? assessment.sponsorId : 0
      }
    });

    this.gtmService.trackEvent(assessment.badge ? 'badge_migrate' : 'badge_claim', {
      module: assessment.moduleId,
      location: assessment.sponsorId ? assessment.sponsorId : 0
    });

    assessment.badgrAssertion = <any>response.content;
  }

  /**
   * Handler of erroring API response for finding/making a badge.
   *
   * @param response
   */
  protected onFindOrCreateBadgeError(response: IBadgeFindOrCreateResponse): void {
    this.logger.warn('Badge not found/created.');
    this.toastr.warning(response.content, null, {
      timeOut: 10000
    });
  }
}
