import {Resolve, ActivatedRouteSnapshot} from "@angular/router";
import {Injectable, Inject} from "@angular/core";
import {ApiService, apiEndpoints} from "../../services/api.service";
import {CustomDataStore} from "../../data-store/custom-data-store.service";
import {LoggerService} from "../../services/logger.service";
import {SessionService} from "../../services/session.service";
import {User, ModuleTopic, Assessment, ModulePracticeLesson,
        ModulePracticeUserCompletion,
        ModulePracticeSection,
        ModulePracticeSectionReview,
        ITimeOnTaskSummaryForUser,
        ITimeOnTaskDayDetailForUser,
        ModulePracticeSectionReviewUserCompletion} from "northstar-foundation";
import {IAppConfig, APP_CONFIG} from "../../../app.config";
import {MeResolve} from "../me.resolve";
import {ModulesResolve} from "../modules.resolve";
import {forkJoin, of} from "rxjs";
import {catchError, switchMap} from "rxjs/operators";
import {TranslocoService} from "@ngneat/transloco";
import {each} from "lodash";


export interface ILMSBootstrapResponseSuccess {
  assessmentsLatest: Assessment[],
  modulesPassedAndProctored: number[], // IDs of the modules
  modulesBestScores: any, // keys are module IDs, values are scores
  lessons: ModulePracticeLesson[],
  lessonCompletions: ModulePracticeUserCompletion[],
  sections: ModulePracticeSection[],
  sectionReviews: ModulePracticeSectionReview[],
  sectionReviewCompletions: ModulePracticeSectionReviewUserCompletion[],
  timeOnTask: ITimeOnTaskSummaryForUser
}

@Injectable()
export class LMSBootstrapResolve implements Resolve<any> {

  constructor(
    protected meResolver: MeResolve,
    protected modulesResolver: ModulesResolve,
    protected loggerService: LoggerService,
    protected dataStore: CustomDataStore,
    protected translocoService: TranslocoService,
    public sessionService: SessionService,
    protected apiService: ApiService,
    @Inject(APP_CONFIG) public appConfig: IAppConfig
  ) {}

  resolve(route: ActivatedRouteSnapshot) {

    // call me resolver to determine lang first, then get l10n content etc
    return this.meResolver.resolve(route).pipe(
        switchMap(meResult => forkJoin([
          this.translocoService.selectTranslation(this.translocoService.getActiveLang()),
          this.modulesResolver.resolve(route)
        ])),
        switchMap(results => this.apiService.httpGet(apiEndpoints.lmsBootstrap)),
        switchMap(this.onLMSDataReady.bind(this)),
        catchError(this.onLMSDataProblem.bind(this))
    );
  }

  onLMSDataReady(response: ILMSBootstrapResponseSuccess) {
    this.dataStore.add('module_practice_lesson', response.lessons);
    this.dataStore.add('module_practice_user_completion', response.lessonCompletions);
    this.dataStore.add('module_practice_section', response.sections);
    this.dataStore.add('module_practice_section_review', response.sectionReviews);
    this.dataStore.add('module_practice_section_review_user_completion', response.sectionReviewCompletions);

    let assessmentsLatest = this.dataStore.add('assessment', response.assessmentsLatest);

    // filter out modules unavailable in current language and non-v2 modules
    assessmentsLatest = assessmentsLatest.filter((assessment: Assessment) => {
      return assessment.module && !assessment.module.isLegacy()
    });

    each(response.timeOnTask.byDay, (detail: ITimeOnTaskDayDetailForUser) => {
      detail.date = new Date(detail.day);
    });

    return of({
      modulesBestScores: response.modulesBestScores,
      modulesPassedAndProctored: response.modulesPassedAndProctored,
      assessmentsLatest: assessmentsLatest,
      timeOnTask: response.timeOnTask
    });
  }

  onLMSDataProblem(response) {

    this.loggerService.error(['Redirecting to login from LMS', response]);
    const loginUrlWithRedirect = this.appConfig.mainSiteBase + `login/?next=${window.location}`;

    window.location.href = loginUrlWithRedirect;

    return of(false);
  }

}
