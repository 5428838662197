import {Injectable, Inject} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {ApiService, apiEndpoints} from "../services/api.service";
import {SessionService} from "../services/session.service";
import {BootstrapDataService} from "../bootstrap/bootstrap-data.service";
import {CustomDataStore} from "../data-store/custom-data-store.service";
import {LoggerService} from "../services/logger.service";
import {MeResolve} from "./me.resolve";
import {ModulePracticeSectionReview, ModulePracticeSection, ModulePracticeUserCompletion, ModulePracticeLesson, ModulePracticeSectionReviewUserCompletion, ModulePracticeSectionReviewQuestionResponse} from "northstar-foundation";
import {forEach} from "lodash";
import {SlideNavigationService} from "../engagement/slide-navigation.service";
import {ToastrService} from 'ngx-toastr';
import {IAppConfig, APP_CONFIG} from "../../app.config";
import {ResponseCollectionService} from "../engagement/response-collection.service";
import {catchError, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {GoogleTagManagerService} from "northstar-foundation/angular";
import {TranslocoService} from "@ngneat/transloco";
import {Angulartics2} from "angulartics2";

declare var trackJs: any;

export interface ISectionReviewCompletionResponse {
  reviewCompletion: ModulePracticeSectionReviewUserCompletion,
  reviewCompletionResponses: ModulePracticeSectionReviewQuestionResponse
}

@Injectable()
export class ModulePracticeSectionReviewCompleteResolve implements Resolve<any> {

  constructor(
    protected loggerService: LoggerService,
              protected dataStore: CustomDataStore,
              public sessionService: SessionService,
              protected apiService: ApiService,
              protected meResolver: MeResolve,
              protected bootstrapDataService: BootstrapDataService,
              protected slideNavigation: SlideNavigationService,
              protected responseCollectionService: ResponseCollectionService,
              protected toastr: ToastrService,
              protected angulartics2: Angulartics2,
              protected gtmService: GoogleTagManagerService,
              @Inject(APP_CONFIG) public appConfig: IAppConfig,
              protected translocoService: TranslocoService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot) {
    const sectionReview: ModulePracticeSectionReview = (<ModulePracticeSection>this.slideNavigation.getCurrentSection()).review;

    if (!this.sessionService.userIsLoggedIn()) {
      this.loggerService.warn('Not submitting review completion since user appears logged out.');
      return false;
    }

    let responses = this.responseCollectionService.serialize();

    // API expects review-question responses to use a different property than assessment responses (`review_question` instead of `question`)
    forEach(responses, (response: any) => {
      response.reviewQuestionId = response.question;
      delete response.question;
    });

    const completionData = {
      userId: this.sessionService.user.id,
      modulePracticeSectionReviewId: sectionReview.id,
      responses: responses
    };

    return this.apiService.httpPostWithRetry(apiEndpoints.recordSectionReviewCompletion, completionData)
                          .pipe(
                            switchMap(this.onResponse.bind(this)),
                            catchError(this.onError.bind(this)),
                          );
  }

  onResponse(response: ISectionReviewCompletionResponse) {
    const completion: ModulePracticeSectionReviewUserCompletion = <ModulePracticeSectionReviewUserCompletion>this.dataStore.add('module_practice_section_review_user_completion', response.reviewCompletion);
    const questionResponses = this.dataStore.add('module_practice_section_review_question_response', response.reviewCompletionResponses);

    this.angulartics2.eventTrack.next({
      action: 'Complete',
      properties: {
        category: 'NSOL Section Review',
        label: `Module ${this.bootstrapDataService.getModule().id} - Review ${completion.review.id} (${completion.review.section.slug})`,
        value: completion.success ? 1 : 0
      }
    });

    this.gtmService.trackEvent('review_complete', {
      module: this.bootstrapDataService.getModule().id,
      review: completion.review.id,
      reviewPass: completion.success,
      lang: this.translocoService.getActiveLang(),
    });

    // save section and module completions if successful
    // note that theoretically this could track multiple instances in GTM, but seems unlikely that a high % of users
    // would retake section reviews that they already passed with 100%
    if (completion.success) {
        this.gtmService.trackEvent('section_complete', {
          module: completion.review.section.moduleId,
          section: completion.review.section.id,
          location: this.sessionService.sponsor ? this.sessionService.sponsor.id : 0,
          lang: this.translocoService.getActiveLang(),
        });

        if (completion.review.section.module.practiceCompleteForUser(this.sessionService.user.id)) {
          this.gtmService.trackEvent('module_complete', {
            module: completion.review.section.moduleId,
            location: this.sessionService.sponsor ? this.sessionService.sponsor.id : 0,
            lang: this.translocoService.getActiveLang(),
          });
        }
    }

    return of({
      completion: completion,
      questionResponses: questionResponses
    });
  }

  onError(response) {
    this.loggerService.log(response);

    const problemRecordingMsg = `There was a problem recording your completion of the section review.`;
    this.toastr.warning(problemRecordingMsg);

    if (typeof trackJs !== 'undefined') {
      trackJs.track(problemRecordingMsg);
    }

    return of(false);
  }
}
