import {Component, OnInit, Input, HostBinding, ViewChild, ElementRef, ViewChildren} from '@angular/core';
import {FadeInOut, FadeOut} from "../../animations";
import {ISlideWrapper} from "../slide-wrapper/slide-wrapper.interfaces";
import {BaseSlideWrapperComponent} from "../base-slide-wrapper.component";

/**
 * Wrapper for NSOL instruction slides. Characterized by same color navbar as content.
 */
@Component({
  selector: 'practice-instruction-slide-wrapper',
  templateUrl: './practice-instruction-slide-wrapper.component.html',
  styleUrls: ['./practice-instruction-slide-wrapper.component.scss'],
  animations: [FadeInOut(500), FadeOut(250)]
})
export class PracticeInstructionSlideWrapperComponent extends BaseSlideWrapperComponent {
  sources: string[] = [];

  @Input() config: ISlideWrapper;
}
