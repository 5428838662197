import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { ModuleWrapperComponent } from './layout/module-wrapper/module-wrapper.component';
import { TaskSlideWrapperComponent } from './layout/slide-wrapper/task-slide-wrapper.component';
import {RouterModule} from "@angular/router";
import {AssessmentOrientationComponent} from "./slides/assessment-orientation/assessment-orientation.component";
import {SlideTitleComponent} from "./layout/slide-wrapper/slide-title.component";
import {CCOverlayComponent} from "./layout/slide-wrapper/cc-overlay.component";
import {ProgressBarComponent} from "./layout/slide-wrapper/progress-bar.component";
import {NextButtonComponent} from "./layout/slide-wrapper/next-button.component";
import {IDontKnowButtonComponent} from "./layout/slide-wrapper/i-dont-know-button.component";
import {CCToggleButtonComponent} from "./layout/slide-wrapper/cc-toggle-button.component";
import {FullScreenButtonComponent} from "./layout/slide-wrapper/full-screen-button.component";
import {VolumeButtonComponent} from "./layout/slide-wrapper/volume-button.component";
import {ReplayAudioButtonComponent} from "./layout/slide-wrapper/replay-audio-button.component";
import {OtherSlidesNavigationComponent} from "./layout/slide-wrapper/other-slides-navigation.component";
import {CCToggleDirective} from "./layout/slide-wrapper/cc-toggle.directive";
import {AudioSetupComponent} from "./slides/audio-setup/audio-setup.component";
import {ModuleTitleAndStartComponent} from "./slides/module-title-and-start/module-title-and-start.component";
import {VgCoreModule} from "videogular2/core";
import {VgControlsModule} from "videogular2/controls";
import {VgOverlayPlayModule} from "videogular2/overlay-play";
import {VgBufferingModule} from "videogular2/buffering";
import {TooltipComponent} from "./layout/slide-wrapper/tooltip.component";
import {TaskTitleComponent} from "./layout/slide-wrapper/task-title.component";
import {BaseOverviewSlideComponent} from "./slides/base-overview-slide.component";
import {BaseTaskSlideComponent} from "./slides/base-task-slide.component";
import {SlideNavLinkComponent} from "./layout/slide-wrapper/slide-nav-link.component";
import {OverviewSlideWrapperComponent} from "./layout/slide-wrapper/overview-slide-wrapper.component";
import {SubmissionConfirmationComponent} from "./slides/submission-confirmation/submission-confirmation.component";
import {AssessmentResultsComponent} from "./slides/assessment-results/assessment-results.component";
import {BaseTaskRecipeMultipleChoiceSingleSlide} from "./slides/base-task-recipe-multiple-choice-single-slide.component";
import {BaseTaskRecipeMultipleChoiceMultipleSlide} from "./slides/base-task-recipe-multiple-choice-multiple-slide.component";
import {RecordClickDirective} from "./engagement/record-click.directive";
import {DndModule} from "ng2-dnd";
import {PreventDefaultDirective} from "./engagement/prevent-default.directive";
import {ResponsiveHeightDirective} from "./layout/ui-helpers/responsive-height.directive";
import {OrientationVideoComponent} from "./slides/assessment-orientation/orientation-video.component";
import {PhotoCreditComponent} from "./layout/slide-wrapper/photo-credit.component";
import {ModalComponent} from "./layout/modal/modal.component";
import {OneTimeBindDirective} from "./utilities/one-time-bind.directive";
import {ModuleImagePipe} from "./pipes/module-image.pipe";
import { ModuleSafeHtml } from './pipes/module-safeHtml.pipe';
import {BaseTaskRecipeDragAndDropMatchingSlide} from "./slides/base-task-recipe-drag-and-drop-matching-slide.component";
import {BaseTaskRecipeMultipleChoicePartialAcceptedSlide} from "./slides/base-task-recipe-multiple-choice-partial-accepted-slide.component";
import {ConfirmRetryOverlayComponent} from "./layout/slide-wrapper/confirm-retry-overlay.component";
import {PracticeSlideWrapperComponent} from "./layout/practice/practice-slide-wrapper.component";
import {PracticeWrapperComponent} from "./layout/practice/practice-wrapper.component";
import {LessonNavLinkComponent} from "./layout/slide-wrapper/lesson-nav-link.component";
import {PracticeSectionReviewComponent} from "./slides/section-review/section-review.component";
import {PopoverComponent} from "./layout/ui-helpers/popover.component";
import {PracticeInstructionSlideWrapperComponent} from "./layout/practice/practice-instruction-slide-wrapper.component";
import {PointerComponent} from "./layout/ui-helpers/pointer.component";
import {PracticeBaseTaskSlideComponent} from "./slides/practice-base-task-slide.component";
import {PracticeBaseOverviewSlideComponent} from "./slides/practice-base-overview-slide.component";
import {ContentBubbleComponent} from "./layout/ui-helpers/content-bubble.component";
import {PracticeBaseTaskRecipeMultipleChoiceSingleSlide} from "./slides/practice-base-task-recipe-multiple-choice-single-slide.component";
import {SectionNavLinkComponent} from "./layout/slide-wrapper/section-nav-link.component";
import {PracticeSectionOverviewComponent} from "./slides/section-overview/section-overview.component";
import {ImageAssetPipe} from "./pipes/image-asset.pipe";
import {SlideNavLinkForPracticeComponent} from "./layout/slide-wrapper/slide-nav-link-for-practice.component";
import {PracticeBaseTaskRecipeDragAndDropMatchingSlide} from "./slides/practice-base-task-recipe-drag-and-drop-matching-slide.component";
import {SectionReviewNavLinkComponent} from "./layout/slide-wrapper/section-review-nav-link.component";
import {PracticeReviewQuestionSlideWrapperComponent} from "./layout/practice/practice-review-question-slide-wrapper.component";
import {PracticeSectionReviewResultsComponent} from "./slides/section-review/section-review-results.component";
import {PracticeSectionReviewEntryComponent} from "./slides/section-review/section-review-entry.component";
import {RouterOutletPlaceholderComponent} from "./slides/router-outlet-placeholder.component";
import {StandardPracticeButton} from "./layout/nsol/standard-practice-button.component";
import {NorthstarFoundationSharedModule} from "northstar-foundation/angular";
import {PracticeBaseTaskRecipeMultipleChoiceMultipleSlide} from "./slides/practice-base-task-recipe-multiple-choice-multiple-slide.component";
import {PracticeInstructionNSOLMuteIntroComponent} from "./slides/nsol/instruction-nsol-mute-intro.component";
import {FlipCardComponent} from "./layout/ui-helpers/flip-card.component";
import {SectionReviewBaseOverviewSlideComponent} from "./slides/section-review/section-review-base-overview-slide.component";
import {ExternalLinkFlagComponent} from "./layout/ui-helpers/external-link-flag.component";
import {CursorComponent} from "./layout/ui-helpers/cursor.component";
import {CrossPlatformShortcutPipe} from "./pipes/cross-platform-shortcut.pipe";
import {MathPipe} from "./pipes/math.pipe";
import {TimeDeltaPipe} from "./pipes/time-delta.pipe";
import {OSDependentTextPipe} from "./pipes/os-dependent-text.pipe";
import {TranslocoModule} from "@ngneat/transloco";
import {L10nSlideContentPipe} from "./i18n/l10n-slide-content.pipe";
import {L10nForPathPipe} from "./i18n/l10n-for-path.pipe";
import {MobileControlsSettingsComponent} from "./layout/slide-wrapper/mobile-controls-settings.component";
import {ModuleWrapperUserInfoComponent} from "./layout/module-wrapper/module-wrapper-user-info.component";
import {ContentShadowComponent} from "./layout/slide-wrapper/content-shadow.component";
import {BaseSlideWrapperComponent} from "./layout/base-slide-wrapper.component";
import { L10nDateFormatPipe } from './i18n/l10n-date-format.pipe';
import { L10nImageNamePipe } from './i18n/l10n-image-name.pipe';
import { L10nTimeFormatPipe } from './i18n/l10n-time-format.pipe';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { NSDatepickerI18n } from './i18n/ns-datepicker-i18n';
import {TimeAgoPipe} from "time-ago-pipe";
import {ModulePracticeImagePipe} from "./pipes/module-practice-image.pipe";
import {ResponsiveVideoWrapperComponent} from "./layout/ui-helpers/responsive-video-wrapper.component";
import {PhotoCreditInfoButtonComponent} from "./layout/slide-wrapper/photo-credit-info-button.component";
import {MobileCloseComponent} from "./layout/slide-wrapper/mobile-close.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    DndModule,
    NorthstarFoundationSharedModule,
    TranslocoModule,
  ],
  declarations: [
    ModuleWrapperComponent,
    PracticeWrapperComponent,
    TaskSlideWrapperComponent,
    PracticeSlideWrapperComponent,
    PracticeInstructionSlideWrapperComponent,
    PracticeReviewQuestionSlideWrapperComponent,
    OverviewSlideWrapperComponent,
    AudioSetupComponent,
    AssessmentOrientationComponent,
    ModuleTitleAndStartComponent,
    SubmissionConfirmationComponent,
    PracticeSectionOverviewComponent,
    PracticeSectionReviewComponent,
    PracticeSectionReviewResultsComponent,
    PracticeSectionReviewEntryComponent,
    AssessmentResultsComponent,
    SlideTitleComponent,
    TaskTitleComponent,
    CCOverlayComponent,
    PhotoCreditComponent,
    ProgressBarComponent,
    NextButtonComponent,
    IDontKnowButtonComponent,
    CCToggleButtonComponent,
    FullScreenButtonComponent,
    VolumeButtonComponent,
    MobileControlsSettingsComponent,
    ReplayAudioButtonComponent,
    OtherSlidesNavigationComponent,
    CCToggleDirective,
    TooltipComponent,
    BaseTaskSlideComponent,
    PracticeBaseTaskSlideComponent,
    BaseOverviewSlideComponent,
    PracticeBaseOverviewSlideComponent,
    SlideNavLinkComponent,
    SlideNavLinkForPracticeComponent,
    LessonNavLinkComponent,
    SectionNavLinkComponent,
    SectionReviewNavLinkComponent,
    BaseTaskRecipeMultipleChoiceSingleSlide,
    PracticeBaseTaskRecipeMultipleChoiceSingleSlide,
    BaseTaskRecipeMultipleChoiceMultipleSlide,
    PracticeBaseTaskRecipeMultipleChoiceMultipleSlide,
    BaseTaskRecipeMultipleChoicePartialAcceptedSlide,
    BaseTaskRecipeDragAndDropMatchingSlide,
    PracticeBaseTaskRecipeDragAndDropMatchingSlide,
    RecordClickDirective,
    PreventDefaultDirective,
    ResponsiveHeightDirective,
    OrientationVideoComponent,
    ModalComponent,
    OneTimeBindDirective,
    ModuleImagePipe,
    ModulePracticeImagePipe,
    ImageAssetPipe,
    ModuleSafeHtml,
    ConfirmRetryOverlayComponent,
    PopoverComponent,
    PointerComponent,
    ContentBubbleComponent,
    RouterOutletPlaceholderComponent,
    StandardPracticeButton,
    PracticeInstructionNSOLMuteIntroComponent,
    FlipCardComponent,
    SectionReviewBaseOverviewSlideComponent,
    ExternalLinkFlagComponent,
    CursorComponent,
    CrossPlatformShortcutPipe,
    MathPipe,
    TimeDeltaPipe,
    OSDependentTextPipe,
    L10nSlideContentPipe,
    L10nForPathPipe,
    ModuleWrapperUserInfoComponent,
    ContentShadowComponent,
    BaseSlideWrapperComponent,
    L10nDateFormatPipe,
    L10nImageNamePipe,
    L10nTimeFormatPipe,
    TimeAgoPipe,
    ResponsiveVideoWrapperComponent,
    PhotoCreditInfoButtonComponent,
    MobileCloseComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    TranslocoModule,
    ReactiveFormsModule,
    NorthstarFoundationSharedModule,
    BaseSlideWrapperComponent,
    TaskSlideWrapperComponent,
    PracticeSlideWrapperComponent,
    PracticeInstructionSlideWrapperComponent,
    PracticeReviewQuestionSlideWrapperComponent,
    PracticeBaseTaskRecipeMultipleChoiceSingleSlide,
    PracticeBaseTaskRecipeMultipleChoiceMultipleSlide,
    PracticeInstructionNSOLMuteIntroComponent,
    OverviewSlideWrapperComponent,
    RecordClickDirective,
    PreventDefaultDirective,
    OneTimeBindDirective,
    PhotoCreditComponent,
    ModalComponent,
    PopoverComponent,
    PointerComponent,
    ContentBubbleComponent,
    StandardPracticeButton,
    RouterOutletPlaceholderComponent,
    FlipCardComponent,
    ModuleImagePipe,
    ModulePracticeImagePipe,
    ImageAssetPipe,
    ModuleSafeHtml,
    // export DndModule to avoid importing multiple times elsewhere
    // https://github.com/akserg/ng2-dnd#2-import-the-dndmodule
    DndModule,
    SectionReviewBaseOverviewSlideComponent,
    ExternalLinkFlagComponent,
    CursorComponent,
    ResponsiveHeightDirective,
    CrossPlatformShortcutPipe,
    MathPipe,
    TimeDeltaPipe,
    OSDependentTextPipe,
    L10nSlideContentPipe,
    L10nForPathPipe,
    ModuleWrapperUserInfoComponent,
    ContentShadowComponent,
    L10nDateFormatPipe,
    L10nImageNamePipe,
    L10nTimeFormatPipe,
    TimeAgoPipe,
    ResponsiveVideoWrapperComponent,
  ],
  providers: [
    ModuleImagePipe,
    L10nSlideContentPipe,
    L10nForPathPipe,
    L10nDateFormatPipe,
    L10nImageNamePipe,
    L10nTimeFormatPipe,
    {provide: NgbDatepickerI18n, useClass: NSDatepickerI18n}
  ]
})
export class SharedModule { }
