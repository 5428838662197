import {Component, ElementRef, OnInit} from "@angular/core";
import {VgAPI} from "videogular2/core";
import {LoggerService} from "../../../core/services/logger.service";
import {AssetService} from "../../../core/services/asset.service";
import {ActivatedRoute, Router} from "@angular/router";
import {SlideNavigationService} from "../../../core/engagement/slide-navigation.service";
import {MediaQueryService} from "../../../core/environment/media-query.service";

@Component({
  templateUrl: 'instruction-nsol-mute-intro.component.html',
  styleUrls: ['instruction-nsol-mute-intro.component.scss']
})
export class PracticeInstructionNSOLMuteIntroComponent implements OnInit {
  static slug: string = 'instruction-nsol-mute-intro';
  static title: string = 'Intro';
  static nsComponentId: string = 'PracticeInstructionNSOLMuteIntroComponent';

  sources: any[];
  customHandleClickNextHandler: any;

  config: any = {
    audioPath: null,
    hasCaptions: true
  };

  getTitle() {
    return PracticeInstructionNSOLMuteIntroComponent.title;
  }

  getSlug() {
    return PracticeInstructionNSOLMuteIntroComponent.slug;
  }

  getComponentId() {
    return PracticeInstructionNSOLMuteIntroComponent.nsComponentId;
  }

  constructor(
    public el: ElementRef,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    public assetService: AssetService,
    protected loggerService: LoggerService,
    protected slideNavigationService: SlideNavigationService,
    public mediaQueryService: MediaQueryService,
  ) {
    this.customHandleClickNextHandler = this.customHandleClickNext.bind(this)
  }

  ngOnInit() {
    this.config.audioPath = `shared/${this.getSlug()}`;

    this.sources = [
      {
        src: this.assetService.getAudio(this.config.audioPath),
        type: 'audio/mp3'
      }
    ];
  }

  customHandleClickNext() {
    this.slideNavigationService.goToFirstSection();
  }
}
