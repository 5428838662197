import {camelCase, upperFirst} from "lodash";
import {AssessmentModule} from "northstar-foundation";

/**
 * Store path of keys to get to a particular l10n node in the translation file.
 */
export class L10nNode {
  /**
   *
   */
  pathSegments: string[];

  /**
   *
   * @param path The path to the node in the form of a directory-like string, e.g. some/path/to/a/node
   *             which will get converted to an array to store.
   */
  constructor(
    protected path: string
  ) {
    this.pathSegments = L10nNode.convertPathToSegments(this.path);
  }

  /**
   * Return the file path for Transloco to find the l10n files for a given module, whether for its assessment or
   * practice content.
   *
   * @param module
   * @param type
   * @returns {string}
   */
  static pathForSelectModule(module: AssessmentModule, type: 'assessment'|'practice') {
    return `${module.topic.slug}/${module.softwareVersion.isBase ? 'base' : module.softwareVersion.slug}/${type === 'assessment' ? ('v' + module.variantCode) : 'practice'}/`;
  }

  /**
   * Return the Transloco path for a given module, whether for its assessment or practice content.
   *
   * @param module
   * @param type
   * @returns {string}
   */
  static pathForTranslateModule(module: AssessmentModule, type: 'assessment'|'practice'): string {
    return `${upperFirst(camelCase(module.topic.slug))}${upperFirst(module.softwareVersion.isBase ? 'base' : module.softwareVersion.slug)}${type === 'assessment' ? ('V' + module.variantCode) : 'Practice'}`
  }

  static convertPathToSegments(path: string): string[] {
    return path.split('/');
  }

  // /**
  //  * Replace a given segment with a different segment. Useful in cases where multiple slides share an
  //  * original slide's content, e.g. IdentifyLaptopComputer content needs to refer to IdentifyDesktopComputer l10n data.
  //  *
  //  * @param index
  //  * @param segment
  //  */
  // swapPathSegment(index: number = null, segment: string) {
  //   if (typeof index !== 'number') {
  //     index = this.pathSegments.length - 1;
  //   }
  //
  //   this.pathSegments[index] = segment;
  // }

  /**
   * Fetch the Transloco keys path. Providing the result to Transloco's .translate(path) should provide
   * localized content.
   *
   * @param suffixPath Optional path to further drill down from the original path, e.g. instead of `original/path` might
   *                   want `original/path/more/specific` so suffixPath = 'more/specific'
   * @returns {string} The path to be provided to Transloco as a key for l10n content.
   */
  getL10NPath(suffixPath: string = '') {
    if (!suffixPath) {
      return this.getL10NPathForSegments(this.pathSegments);
    }

    return this.getL10NPathForSegments([].concat(this.pathSegments, L10nNode.convertPathToSegments(suffixPath)));
  }

  /**
   * Given key segments, return a Transloco-ready key.
   *
   * Camel-casing original directory-like path because transloco doesn't work w/requesting hyphenated keys but seems to
   * store JSON's hyphenated keys as their camel-cased equivalent.
   *
   * i.e. 'original-path-supplied/sub-item/sub-sub-item' => 'originalPathSupplied.subItem.subSubItem'
   *
   * @param segments
   * @returns {string}
   */
  protected getL10NPathForSegments(segments: string[]) {
    return segments.map(camelCase).join('.');
  }

  getL10NPathForSlideContent(suffixPath: string = '') {
    return this.getL10NPath('content/' + suffixPath);
  }
}
