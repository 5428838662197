import {Component, OnInit, OnDestroy} from '@angular/core';
import {sharedSlidesSlugs} from "../shared-slides-slugs.config";
import {ToastrService} from 'ngx-toastr';
import {Router, ActivatedRoute} from "@angular/router";
import {KeyValueStoreService} from "../../../core/services/key-value-store.service";
import {IPhotoCreditConfig} from "../../../app.config";
import {TranslocoService} from "@ngneat/transloco";
import {MediaQueryService} from "../../../core/environment/media-query.service";

@Component({
  templateUrl: './assessment-orientation.component.html',
  styleUrls: [
    './assessment-orientation.component.scss',
    '../../layout/slide-wrapper/_shared-slide-wrapper.component.scss',
    '../../layout/slide-wrapper/next-button.component.scss',
    '../../layout/slide-wrapper/_shared-controls-button.scss',
    '../../layout/slide-wrapper/_shared-button-types.scss',
    '../../layout/slide-wrapper/i-dont-know-button.component.scss',
  ]
})
export class AssessmentOrientationComponent implements OnDestroy {
  static nsComponentId: string = 'AssessmentOrientationComponent';
  static slug: string = sharedSlidesSlugs.assessmentOrientation;

  l10nBasePath: string = 'shared.slides.assessmentOrientation.';

  videoPlayedThrough: boolean = false;

  /**
   * Handler function to be able to bind context to this instance.
   */
  customHandleClickNext: any;

  photoCreditConfig: IPhotoCreditConfig;

  constructor(
    protected toastr: ToastrService,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected keyStoreValueService: KeyValueStoreService,
    protected translocoService: TranslocoService,
    public mediaQueryService: MediaQueryService,
  ) {
    this.customHandleClickNext = this.handleClickNext.bind(this);
  }

  ngOnInit() {
    this.photoCreditConfig = {
      creditContent: this.translocoService.translate('shared.slides.assessmentOrientation.creditContent'),
      expanded: true
    };
  }

  getComponentId() {
    return AssessmentOrientationComponent.nsComponentId;
  }

  onNearFinished() {
    this.videoPlayedThrough = true;
    this.keyStoreValueService.set('nextButtonPulsing', true);
  }

  handleClickNext() {
    this.router.navigate(['../title'], {relativeTo: this.activatedRoute});
  }

  ngOnDestroy() {
    this.keyStoreValueService.clear('nextButtonPulsing');
  }
}
