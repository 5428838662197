import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {ApiService, apiEndpoints} from "../../services/api.service";
import {SessionService} from "../../services/session.service";
import {CustomDataStore} from "../../data-store/custom-data-store.service";
import {LoggerService} from "../../services/logger.service";
import {
  IApiModulePracticeLessonsResponse,
  ModulePracticeLessonsResolve
} from "../module-practice-lessons.resolve";

@Injectable()
export class LMSModulePracticeLessonsResolve extends ModulePracticeLessonsResolve {
  getModuleId(route: ActivatedRouteSnapshot) {
    return parseInt((<any>route.params).moduleId, 10);
  }
}
