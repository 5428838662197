import {CustomDataStore} from "./custom-data-store.service";
import {HttpAdapter} from "js-data-http";
import {Injectable, Inject} from "@angular/core";
import {IAppConfig, APP_CONFIG, appConfig} from "../../app.config";
import {SlimLoadingBarService} from "ng2-slim-loading-bar/index";
import {LoggerService} from "../services/logger.service";
import {SerializationService} from "../services/serialization.service";
import {
  Sponsor,
  User,
  Assessment,
  SponsorLearnerMembership,
  ProctorSessionForUser,
  assessmentModuleMapperConfig,
  moduleSoftwareVersionMapperConfig,
  moduleTopicMapperConfig,
  assessmentModuleStandardMapperConfig,
  assessmentModuleStandardRelationMapperConfig,
  modulePracticeLessonMapperConfig,
  modulePracticeSectionMapperConfig,
  modulePracticeUserCompletionMapperConfig,
  modulePracticeSectionReviewMapperConfig,
  modulePracticeSectionReviewUserCompletionMapperConfig,
  modulePracticeSectionReviewQuestionMapperConfig,
  modulePracticeSectionReviewQuestionResponesMapperConfig,
  moduleTopicCategoryMapperConfig
} from "northstar-foundation";
import {HttpXsrfTokenExtractor} from "@angular/common/http";
import {defaultsDeep} from "lodash";

@Injectable()
export class CustomDataStoreConfig {

  constructor(
    protected tokenExtractor: HttpXsrfTokenExtractor,
    protected loggerService: LoggerService,
    protected dataStore: CustomDataStore,
    protected slimLoadingBarService: SlimLoadingBarService,
    protected serializationService: SerializationService,
    @Inject(APP_CONFIG) public appConfig: IAppConfig
  ) {

    const configService = this;

    const NorthstarAdapter = new HttpAdapter({
      basePath: this.appConfig.apiBase,
      log: this.appConfig.debug,
      forceTrailingSlash: true,
      httpConfig: {
        withCredentials: true
      },
      deserialize: function(mapper, response, opts) {
        response.data = configService.serializationService.deserialize(response.data);

        return HttpAdapter.prototype.deserialize.call(this, mapper, response, opts);
      },
      serialize: function(mapper, data, opts) {
        data = configService.serializationService.serialize(data);

        return HttpAdapter.prototype.serialize.call(this, mapper, data, opts);
      },
      beforeHTTP: function (config: any, opts: any) {
        config.headers || (config.headers = {});

        let token = configService.tokenExtractor.getToken() as string;

        if (token !== null) {
          config.headers['X-CSRFToken'] = token;
        }

        configService.slimLoadingBarService.start();

        // Now do the default behavior
        return HttpAdapter.prototype.beforeHTTP.call(this, config, opts);
      },
      afterHTTP: function(config: any, opts: any, response: any) {
        configService.slimLoadingBarService.complete();

        // Now do the default behavior
        return HttpAdapter.prototype.afterHTTP.call(this, config, opts, response);
      },
      responseError: function(err, config, opts) {
        configService.slimLoadingBarService.complete();

        if (err && err.response && err.response.data) {
          configService.loggerService.log(['JSData error', err.response.data]);
        }

        // Now do the default behavior
        return HttpAdapter.prototype.responseError.call(this, err, config, opts);
      }
    });

    this.dataStore.registerAdapter('http', NorthstarAdapter, {'default': true});


    this.dataStore.defineMapper('module', assessmentModuleMapperConfig);
    this.dataStore.defineMapper('module_software_version', moduleSoftwareVersionMapperConfig);

    this.dataStore.defineMapper('module_topic', defaultsDeep({
      relations: {
        hasMany: {
          sponsor: {
            localField: 'sponsorsHiding',
            foreignKeys: 'topicsHiddenIds'
          },
        }
      }
    }, moduleTopicMapperConfig));

    this.dataStore.defineMapper('module_topic_category', defaultsDeep({
      relations: {
        hasMany: {
          sponsor: {
            localField: 'sponsorsHiding',
            foreignKeys: 'topicCategoriesHiddenIds'
          },
        }
      }
    }, moduleTopicCategoryMapperConfig));

    this.dataStore.defineMapper('module_standard', assessmentModuleStandardMapperConfig);
    this.dataStore.defineMapper('module_standard_relation', assessmentModuleStandardRelationMapperConfig);
    this.dataStore.defineMapper('module_practice_section', modulePracticeSectionMapperConfig);
    this.dataStore.defineMapper('module_practice_lesson', modulePracticeLessonMapperConfig);
    this.dataStore.defineMapper('module_practice_user_completion', modulePracticeUserCompletionMapperConfig);
    this.dataStore.defineMapper('module_practice_section_review', modulePracticeSectionReviewMapperConfig);
    this.dataStore.defineMapper('module_practice_section_review_user_completion', modulePracticeSectionReviewUserCompletionMapperConfig);
    this.dataStore.defineMapper('module_practice_section_review_question', modulePracticeSectionReviewQuestionMapperConfig);
    this.dataStore.defineMapper('module_practice_section_review_question_response', modulePracticeSectionReviewQuestionResponesMapperConfig);

    // custom mapper below rather than what northstar-foundation has because related mappers not yet set up
    this.dataStore.defineMapper('sponsor', {
      recordClass: Sponsor,
      endpoint: 'sponsors',
      relations: {
        hasMany: {
          sponsor_learner_membership: {
            localField: 'sponsorLearnerMemberships',
            foreignKey: 'sponsorId'
          },
          module_topic: {
            localKeys: 'topicsHiddenIds',
            localField: 'topicsHidden'
          },
          module_topic_category: {
            localKeys: 'topicCategoriesHiddenIds',
            localField: 'topicCategoriesHidden'
          },
        }
      }
    });

    this.dataStore.defineMapper('user', {
      recordClass: User,
      endpoint: 'users',
      relations: {
        hasMany: {
          assessment: {
            localField: 'assessments',
            foreignKey: 'userId'
          },
          module_practice_user_completion: {
            localField: 'lessonCompletions',
            foreignKey: 'userId'
          },
          module_practice_section_review_user_completion: {
            localField: 'sectionReviewCompletions',
            foreignKey: 'userId'
          },
          sponsor_learner_membership: {
            localField: 'sponsorLearnerMemberships',
            foreignKey: 'userId'
          }
        }
      }
    });

    this.dataStore.defineMapper('assessment', {
      recordClass: Assessment,
      endpoint: 'assessments',
      relations: {
        belongsTo: {
          module: {
            localField: 'module',
            localKey: 'moduleId'
          },
          user: {
            localField: 'user',
            localKey: 'userId'
          }
        }
      }
    });

    this.dataStore.defineMapper('sponsor_learner_membership', {
      recordClass: SponsorLearnerMembership,
      endpoint: 'learner-memberships',
      relations: {
        belongsTo: {
          user: {
            localField: 'user',
            localKey: 'userId'
          },
          sponsor: {
            localField: 'sponsor',
            localKey: 'sponsorId'
          }
        }
      }
    });

    this.dataStore.defineMapper('proctor_session_for_user', {
      recordClass: ProctorSessionForUser,
      endpoint: 'proctor-sessions-for-user',
      relations: {
        belongsTo: {
          user: {
            localField: 'user',
            localKey: 'userId'
          }
        }
      }
    });
  }
}
