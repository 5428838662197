import {NgModule, Optional, SkipSelf} from '@angular/core';
import { CommonModule } from '@angular/common';
import {throwIfAlreadyLoaded} from "./module-import-guard";
import {LoggerService} from "./services/logger.service";
import {BootstrapDataService} from "./bootstrap/bootstrap-data.service";
import {CaptionsSettingsService} from "./settings/captions-settings.service";
import {ResponseCollectionService} from "./engagement/response-collection.service";
import {SlideNavigationService} from "./engagement/slide-navigation.service";
import {AudioSettingsService} from "./settings/audio-settings.service";
import {InterfaceInteractionStagerService} from "./engagement/interface-interaction-stager.service";
import {LoadingOverlayService} from "./engagement/loading-overlay.service";
import {LoadingOverlayComponent} from "./engagement/loading-overlay.component";
import {CanActivateSlide} from "./guards/can-activate-slide.guard";
import {TransparentBackdropService} from "./engagement/transparent-backdrop.service";
import {TransparentBackdropComponent} from "./engagement/transparent-backdrop.component";
import {ApiService} from "./services/api.service";
import {ResultsDataResolve} from "./resolves/results-data.resolve";
import {BadgingService} from "./badging/badging.service";
import {ModalService} from "./modal/modal.service";
import {ResultsDataHistoricalResolve} from "./resolves/results-data-historical.resolve";
import {KeyValueStoreService} from "./services/key-value-store.service";
import {AssetService} from "./services/asset.service";
import {PlatformService} from "./environment/platform.service";
import {KeyboardEventInterpreterService} from "./engagement/keyboard-event-interpreter.service";
import {EnvironmentService} from "./environment/environment.service";
import {MediaQueryService} from "./environment/media-query.service";
import {SerializationService} from "./services/serialization.service";
import {CustomDataStoreConfig} from "./data-store/custom-data-store-config.service";
import {CustomDataStore} from "./data-store/custom-data-store.service";
import {HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS} from "@angular/common/http";
import {XsrfInterceptor} from "./services/xsrf.interceptor";
import {MocksForDevResolve} from "./resolves/mocks-for-dev.resolve";
import {SessionService} from "./services/session.service";
import {ModulesResolve} from "./resolves/modules.resolve";
import {ModulePracticeLessonsResolve} from "./resolves/module-practice-lessons.resolve";
import {PracticeBootstrapResolve} from "./resolves/practice-bootstrap.resolve";
import {MeResolve} from "./resolves/me.resolve";
import {ModulePracticeLessonCompleteResolve} from "./resolves/module-practice-lesson-complete.resolve";
import {PopoverService} from "./popover/popover.service";
import {RouterHistoryService} from "./services/router-history.service";
import {TimeOnTaskService} from "./services/time-on-task.service";
import {YouTubePlayerService} from "./services/youtube-player.service";
import {ModulePracticeSectionReviewCompleteResolve} from "./resolves/module-practice-section-review-complete.resolve";
import {LMSModulePracticeLessonsResolve} from "./resolves/lms/module-practice-lessons.resolve";
import {LMSModuleAssessmentsHistoryResolve} from "./resolves/lms/module-assessments-history.resolve";
import {LMSBootstrapResolve} from "./resolves/lms/lms-bootstrap.resolve";
import {ProctorSessionForUserService} from "./services/proctor-session-for-user.service";
import {AssessmentTokenService} from "./services/assessment-token.service";
import {L10nForAssessmentResolve} from "./resolves/l10n/l10n-for-assessment.resolve";
import {L10nForPracticeResolve} from "./resolves/l10n/l10n-for-practice.resolve";
import {ContentShadowManagerService} from "./engagement/content-shadow-manager.service";
import {ServerSettingsService} from "./settings/server-settings.service";
import {TranslocoModule} from "@ngneat/transloco";
import {GoogleTagManagerService} from "northstar-foundation/angular";

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TranslocoModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'csrftoken',
      headerName: 'X-CSRFToken',
    }),
  ],
  providers: [
    LoggerService,
    ApiService,
    CustomDataStore,
    CustomDataStoreConfig,
    BootstrapDataService,
    CaptionsSettingsService,
    AudioSettingsService,
    ResponseCollectionService,
    SlideNavigationService,
    InterfaceInteractionStagerService,
    LoadingOverlayService,
    TransparentBackdropService,
    CanActivateSlide,
    MocksForDevResolve,
    ResultsDataResolve,
    MeResolve,
    ModulesResolve,
    ModulePracticeLessonsResolve,
    ModulePracticeLessonCompleteResolve,
    PracticeBootstrapResolve,
    ResultsDataHistoricalResolve,
    ModulePracticeSectionReviewCompleteResolve,
    BadgingService,
    ModalService,
    PopoverService,
    SessionService,
    ProctorSessionForUserService,
    AssessmentTokenService,
    KeyValueStoreService,
    AssetService,
    PlatformService,
    KeyboardEventInterpreterService,
    EnvironmentService,
    MediaQueryService,
    SerializationService,
    YouTubePlayerService,
    RouterHistoryService,
    TimeOnTaskService,
    LMSBootstrapResolve,
    LMSModuleAssessmentsHistoryResolve,
    LMSModulePracticeLessonsResolve,
    L10nForAssessmentResolve,
    L10nForPracticeResolve,
    ContentShadowManagerService,
    ServerSettingsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XsrfInterceptor,
      multi: true
    }
  ],
  declarations: [
    LoadingOverlayComponent,
    TransparentBackdropComponent
  ],
  exports: [
    LoadingOverlayComponent,
    TransparentBackdropComponent
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
