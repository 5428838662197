import { Injectable } from "@angular/core";
import { NgbDatepickerI18n, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { TranslocoService } from "@ngneat/transloco";
import { L10nDateFormatPipe } from "./l10n-date-format.pipe";

@Injectable()
export class NSDatepickerI18n extends NgbDatepickerI18n {
 
    constructor(
      protected translocoService: TranslocoService
    ) { super(); }

    lang: string = this.translocoService.getActiveLang();
  
    tag = L10nDateFormatPipe.toBCP47tag[this.lang];

    shortWeekdayFormat = new Intl.DateTimeFormat(this.tag, { weekday: 'short' });
    shortMonthFormat = new Intl.DateTimeFormat(this.tag, { month: 'short' });
    longMonthFormat = new Intl.DateTimeFormat(this.tag, { month: 'long' });
    ariaDayLabelFormat = new Intl.DateTimeFormat(this.tag, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });

    getWeekdayShortName(weekday:number): string {
      // Monday(1) is the first day of a NgbDatepickerI18n calendar week. 
      // Using June 1970 because Monday is day 1 of the month, no offset calculation.
      return this.shortWeekdayFormat.format(new Date(1970, 5, weekday));
    }
 
    getMonthShortName(month:number): string {
      // NgbDatepickerI18n months are 1 indexed Date object months are zero indexed.
      return this.shortMonthFormat.format(new Date().setMonth(month-1));
    }
 
    getMonthFullName(month:number): string {
      return this.shortMonthFormat.format(new Date().setMonth(month-1));
    }

    getDayAriaLabel(date: NgbDateStruct): string {
      return this.ariaDayLabelFormat.format(new Date(date.year, date.month-1, date.day));
    }
}
