import {Component, Input, HostBinding, Output, EventEmitter} from "@angular/core";
export interface IFlipCardStandard {
  id: string;
  title: string;
  label: string;
}

@Component({
  selector: 'flip-card',
  templateUrl: 'flip-card.component.html',
  styleUrls: ['./flip-card.component.scss'],
})
export class FlipCardComponent {

  @HostBinding('class.flipped')
  flipped: boolean = false;

  @Input()
  width: number = 275;

  @Input()
  height: number = 275;

  @Output()
  onFlipped: EventEmitter<boolean> = new EventEmitter<boolean>();

  toggle(event) {
    event.preventDefault();

    this.flipped = !this.flipped;
    this.onFlipped.emit(this.flipped);
  }
}
