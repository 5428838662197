import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {ApiService, apiEndpoints} from "../../services/api.service";
import {SessionService} from "../../services/session.service";
import {CustomDataStore} from "../../data-store/custom-data-store.service";
import {LoggerService} from "../../services/logger.service";
import {IApiPaginatedDataResponse, User} from "northstar-foundation";
import {of} from "rxjs";
import {switchMap, catchError} from "rxjs/operators";

@Injectable()
export class LMSModuleAssessmentsHistoryResolve implements Resolve<any> {

  constructor(protected loggerService: LoggerService,
              protected dataStore: CustomDataStore,
              public sessionService: SessionService,
              protected apiService: ApiService) {
  }

  resolve(route: ActivatedRouteSnapshot) {
    const urlModuleIdParam = parseInt((<any>route.params).moduleId, 10);

    return this.apiService.httpGet(`${apiEndpoints.recordAssessment}?user_id=${(<User>this.sessionService.user).id}&modules=${urlModuleIdParam}`)
      .pipe(
        switchMap((response: IApiPaginatedDataResponse) => of(this.dataStore.add('assessment', response.results))),
        catchError(response => of(false))
      );
  }
}
