import {Component, OnInit, ElementRef, ViewChild, ViewChildren, AfterViewInit} from '@angular/core';
import {sharedSlidesSlugs} from "../shared-slides-slugs.config";
import {SlideNavigationService} from "../../../core/engagement/slide-navigation.service";
import {ProctorSessionForUserService} from "../../../core/services/proctor-session-for-user.service";
import {ActivatedRoute} from "@angular/router";
import {TranslocoService} from "@ngneat/transloco";
import {MediaQueryService} from "../../../core/environment/media-query.service";
import {AssetService} from "../../../core/services/asset.service";
import {ContentShadowManagerService} from "../../../core/engagement/content-shadow-manager.service";
import {ContentShadowComponent} from "../../layout/slide-wrapper/content-shadow.component";

@Component({
  templateUrl: './submission-confirmation.component.html',
  styleUrls: [
    './submission-confirmation.component.scss',
    '../../layout/slide-wrapper/_shared-slide-wrapper.component.scss',
    '../../layout/slide-wrapper/_shared-controls-button.scss',
    '../../layout/slide-wrapper/_shared-button-types.scss'
  ]
})
export class SubmissionConfirmationComponent implements OnInit, AfterViewInit {
  static nsComponentId: string = 'SubmissionConfirmationComponent';
  static slug: string = sharedSlidesSlugs.submissionConfirmation;

  @ViewChild('mainContent')
  mainContent: ElementRef;

  @ViewChildren(ContentShadowComponent)
  contentShadows: ContentShadowComponent[];

  numIncomplete: number;
  incompleteTasks: {task: any, title: string}[] = [];

  l10nBasePath: string = 'shared.slides.submissionConfirmation';

  slideContent: any = this.translocoService.translateObject(this.l10nBasePath);

  constructor(
    public navigation: SlideNavigationService,
    protected proctorSessionForUserService: ProctorSessionForUserService,
    protected route: ActivatedRoute,
    protected translocoService: TranslocoService,
    public mediaQueryService: MediaQueryService,
    public assetService: AssetService,
    public contentShadowManagerService: ContentShadowManagerService,
  ) {
  }

  getTitle() {
    return this.slideContent.title;
  }

  getSlug() {
    return SubmissionConfirmationComponent.slug;
  }

  getComponentId() {
    return SubmissionConfirmationComponent.nsComponentId;
  }

  ngOnInit() {
    this.numIncomplete = this.navigation.getNumIncompleteTasks();
    // don't call nav service's onVisitSlideComponentInstance since that tries
    // to keep track of the 'visited' status of this slide, but this slide doesn't
    // even show up in the nav menu so it's irrelevant and would complicate logic elsewhere

    if (this.numIncomplete > 0) {
      this.incompleteTasks = this.navigation.getIncomplete();
    }

    // check that a proctored token is still valid before submitting, otherwise fetch unproctored token
    if (this.proctorSessionForUserService.getSession()) {
      this.proctorSessionForUserService.listenForActiveUserSessionStatus();
    }
  }

  ngAfterViewInit() {
    this.contentShadowManagerService.track(this.mainContent, this.contentShadows);
  }

  goToResultsSlide() {
    // keeping logic w/in method in case we want to make more robust by checking one more time whether proctored
    // session token is still valid
    this.navigation.goToResultsSlide(this.route);
  }
}
