import {Component, OnInit, Input, HostBinding, OnDestroy} from '@angular/core';
import {FadeInOut, FadeOut} from "../../animations";
import {LoadingOverlayService} from "../../../core/engagement/loading-overlay.service";
import {MediaQueryService} from "../../../core/environment/media-query.service";
import {BaseSlideWrapperComponent} from "../base-slide-wrapper.component";
import {ContentShadowManagerService} from "../../../core/engagement/content-shadow-manager.service";
import {CaptionsSettingsService} from "../../../core/settings/captions-settings.service";

@Component({
  selector: 'practice-review-question-slide-wrapper',
  templateUrl: './practice-review-question-slide-wrapper.component.html',
  styleUrls: ['./practice-review-question-slide-wrapper.component.scss'],
  animations: [FadeInOut(500), FadeOut(250)]
})
export class PracticeReviewQuestionSlideWrapperComponent extends BaseSlideWrapperComponent implements OnInit, OnDestroy {

  constructor(
    protected loadingOverlayService: LoadingOverlayService,
    public mediaQueryService: MediaQueryService,
    public contentShadowManagerService: ContentShadowManagerService,
    public captionsSettingsService: CaptionsSettingsService,
  ) {
    super(
      mediaQueryService,
      contentShadowManagerService,
      captionsSettingsService
    );
  }

  ngOnInit() {
    this.loadingOverlayService.setMode('loading');

    // don't show overlay for section-review questions if user navigates to them
    this.config.askUserToConfirmRetry = false;
  }

  ngOnDestroy() {
    this.loadingOverlayService.setMode('congrats');
  }
}
