import {Component, OnInit, Inject, HostListener, OnDestroy} from '@angular/core';
import {AssessmentModule, Sponsor, User} from "northstar-foundation";
import {SlideNavigationService} from "../../../core/engagement/slide-navigation.service";
import {ActivatedRoute, Router} from "@angular/router";
import {LoggerService} from "../../../core/services/logger.service";
import {AssetService} from "../../../core/services/asset.service";
import {CustomDataStore} from "../../../core/data-store/custom-data-store.service";
import {APP_CONFIG, IAppConfig} from "../../../app.config";
import {find, isArray} from "lodash";
import {BootstrapDataService} from "../../../core/bootstrap/bootstrap-data.service";
import {TranslateFade} from "../../animations";
import {SessionService} from "../../../core/services/session.service";
import {Subscription} from "rxjs";
import {LoadingOverlayService} from "../../../core/engagement/loading-overlay.service";
import {ProctorSessionForUserService} from "../../../core/services/proctor-session-for-user.service";
import {MediaQueryService} from "../../../core/environment/media-query.service";
import {TranslocoService} from "@ngneat/transloco";

@Component({
  selector: 'ns-module-wrapper',
  templateUrl: './module-wrapper.component.html',
  styleUrls: ['./module-wrapper.component.scss'],
  animations: [TranslateFade()]
})
export class ModuleWrapperComponent implements OnInit, OnDestroy {
  module: AssessmentModule;
  slidesToTasks: any[];
  showPublicVersionExplanation: boolean = false;
  routerSubscription: Subscription;

  constructor(
    @Inject(APP_CONFIG) protected appConfig: IAppConfig,
    protected loggerService: LoggerService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected navigation: SlideNavigationService,
    protected assetService: AssetService,
    protected dataStore: CustomDataStore,
    protected bootstrapData: BootstrapDataService,
    public sessionService: SessionService,
    public proctorSessionForUserService: ProctorSessionForUserService,
    protected loadingOverlayService: LoadingOverlayService,
    public mediaQueryService: MediaQueryService,
    protected translocoService: TranslocoService,
  ) {
    this.routerSubscription = this.router.events.subscribe(this.onRouterEvents.bind(this))
  }

  ngOnInit() {
    this.initModule();
  }

  initModule() {
    // in dev environment, might load assessment 1/2 way through, skipping the bootstrap process,
    // so try to find right module based on URL being requested
    if (this.appConfig.apiMocks) {
      this.module = find(this.dataStore.filter('module', {where: {variantCode: {'>': 0}}}), (module: AssessmentModule) => {
        return this.router.url.indexOf(this.getExpectedUrlForModule(module)) === 1;
      });
      this.bootstrapData.setModule(this.module);
    } else {
      // typically, though, will already know the intended module from bootstrap process
      this.module = this.bootstrapData.getModule();
    }

    this.navigation.setModule(this.module)
                   .populateSlides(this.slidesToTasks);
    this.assetService.registerModule(this.module);
  }

  getExpectedUrlForModule(module: AssessmentModule) {
    return module.getResolvedModulePath();
  }

  /**
   * When user tries to refresh page or close tab/window, ask them to confirm so they don't ruin their assessment
   * on accident.
   *
   * @param $event
   */
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
      if (!this.navigation.getAssessmentComplete() && !this.appConfig.debug) {
          $event.returnValue = true;
      }
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

  onRouterEvents(routerValue) {
    return; // overridden by PracticeWrapperComponent for more logic
  }
}
