import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

 

/**
 * Format a Date for the active transloco language.
 * 
 *    DATE | l10nDateFormat [: OPTIONS]
 *  
 * Options documentation: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 *
 * Examples witn active language 'en':
 * 
 * {{ assignment.dateAssigned  | l10nDateFormat : { month: 'long', day: '2-digit', year: 'numeric' } }} 
 * May 12, 2022
 * 
 * {{ assignment.dateAssigned  | l10nDateFormat }}
 * 5/12/2022
 * 
 * Examples with active language 'es_419':
 * 
 * {{ assignment.dateAssigned  | l10nDateFormat : { month: 'long', day: '2-digit', year: 'numeric' } }} 
 * 12 de mayo de 2022
 * 
 * {{ assignment.dateAssigned | l10nDateFormat }}
 * 12/5/2022
 * 
 */
@Pipe({
  name: 'l10nDateFormat'
})
export class L10nDateFormatPipe implements PipeTransform {

  constructor(protected translocoService: TranslocoService,) {
  }

  static toBCP47tag = {
    en: 'en-US',
    es_419: 'es-419',
  }; 

  lang: string = this.translocoService.getActiveLang();
  
  tag = L10nDateFormatPipe.toBCP47tag[this.lang];

  transform(date: Date, options?: any): string {
    return new Intl.DateTimeFormat(this.tag, options).format(date);
  }
}
