import { Component, OnInit } from '@angular/core';
import {SlideNavigationService, SlideToTaskSequence} from "../../../core/engagement/slide-navigation.service";
import {ActivatedRoute} from "@angular/router";
import {sharedSlidesSlugs} from "../shared-slides-slugs.config";
import {analyticsValues} from "northstar-foundation";
import {BootstrapDataService} from "../../../core/bootstrap/bootstrap-data.service";
import {SessionService} from "../../../core/services/session.service";
import {GoogleTagManagerService} from "northstar-foundation/angular";
import {TranslocoService} from "@ngneat/transloco";
import {Angulartics2} from "angulartics2";
import {MediaQueryService} from "../../../core/environment/media-query.service";

@Component({
  templateUrl: './module-title-and-start.component.html',
  styleUrls: [
    '../../layout/slide-wrapper/_shared-slide-wrapper.component.scss',
    './module-title-and-start.component.scss',
    '../../layout/slide-wrapper/_shared-controls-button.scss',
    '../../layout/slide-wrapper/_shared-button-types.scss'
  ]
})
export class ModuleTitleAndStartComponent implements OnInit {
  static nsComponentId: string = 'ModuleTitleAndStartComponent';
  static slug: string = sharedSlidesSlugs.moduleTitleAndStart;

  l10nBasePath: string = 'shared.slides.moduleTitleAndStart.';

  constructor(
    public navigation: SlideNavigationService,
    protected route: ActivatedRoute,
    private angulartics2: Angulartics2,
    private gtmService: GoogleTagManagerService,
    private bootstrapDataService: BootstrapDataService,
    protected sessionService: SessionService,
    protected translocoService: TranslocoService,
    public mediaQueryService: MediaQueryService,
  ) {}

  getComponentId() {
    return ModuleTitleAndStartComponent.nsComponentId;
  }

  goToFirstSlide(event) {
    event.preventDefault();

    // increment starts in session by 1
    (<any>window).ga('set', analyticsValues.metrics.assessmentStart.analyticsKey, 1);

    const sponsor = this.sessionService.getSponsor();
    const module = this.bootstrapDataService.getModule();

    this.angulartics2.eventTrack.next({
      action: 'Start',
      properties: {
        category: 'Assessment',
        label: module ? module.id : 0,
        value: sponsor ? sponsor.id : 0
      }
    });

    this.gtmService.trackEvent('assessment_start', {
      module: module ? module.id : 0,
      location: sponsor ? sponsor.id : 0,
      lang: this.translocoService.getActiveLang(),
    });

    this.navigation.goToSlideNum(<SlideToTaskSequence[]>this.navigation.slides, 1, this.route);
  }

  ngOnInit() {
  }

}
