import {Injectable, ElementRef} from "@angular/core";
import {debounce, each} from "lodash";
import {LoggerService} from "../services/logger.service";

/**
 * Num pixels that the page may be scrollable by and the shadows not show up, e.g. to prevent excessive shadowing
 * if the scrolling is just a matter of a couple pixels.
 *
 * @type {number}
 */
const contentScrollableTolerance: number = 15;

@Injectable()
export class ContentShadowManagerService {

  onScrollContent: any;

  contentIsScrolledTo: {top: boolean, bottom: boolean, left: boolean, right: boolean} = {
    top: true,
    bottom: true,
    left: true,
    right: true
  };

  element: ElementRef;
  contentShadows: any[];

  contentShadowByDirection: {top: any, bottom: any, left: any, right: any} = {top: null, bottom: null, left: null, right: null};

  debug: boolean = false;

  constructor(
    protected loggerService: LoggerService,
  ) {}

  reset() {
    this.element = null;
    this.contentShadows = [];
    this.contentShadowByDirection = {top: null, bottom: null, left: null, right: null};
  }

  track(element: ElementRef, contentShadows: any[]) {
    this.reset();

    this.element = element;
    this.contentShadows = contentShadows;

    // make shadows more easily queryable
    this.contentShadows.forEach(shadow => {
      this.contentShadowByDirection[shadow.anchor] = shadow;
    });

    // set up performance-scalable listener - requires wiring up to a (scroll) event in a template
    this.onScrollContent = debounce(this.onScrollElement.bind(this), 100, {
      maxWait: 200,
      leading: true
    });

    // initialize
    setTimeout(() => this.onScrollElement(new CustomEvent('init')), 0);
  }

  onScrollElement(event) {
    if (this.debug) {
      this.showDebugInfo();
    }

    this.updateShadows();
  }

  showDebugInfo() {
    this.loggerService.log([
      'scrollHeight', this.element.nativeElement.scrollHeight,
      'clientHeight', this.element.nativeElement.clientHeight,
      'scrollTop', this.element.nativeElement.scrollTop,
    ])
  }

  updateShadows() {
    if (this.contentShadowByDirection.top) {
      this.contentShadowByDirection.top.setShadowActive(!this.elementScrolledToTop());
    }

    if (this.contentShadowByDirection.bottom) {
      this.contentShadowByDirection.bottom.setShadowActive(!this.elementScrolledToBottom());
    }
  }

  elementScrolledToTop() {
    return this.element.nativeElement.scrollTop < contentScrollableTolerance;
  }

  elementScrolledToBottom() {
    return (this.element.nativeElement.scrollHeight - this.element.nativeElement.scrollTop - contentScrollableTolerance) <
      this.element.nativeElement.clientHeight;
  }
}
