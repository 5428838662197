import {Component, OnInit, OnDestroy, Input, HostListener, HostBinding, ElementRef, Renderer2} from '@angular/core';
import {PopoverService} from "../../../core/popover/popover.service";
import {FadeInOut} from "../../animations";
import {LoggerService} from "../../../core/services/logger.service";
import {MediaQueryService} from "../../../core/environment/media-query.service";

const huggingOffsets = {
  x: 20,
  y: 20
};

@Component({
  selector: 'popover',
  templateUrl: './popover.component.html',
  styleUrls: [
    './popover.component.scss'
  ],
  animations: [FadeInOut(400)]
})
export class PopoverComponent implements OnInit, OnDestroy {
  hidden: boolean = true;

  @Input()
  caretPosition: 'top'|'right'|'bottom'|'left' = 'bottom';

  /**
   * Property to easily filter/find in parent components via ViewChildren and .forEach
   */
  @Input()
  id: string;


  /**
   * Whether popover should draw attention to itself at a predefined interval, e.g. jiggle
   *
   * @type {boolean}
   */
  @HostBinding('class.noisy')
  @Input()
  noisy: boolean = true;

  /**
   * If wanting to auto-position this element based on some other element, provide the HTML ID of the other element.
   * Convenient for general use but especially nice for dynamic content of unknown size.
   *
   * @type {string}
   */
  @Input()
  hug: string = '';

  constructor(
    public popoverService: PopoverService,
    public elRef: ElementRef,
    protected loggerService: LoggerService,
    protected renderer2: Renderer2,
    public mediaQueryService: MediaQueryService,
  ) {
  }

  ngOnInit() {
    this.popoverService.register(this);
  }

  ngOnDestroy() {
    this.popoverService.unregister(this);
  }

  show() {
    this.hidden = false;

    if (this.hug) {
      // give time for display of object to happen in order to calculate sizing, then hug object
      setTimeout(() => this.hugObject(this.hug), 1);
    }
  }

  hide() {
    this.hidden = true;
  }

  toggle() {
    if (this.hidden) {
      this.show();
      return;
    }

    this.hide();
  }

  scrollIntoView() {
    this.elRef.nativeElement.scrollIntoView();
  }

  /**
   * Convenience method to
   *
   * @param id
   */
  hugObject(id: string) {
    const huggedObject = window.document.getElementById(id);

    if (!huggedObject) {
      this.loggerService.error(`No object with ID ${id}`);
      return;
    }

    const popoverRect = this.elRef.nativeElement.getBoundingClientRect();
    const popoverParentRect = this.elRef.nativeElement.parentElement.getBoundingClientRect();
    const huggedRect = huggedObject.getBoundingClientRect();

    const leftOfHuggedObject = huggedRect.left - popoverParentRect.left;
    const topOfHuggedObject = huggedRect.top - popoverParentRect.top;

    const centeringForCaretPosition = {
      top: () => {
        // appear below hugged object
        return {
          left: leftOfHuggedObject + (huggedRect.width / 2) - (popoverRect.width / 2),
          top: topOfHuggedObject + huggedRect.height + huggingOffsets.y
        }
      },
      bottom: () => {
        // appear above hugged object
        return {
          left: leftOfHuggedObject + (huggedRect.width / 2) - (popoverRect.width / 2),
          top: topOfHuggedObject - popoverRect.height - huggingOffsets.y
        }
      },
      left: () => {
        // appear to right of hugged object
        return {
          left: leftOfHuggedObject + huggedRect.width + huggingOffsets.x,
          top: topOfHuggedObject + (huggedRect.height / 2) - (popoverRect.height / 2)
        }
      },
      right: () => {
        // appear to left of hugged object
        return {
          left: leftOfHuggedObject - popoverRect.width - huggingOffsets.x,
          top: topOfHuggedObject + (huggedRect.height / 2) - (popoverRect.height / 2)
        }
      },
    };

    const finalPositioning = centeringForCaretPosition[this.caretPosition]();

    this.renderer2.setStyle(this.elRef.nativeElement, 'left', finalPositioning.left + 'px');
    this.renderer2.setStyle(this.elRef.nativeElement, 'top', finalPositioning.top + 'px');
  }
}
