import {Injectable, Inject} from "@angular/core";
import {BootstrapDataService} from "../bootstrap/bootstrap-data.service";
import {AssessmentModule} from "northstar-foundation";
import {IAppConfig, APP_CONFIG} from "../../app.config";
import {LoggerService} from "./logger.service";

@Injectable()
export class AssetService {
  activeModuleSlug: string = null;

  constructor(
    @Inject(APP_CONFIG) protected appConfig: IAppConfig,
    protected bootstrapData: BootstrapDataService,
    protected loggerService: LoggerService
  ) {}

  /**
   * In order to simplify fetching modules' images and make more DRY, allow setting of current module.
   * Can then just call assetService.getModuleImage('image.png')
   *
   * @param module
   */
  registerModule(module: AssessmentModule) {
    this.activeModuleSlug = module.softwareVersion.isBase
      // topic-only folder if no software version
      ? module.topic.slug
      // "topic/version" if software version exists
      : `${module.topic.slug}/${module.softwareVersion.slug}`;
  }

  getCaption(pathNoExtension) {
    const path = `${this.appConfig.captionsFolder}${pathNoExtension}.vtt`;

    return this.getUrlWithCacheVersioning(path);
  }

  getAudio(filenameNoExtension) {
    const completePath = `${this.appConfig.audioFolder}${filenameNoExtension}.mp3`;

    return this.getUrlWithCacheVersioning(completePath);
  }

  getInterfaceImage(filenameWithExtension) {
    return this.getImage(filenameWithExtension, 'interface');
  }

  getSharedImage(filenameWithExtension) {
    return this.getImage(filenameWithExtension, 'shared');
  }

  getModuleImage(filenameWithExtension) {
    return this.getImage(filenameWithExtension, this.activeModuleSlug);
  }

  getImage(filenameWithExtension, folder:string='') {
    const folderWithBase = this.appConfig.imgFolder + (folder ? `${folder}/` : '');
    const completePath = `${folderWithBase}${filenameWithExtension}`;

    return this.getUrlWithCacheVersioning(completePath);
  }

  /**
   * Return a provided path with a cache identifier added to the end. Coordinates with the cache
   * version provided upon bootstrap, ultimately provided by backend. Backend value needs to be
   * updated when CDN contents of assessments are updated in order for users to get latest data.
   *
   * @param path
   * @returns {string}
   */
  getUrlWithCacheVersioning(path) {
    const version = this.bootstrapData.getCacheVersion();

    return `${path}?cacheversion=${version}`;
  }
}
