/**
  * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
  *
  * @param {String} text The text to be rendered.
  * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
  *
  * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
  */
export function getWidthOfText(text, font = null) {
  // re-use canvas for better performance
  const canvas = (<any>getWidthOfText).measurementCanvas || ((<any>getWidthOfText).measurementCanvas = document.createElement("canvas"));
  const context = canvas.getContext("2d");

  if (font) {
    context.font = font;
  }

  const metrics = context.measureText(text);
  return metrics.width;
}

const unsecuredCopyToClipboard = (text) => {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try{
    document.execCommand('copy')
  } catch (err) {
    console.error('Unable to copy to clipboard',err)
  }

  document.body.removeChild(textArea)
};

export async function copyToClipboard(text) {
  if ((<any>window).isSecureContext && (<any>navigator).clipboard) {
    (<any>navigator).clipboard.writeText(text);
  } else {
    unsecuredCopyToClipboard(text);
  }
}
