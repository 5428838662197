import {Injectable} from "@angular/core";
import {pull, each, filter, includes} from "lodash";
import {LoggerService} from "../services/logger.service";
import {PopoverComponent} from "../../shared/layout/ui-helpers/popover.component";
import {PointerComponent} from "../../shared/layout/ui-helpers/pointer.component";
import {ContentBubbleComponent} from "../../shared/layout/ui-helpers/content-bubble.component";

/**
 * Service to manage popovers, or similar components like pointers.
 */
@Injectable()
export class PopoverService {
  popovers: PopoverComponent[] = [];

  constructor(protected loggerService: LoggerService) {
  }

  register(popover: any) {
    this.popovers.push(popover);

    // this.loggerService.log(['Added popover. Currently:', this.popovers]);
  }

  unregister(popover: any) {
    this.popovers = pull(this.popovers, popover);

    // this.loggerService.log(['Removed popover. Currently:', this.popovers]);
  }

  unregisterAll() {
    this.popovers = [];
  }

  /**
   * Show only the popovers that have the given IDs. Convenience method to keep UI components more simple.
   *
   * @param ids
   */
  showIds(ids: string[]) {
    let toShow = this.getPopoversById(ids);

    this.show(toShow);
  }

  showId(id: string) {
    this.showIds([id]);
  }

  scrollTo(id: string) {
    const popover: PopoverComponent|PointerComponent|ContentBubbleComponent = this.getPopoversById([id])[0];

    popover.scrollIntoView();
  }

  showAndScrollTo(id: string) {
    this.showId(id);
    // Scroll after show timer in show(popovers: (PopoverComponent|PointerComponent)[])
    setTimeout(() => {this.scrollTo(id)}, 250);
  }

  showAll() {
    this.show(this.popovers);
  }

  show(popovers: (PopoverComponent|PointerComponent|ContentBubbleComponent)[]) {
    each(popovers, (popover, index) => {
      // delay sequential displays slightly
      setTimeout(() => {popover.show()}, index * 50);
    });
  }

  popoverVisible(id: string) {
    const popover: PopoverComponent|PointerComponent|ContentBubbleComponent = this.getPopoversById([id])[0];

    if (!popover) {
      return false;
    }

    return !popover.hidden;
  }

  /**
   * Hide only the popovers that have the given IDs.
   *
   * @param ids
   */
  hideIds(ids: string[]) {
    let toHide = this.getPopoversById(ids);

    this.hide(toHide);
  }

  hideId(id: string) {
    this.hideIds([id]);
  }

  hideAll() {
    this.hide(this.popovers);
  }

  hide(popovers: (PopoverComponent|PointerComponent|ContentBubbleComponent)[]) {
    each(popovers, popover => {
      popover.hide();
    });
  }

  getPopoversById(ids: string[]) {
    return <(PopoverComponent|PointerComponent|ContentBubbleComponent)[]>filter(this.popovers, (popover: PopoverComponent|PointerComponent|ContentBubbleComponent) => {
      return includes(ids, popover.id);
    });
  }

  toggle(ids: string[]) {
    const popovers: (PopoverComponent|PointerComponent|ContentBubbleComponent)[] = this.getPopoversById(ids);

    each(popovers, (popover: PopoverComponent|PointerComponent) => {
      popover.toggle();
    });
  }
}
