import {Component, OnInit, Input, Output, EventEmitter, Inject} from '@angular/core';
import {SlideNavigationService} from "../../../core/engagement/slide-navigation.service";
import {IModuleTask, IAppConfig, APP_CONFIG} from "../../../app.config";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from 'ngx-toastr';
import {TranslocoService} from "@ngneat/transloco";

@Component({
  selector: 'slide-nav-link',
  templateUrl: './slide-nav-link.component.html',
  styleUrls: ['./slide-nav-link.component.scss']
})
export class SlideNavLinkComponent implements OnInit {
  @Input()
  component: any;

  task: IModuleTask;

  @Output('onToggleSelf')
  toggleSelf: EventEmitter<any> = new EventEmitter<any>();

  summary: string = null;

  constructor(
    protected navigation: SlideNavigationService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected toastr: ToastrService,
    @Inject(APP_CONFIG) protected appConfig: IAppConfig,
    protected translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    this.task = this.navigation.getTaskForSlideComponent(this.component);
    this.summary = this.translocoService.translate(this.navigation.getL10nNodeForComponent(this.component.nsComponentId).getL10NPath('summary'));
  }

  goIfAllowed(route, component, event) {
    // close menu if user clicks current slide link
    if (this.navigation.slideComponentCurrentlyViewed(component)) {
      this.toggleSelf.emit(event);
      return;
    }

    // if in debug mode, won't throw error
    if (!this.navigation.slideComponentIsVisited(component) && !this.appConfig.debug) {
      this.toastr.warning(`Can't visit that slide yet.`);
      return;
    }

    this.router.navigate([route], {relativeTo: this.activatedRoute});
  }
}
