import {Component, OnInit} from '@angular/core';
import {ModulePracticeSection} from "northstar-foundation";
import {PracticeBaseOverviewSlideComponent} from "../practice-base-overview-slide.component";
import {findIndex, orderBy} from "lodash";

@Component({
  templateUrl: 'section-overview.component.html',
  styleUrls: [
    'section-overview.component.scss',
    '../../layout/slide-wrapper/_shared-button-types.scss'
  ]
})
export class PracticeSectionOverviewComponent extends PracticeBaseOverviewSlideComponent {
  static nsComponentId: string = 'SectionOverviewComponent';
  static title: string = 'Overview';

  l10nBasePath: string = 'shared.slides.sectionOverview.';

  section: ModulePracticeSection;
  orderNum: number;

  // false because we're not using this as a nav slide
  static isOverviewSlideComponent: boolean = false;

  showNextButton: boolean = true;
  showProgressBar: boolean = false;
  hasAudio = false;

  getTitle() {
    return PracticeSectionOverviewComponent.title;
  }

  getComponentId() {
    return PracticeSectionOverviewComponent.nsComponentId;
  }

  getIsNavigableSlide() {
    return PracticeSectionOverviewComponent.isOverviewSlideComponent;
  }

  ngOnInit() {
    this.configureSlideWrapperParameters();

    // don't call nav service's onVisitSlideComponentInstance since that tries
    // to keep track of the 'visited' status of this slide, but this slide doesn't
    // even show up in the nav menu so it's irrelevant and would complicate logic elsewhere

    this.section = <ModulePracticeSection>this.navigation.getCurrentSection();
    this.orderNum = findIndex(orderBy(this.section.module.sections, ['moduleOrder']), {id: this.section.id}) + 1;
  }
}
