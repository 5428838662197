import {Component, OnInit, Input, HostListener} from '@angular/core';

/**
 * Ref. https://developer.mozilla.org/en-US/docs/Web/API/Fullscreen_API
 */

@Component({
  selector: 'full-screen-button',
  templateUrl: './full-screen-button.component.html',
  styleUrls: ['./full-screen-button.component.scss']
})
export class FullScreenButtonComponent implements OnInit {
  fullScreenAPIAvailable: boolean = true;
  fullScreenEnabled: boolean = false;

  ngOnInit() {
    this.fullScreenAPIAvailable = this.fullscreenAPIAvailable();
    this.fullScreenEnabled = this.inFullScreenMode();
  }

  fullscreenAPIAvailable() {
    // 'any' in order to avoid TypeScript errors due to cross-browser property testing
    const element: any = document.documentElement;

    const available: boolean = element.requestFullscreen ||
        element.webkitRequestFullscreen ||
        element.mozRequestFullScreen ||
        element.msRequestFullscreen;

    return available;
  }

  requestFullScreen(event) {
    event.preventDefault();

    if (!this.inFullScreenMode()) {
        this.requestFullScreenVendored();
    } else {
      if (this.exitFullScreenAvailable()) {
        this.exitFullScreenVendored();
      }
    }
  }

  inFullScreenMode() {
    // 'any' in order to avoid TypeScript errors
    const documentVar: any = document;

    const truthy = documentVar.fullscreenElement
      || documentVar.webkitFullscreenElement
      || documentVar.mozFullScreenElement
      || documentVar.msFullScreenElement;

    return !!truthy;
  }

  private requestFullScreenVendored() {
    // 'any' in order to avoid TypeScript errors due to cross-browser property testing
    const element: any = document.documentElement;

    if (element.requestFullscreen) {
      element.requestFullscreen();
      return;
    }

    if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
      return;
    }

    if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
      return;
    }

    if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
      return;
    }
  }

  public exitFullScreenAvailable() {
    // 'any' in order to avoid TypeScript errors
    const documentVar: any = document;

    return documentVar.exitFullscreen
      || documentVar.webkitExitFullscreen
      || documentVar.mozExitFullScreen
      || documentVar.msExitFullScreen;
  }

  private exitFullScreenVendored() {
    const documentVar: any = document;

    if (documentVar.exitFullscreen) {
      documentVar.exitFullscreen();
      return;
    }

    if (documentVar.webkitExitFullscreen) {
      documentVar.webkitExitFullscreen();
      return;
    }

    if (documentVar.mozExitFullScreen) {
      documentVar.mozExitFullScreen();
      return;
    }

    if (documentVar.msExitFullScreen) {
      documentVar.msExitFullScreen();
      return;
    }
  }

  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  @HostListener('document:msfullscreenchange', ['$event'])
  onFullScreenChange(event) {
    this.fullScreenEnabled = this.inFullScreenMode();
  }
}
