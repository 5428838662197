import {Injectable} from "@angular/core";
import {VgAPI} from "videogular2/core";
import {BehaviorSubject, Observable} from "rxjs";
import {findIndex} from "lodash";

export interface ISpeedOption {
  id: string,
  rate: number
}

export const speedOptions: ISpeedOption[] = [
  {
    id: '.75x',
    rate: .75
  },
  {
    id: 'default',
    rate: 1
  },
  {
    id: '1.25x',
    rate: 1.25
  },
  {
    id: '1.5x',
    rate: 1.5
  },
  {
    id: '2x',
    rate: 2
  }
];

@Injectable()
export class AudioSettingsService {
  autoplayOn: BehaviorSubject<boolean> = new BehaviorSubject(true);
  $autoplayOn: Observable<any> = this.autoplayOn.asObservable();

  audioOn: BehaviorSubject<boolean> = new BehaviorSubject(true);
  $audioOn: Observable<any> = this.audioOn.asObservable();

  playbackSpeed: BehaviorSubject<ISpeedOption> = new BehaviorSubject(speedOptions[1]);
  $playbackSpeed: Observable<ISpeedOption> = this.playbackSpeed.asObservable();

  api: VgAPI;

  setAPI(api: VgAPI) {
    this.api = api;
  }

  toggleAudio(event) {
    event.preventDefault();

    this.audioOn.next(!this.audioOn.value);

    if (this.audioOn.value) {
      this.unmuteMasterAudio();
    } else {
      this.muteMasterAudio();
    }
  }

  toggleAutoplay(event) {
    event.preventDefault();

    this.autoplayOn.next(!this.autoplayOn.value);
  }

  getMasterAudio() {
    if (!this.api) {
      return false;
    }

    return this.api.getMasterMedia();
  }

  muteMasterAudio() {
    const target = <any>this.getMasterAudio();

    target && (target.volume = 0); // target won't exist if we're on a YouTube slide
  }

  unmuteMasterAudio() {
    const target = <any>this.getMasterAudio();

    target && (target.volume = 1); // target won't exist if we're on a YouTube slide
  }

  advanceSpeed() {
    const currentSpeedIndex = findIndex(speedOptions, {id: this.playbackSpeed.value.id});

    if (currentSpeedIndex < speedOptions.length - 1) {
      this.playbackSpeed.next(speedOptions[currentSpeedIndex + 1]);
    } else {
      this.playbackSpeed.next(speedOptions[0]);
    }
  }
}
