
import {filter} from 'rxjs/operators';
import {Router, NavigationEnd} from "@angular/router";
import {Injectable, Inject} from "@angular/core";
import {LoggerService} from "./logger.service";
import {APP_CONFIG, IAppConfig} from "../../app.config";
import {CustomDataStore} from "../data-store/custom-data-store.service";

export interface IPageView {
  url: string,
  start: Date
}

@Injectable()
export class RouterHistoryService {
  protected pageViewHistory: IPageView[] = [];

  constructor(
    protected router: Router,
    protected loggerService: LoggerService,
    @Inject(APP_CONFIG) protected appConfig: IAppConfig,
    protected dataStore: CustomDataStore
  ) {
    router.events.pipe(
          filter(event => event instanceof NavigationEnd))
          .subscribe((end: NavigationEnd) => {
            this.addPageViewToHistory(end);
          });

  }

  protected addPageViewToHistory(end: NavigationEnd) {
    const pageView: IPageView = {
      url: end.url,
      start: new Date()
    };

    this.pageViewHistory.unshift(pageView);

    if (this.appConfig.debug) {
      // this.loggerService.log(['Adding page view to RouterHistory', pageView]);
    }
  }

  public getPageViewsSince(time: Date) {
    if (!time) {
      return this.pageViewHistory;
    }

    return this.pageViewHistory.filter((pageView: IPageView) => {
      return pageView.start > time;
    });
  }
}
