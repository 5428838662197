import {Injectable, Inject} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {ApiService, apiEndpoints} from "../services/api.service";
import {SessionService} from "../services/session.service";
import {CustomDataStore} from "../data-store/custom-data-store.service";
import {LoggerService} from "../services/logger.service";
import {
  IApiPaginatedDataResponse,
  ModulePracticeUserCompletion,
  ModulePracticeSection,
  ModulePracticeLesson,
  AssessmentModuleStandardRelation,
  AssessmentModuleStandard,
  ModulePracticeSectionReview,
  ModulePracticeSectionReviewUserCompletion,
  ModulePracticeSectionReviewQuestion,
  ModulePracticeSectionReviewQuestionResponse
} from "northstar-foundation";
import {APP_CONFIG, IAppConfig} from "../../app.config";
import {of} from "rxjs";
import {catchError, switchMap} from "rxjs/operators";

export interface IApiModulePracticeLessonsResponse {
  sections: ModulePracticeSection[],
  lessonCompletions: ModulePracticeUserCompletion[],
  lessons: ModulePracticeLesson[],
  standardRelations: AssessmentModuleStandardRelation[],
  standards: AssessmentModuleStandard[],
  sectionReviews: ModulePracticeSectionReview[],
  sectionReviewCompletions: ModulePracticeSectionReviewUserCompletion[],
  reviewQuestions: ModulePracticeSectionReviewQuestion[]
  reviewQuestionResponses: ModulePracticeSectionReviewQuestionResponse[],
}

@Injectable()
export class ModulePracticeLessonsResolve implements Resolve<any> {

  moduleId: number;

  constructor(
    protected loggerService: LoggerService,
    protected dataStore: CustomDataStore,
    public sessionService: SessionService,
    protected apiService: ApiService,
    @Inject(APP_CONFIG) public appConfig: IAppConfig
  ) {
  }

  /**
   * Define the module ID whose data we should fetch for history on practice interactions, etc.
   * Unable to just pass the ID into the `resolve` method due to Typescript checks.
   *
   * @param id
   */
  setModuleId(id: number) {
    this.moduleId = id;
  }

  /**
   * Method able to be overridden by children (cf LMS resolve that depends on URL param rather than setModuleId usage).
   *
   * @param route
   * @returns {number}
   */
  getModuleId(route: ActivatedRouteSnapshot) {
    return this.moduleId;
  }

  resolve(route: ActivatedRouteSnapshot) {
    if (this.appConfig.apiMocks) {
      return of({
        sections: this.dataStore.add('module_practice_section', this.appConfig.apiMocks.sections),
        standardRelations: this.dataStore.add('module_standard_relation', this.appConfig.apiMocks.standardRelations),
        lessons: this.dataStore.add('module_practice_lesson', this.appConfig.apiMocks.lessons),
        standards: this.dataStore.add('module_standard', this.appConfig.apiMocks.standards),
        sectionReviews: this.dataStore.add('module_practice_section_review', this.appConfig.apiMocks.sectionReviews),
        reviewQuestions: this.dataStore.add('module_practice_section_review_question', this.appConfig.apiMocks.reviewQuestions),
      })
    }

    return this.apiService.httpGet(`${apiEndpoints.practiceLessons}?module_id=${this.getModuleId(route)}`)
                          .pipe(
                            switchMap(this.onResponse.bind(this)),
                            catchError(this.onError.bind(this)),
                          );
  }

  onResponse(response: IApiModulePracticeLessonsResponse) {
    const sections = this.dataStore.add('module_practice_section', response.sections);
    const standardRelations = this.dataStore.add('module_standard_relation', response.standardRelations);
    const lessons = this.dataStore.add('module_practice_lesson', response.lessons);
    const lessonCompletions = this.dataStore.add('module_practice_user_completion', response.lessonCompletions);
    const standards = this.dataStore.add('module_standard', response.standards);
    const sectionReviews = this.dataStore.add('module_practice_section_review', response.sectionReviews);
    const sectionReviewCompletions = this.dataStore.add('module_practice_section_review_user_completion', response.sectionReviewCompletions);
    const reviewQuestions = this.dataStore.add('module_practice_section_review_question', response.reviewQuestions);
    const reviewQuestionResponses = this.dataStore.add('module_practice_section_review_question_response', response.reviewQuestionResponses);

    if (this.appConfig.debug) {
      this.loggerService.log([
        sections,
        standardRelations,
        lessons,
        lessonCompletions,
        standards,
        sectionReviews,
        sectionReviewCompletions,
        reviewQuestions,
        reviewQuestionResponses
      ]);
    }

    return of({
      sections: sections,
      standardRelations: standardRelations,
      lessons: lessons,
      lessonCompletions: lessonCompletions,
      standards: standards,
      sectionReviews: sectionReviews,
      sectionReviewCompletions: sectionReviewCompletions,
      reviewQuestions: reviewQuestions,
      reviewQuestionResponses: reviewQuestionResponses
    });
  }

  onError(response) {
    this.loggerService.warn(response);
    return of(false);
  }
}
