import {Injectable} from "@angular/core";

/**
 * General-purpose store for basic key-value pairs. Originally created to make it easy to
 * manage the value for an ngClass value that needed to be set and read across different components.
 */
@Injectable()
export class KeyValueStoreService {
  store: any = {};

  constructor() {
  }

  toggle(key) {
    this.store[key] = !this.store[key];
  }

  set(key, value) {
    this.store[key] = value;
  }

  get(key) {
    return this.store[key];
  }

  clear(key) {
    delete this.store[key];
  }
}
