import {trigger, style, animate, transition} from '@angular/animations';

export function FadeInOut(duration: number = 100) {
  return trigger(
      'fadeInOut', [
        transition(':enter', [
          style({opacity: 0}),
          animate(duration + 'ms', style({opacity: 1}))
        ]),
        transition(':leave', [
          style({opacity: 1}),
          animate(duration + 'ms', style({opacity: 0}))
        ])
      ]
    )
}

export function FadeOut(duration: number = 100) {
  return trigger(
    'fadeOut', [
      transition(':leave', [
        style({opacity: 1}),
        animate(duration + 'ms', style({opacity: 0}))
      ])
    ]
  )
}

export function BackgroundFlashIn(
  backgroundColorStart: string = 'yellow',
  backgroundColorEnd: string = 'transparent',
  duration: number = 1000
) {
  return trigger(
    'backgroundFlash', [
      transition(':enter', [
        style({'background-color': backgroundColorStart}),
        animate(duration + 'ms', style({'background-color': backgroundColorEnd}))
      ])
    ]
  )
}

export function TranslateFade(
  startYPercent: number = -3,
  startXPercent: number = 0,
  duration: number = 100,
  nameSpace: string = ''
) {
  return trigger(
    'translateFade' + nameSpace, [
      transition(':enter', [
        style({transform: `translate(${startXPercent}%, ${startYPercent}%)`, opacity: 0}),
        animate(duration + 'ms', style({transform: 'translate(0, 0)', opacity: 1}))
      ]),
      transition(':leave', [
        style({transform: 'translate(0, 0)', opacity: 1}),
        animate(duration + 'ms', style({transform: `translate(${startXPercent}%, ${startYPercent}%)`, opacity: 0}))
      ])
    ]
  )
}

export function SlideFromLeft(
  startLeft: number = -200,
  finishLeft: number = 0,
  duration: number = 400
) {
  return trigger(
    'slideFromLeft', [
      transition(':enter', [
        style({left: startLeft + 'px'}),
        animate(duration + 'ms', style({left: finishLeft + 'px'}))
      ]),
      transition(':leave', [
        style({left: finishLeft + 'px'}),
        animate(duration + 'ms', style({left: startLeft + 'px'}))
      ])
    ]
  )
}

export function LandInLaunchOut(
  duration: number = 400,
  scale: number = 1.2
) {
  return trigger(
    'landInLaunchOut', [
      transition(':enter', [
        style({transform: 'scale(' + scale + ')'}),
        animate(duration + 'ms', style({transform: 'scale(1)'}))
      ]),
      transition(':leave', [
        style({transform: 'scale(1)'}),
        animate(duration + 'ms', style({transform: 'scale(' + scale + ')'}))
      ])
    ]
  )
}

export function LaunchOutFallBack(
  duration: number = 400,
  scale: number = .5
) {
  return trigger(
    'launchOutFallBack', [
      transition(':enter', [
        style({transform: 'scale(' + scale + ')'}),
        animate(duration + 'ms', style({transform: 'scale(1)'}))
      ]),
      transition(':leave', [
        style({transform: 'scale(1)'}),
        animate(duration + 'ms', style({transform: 'scale(' + scale + ')'}))
      ])
    ]
  )
}
