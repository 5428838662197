import {Injectable, Inject} from "@angular/core";
import {APP_CONFIG, IAppConfig} from "../../app.config";
import {LoggerService} from "./logger.service";
import {HttpClient} from "@angular/common/http";
import {SerializationService} from "./serialization.service";
import {SlimLoadingBarService} from "ng2-slim-loading-bar/index";
import {IApiRequestOptions, retryWithBackoff} from "northstar-foundation";
import {cloneDeep} from "lodash";
import {shareReplay, catchError, map} from 'rxjs/operators';
import {throwError as _throw, Subject, Observable} from 'rxjs';

export const apiEndpoints = {
  validateAssessmentParameters: 'validate-assessment-start/',
  getAssessmentToken: 'assessment-tokens/', // if proctored mode turned off, need different token
  recordAssessment: 'assessments/',
  recordPracticeLesson: 'practice-completions/',
  recordSectionReviewCompletion: 'section-review-completions/',
  timeOnTask: 'time-on-task/',
  practiceLessons: 'practice-lessons/',
  getAssessmentResults: 'assessments/<id>/', // also requires ?verification_code=<code> as query param
  findOrCreateBadge: 'badges/',
  lmsBootstrap: 'lms-bootstrap/',
  modules: 'modules/',
  session: 'session/',
  me: 'me/',
  countries: 'countries/',
  countryRegions: 'countries/<countryId>/regions/',
  checkStatusProctorSessionForUser: 'proctor-sessions-for-user/<sessionId>/?token=<sessionToken>',
  keepProctorSessionForUserAlive: 'proctor-sessions-for-user/<sessionId>/keep-alive/',
};

@Injectable()
export class ApiService {

  static endpoints = apiEndpoints;


  /**
   * Allow way for others than just the immediate caller to be aware of API successes/failures. Primarily, for now,
   * useful for sake of SessionService, which needs to be kept in the loop on when/how server interactions go, to
   * store status locally.
   *
   * @type {Subject<any>}
   */
  private apiCallSuccess = new Subject<any>();
  private apiCallError = new Subject<any>();
  apiCallSuccess$: Observable<any> = this.apiCallSuccess.asObservable();
  apiCallError$: Observable<any> = this.apiCallError.asObservable();

  constructor(
    protected slimLoadingBarService: SlimLoadingBarService,
    @Inject(APP_CONFIG) protected appConfig: IAppConfig,
    protected loggerService: LoggerService,
    // ref https://angular.io/guide/http
    protected http: HttpClient,
    protected serializationService: SerializationService
  ) {}

  httpPost(endpoint, data, options:IApiRequestOptions={}) {
    this.slimLoadingBarService.start();

    // clone in case modified later in this service
    // had issue w/using same options sent to ApiService 2 times due to service deleting `map` property further down
    options = cloneDeep(options);

    return this.http.post(
      this.getFullEndpointUrl(endpoint, options.map ? options.map : null),
      this.serializationService.serialize(data),
      this.mergeOptions(options)
    ).pipe(
      map(this.onApiResponseSuccess.bind(this)),
      catchError(this.onApiResponseError.bind(this)),
    );
  }

  /**
   * For mission-critical POSTs, allow retry of POST if errors occur. See `retryWithBackoff` for config options.
   *
   * @param endpoint
   * @param data
   * @param options
   * @returns {Observable<C>}
   */
  httpPostWithRetry(endpoint, data, options:IApiRequestOptions={}) {
    this.slimLoadingBarService.start();

    // clone in case modified later in this service
    // had issue w/using same options sent to ApiService 2 times due to service deleting `map` property further down
    options = cloneDeep(options);

    const maxRetries = 2;

    return this.http.post(
      this.getFullEndpointUrl(endpoint, options.map ? options.map : null),
      this.serializationService.serialize(data),
      this.mergeOptions(options)
    )
      .pipe(
        retryWithBackoff(1000, maxRetries),
        catchError(this.onApiResponseErrorStream.bind(this)),
        map(this.onApiResponseSuccessStream.bind(this)),
        shareReplay(),
      )
  }

  httpGet(endpoint, options:IApiRequestOptions={}) {
    this.slimLoadingBarService.start();

    // clone in case modified later in this service
    // had issue w/using same options sent to ApiService 2 times due to service deleting `map` property further down
    options = cloneDeep(options);

    return this.http.get(
      this.getFullEndpointUrl(endpoint, options.map ? options.map : null),
      this.mergeOptions(options)
    ).pipe(
      map(this.onApiResponseSuccess.bind(this)),
      catchError(this.onApiResponseError.bind(this)),
    );
  }

  getFullEndpointUrl(endpoint, interpolationMap: any|null) {
    if (interpolationMap && Object.keys(interpolationMap).length > 0) {
      endpoint = this.getInterpolatedEndpoint(endpoint, interpolationMap);
    }

    return `${this.appConfig.apiBase}${endpoint}`;
  }

  /**
   * Interpolate values within an endpoint.
   *
   * e.g. /countries/<countryId> for endpoint value, and {countryId: 2983} passed as interpolationMap
   *
   * @param endpoint string
   * @param interpolationMap   object
   * @returns {any}
     */
  getInterpolatedEndpoint(endpoint, interpolationMap) {
    for (let key in interpolationMap) {
      if (interpolationMap.hasOwnProperty(key)) {
          if (!endpoint.match('<' + key +'>'))
          {
            // skip kwarg if not present in endpoint
            continue;
          }
          endpoint = endpoint.replace('<' + key +'>', interpolationMap[key]);
      }
    }

    const re = new RegExp('<[a-zA-Z0-9-_]{1,}>', 'g');
    const missingArgs = endpoint.match(re);

    if (missingArgs) {
        throw('Missing arguments (' + missingArgs.join(", ") + ') for endpoint ' + endpoint);
    }

    return endpoint;
  }

  /**
   * Success handler for normal API responses.
   *
   * @param result
   * @returns {any}
   */
  onApiResponseSuccess(result) {
    this.onApiResponseSuccessCommon(result);
    this.apiCallSuccess.next(result);

    return result;
  }

  /**
   * Success handler for API response streams in RxJS. Currently same as non-streams, but that may change; setting
   * up separately since the equivalent error methods differ between stream vs non-stream.
   *
   * @param result
   * @returns {any}
   */
  onApiResponseSuccessStream(result) {
    this.onApiResponseSuccessCommon(result);
    this.apiCallSuccess.next(result);

    return result;
  }

  /**
   * Behaviors to occur upon API success whether caller is via RxJS stream or not.
   *
   * @param result
   */
  protected onApiResponseSuccessCommon(result) {
    this.slimLoadingBarService.complete();
  }

  /**
   * Error handler for non-stream API response errors.
   *
   * @param errorResponse
   */
  onApiResponseError(errorResponse) {
    this.onApiResponseErrorCommon(errorResponse);

    // don't need all the details of the response w/in components
    // below will return the API response, e.g. {'detail': 'Field `user` required'}
    throw errorResponse.error;
  }

  /**
   * Error handler for API response streams in RxJS.
   *
   * @param errorResponse
   * @returns {any}
   */
  onApiResponseErrorStream(errorResponse) {
    this.onApiResponseErrorCommon(errorResponse);

    return _throw(errorResponse.error);
  }

  /**
   * Behaviors to occur upon API error whether caller is via RxJS stream or not.
   *
   * @param errorResponse
   */
  protected onApiResponseErrorCommon(errorResponse) {
    this.slimLoadingBarService.complete();
    this.apiCallError.next(errorResponse);

    this.loggerService.log(['API error response', {
      // note: the keys below are intentionally verbose because otherwise the log to TrackJS was unsuccessful,
      // in that some of the keys were being stripped out (e.g. `error`, `url`)
      errorUrl: errorResponse.url,
      errorApiResponse: errorResponse.error,
      errorMessage: errorResponse.message,
      errorName: errorResponse.name
    }]);
  }

  mergeOptions(userOptions: IApiRequestOptions) {
    const optionDefaults = {
      // allow session cookie data to be passed to authenticate user
      withCredentials: true
    };

    // map was for interpolating endpoint data, not needed any longer
    if (userOptions.map) {
      delete userOptions.map;
    }

    return Object.assign({}, optionDefaults, userOptions)
  }
}
