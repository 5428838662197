import {Component, OnInit, OnDestroy, Inject, Renderer2, AfterViewInit, Injector, LOCALE_ID} from "@angular/core";
import {ToastrService} from 'ngx-toastr';
import {ResponseCollectionService} from "../../core/engagement/response-collection.service";
import {IModuleTaskSlideConfig, taskRecipes, IAppConfig, APP_CONFIG, IPhotoCreditConfig} from "../../app.config";
import {SlideNavigationService} from "../../core/engagement/slide-navigation.service";
import {LoggerService} from "../../core/services/logger.service";
import {upperFirst} from "lodash";
import {InterfaceInteractionStagerService} from "../../core/engagement/interface-interaction-stager.service";
import {ActivatedRoute} from "@angular/router";
import {LoadingOverlayService} from "../../core/engagement/loading-overlay.service";
import {ResponseModel} from "../../core/engagement/response.model";
import {KeyValueStoreService} from "../../core/services/key-value-store.service";
import {AssetService} from "../../core/services/asset.service";
import {KeyboardEventInterpreterService} from "../../core/engagement/keyboard-event-interpreter.service";
import {FormBuilder} from "@angular/forms";
import {PopoverService} from "../../core/popover/popover.service";
import {ApiService} from "../../core/services/api.service";
import {SessionService} from "../../core/services/session.service";
import {TranslocoService} from "@ngneat/transloco";
import {L10nSlideContentPipe} from "../i18n/l10n-slide-content.pipe";
import {getKebabCoreNameForComponent} from "./slide-helper";
import { L10nImageNamePipe } from "../i18n/l10n-image-name.pipe";
import { L10nNode } from "app/core/i18n/l10n-node";
import {MediaQueryService} from "../../core/environment/media-query.service";
import { L10nDateFormatPipe } from "../i18n/l10n-date-format.pipe";

@Component({
  templateUrl: './base-task-slide.component.html'
})
export class BaseTaskSlideComponent implements OnInit, AfterViewInit, OnDestroy {
  static nsComponentId: string = '';
  static isTaskSlideComponent: boolean = true;
  static allowAnswerAgain: boolean = true;

  config: IModuleTaskSlideConfig;
  photoCreditConfig: IPhotoCreditConfig;
  recipeType: string|null;
  showNextButton: boolean = false;
  hasCaptions: boolean = true;
  showIDontKnowButton: boolean = true;
  hasAudio: boolean = true;
  hasBackgroundImage: boolean = false;
  delayBetweenSlidesMs: number = 500;

  /**
   * Values that may be interpolated into title text or captions.
   *
   * cf. https://ngneat.github.io/transloco/docs/translation-api
   * cf. TaskTitleComponent
   *
   * @type {{}}
   */
  l10nParams: any = {};

  subscriptions: any = {};
  templateSettings: any = {}; // catch-all placeholder for doing simple things in template w/vars, e.g. (click)="templateSettings[showPanel] = !templateSettings[showPanel]"

  constructor(
    public toastr: ToastrService,
    protected loggerService: LoggerService,
    protected responseCollectionService: ResponseCollectionService,
    protected navigationService: SlideNavigationService,
    public stagerService: InterfaceInteractionStagerService,
    protected route: ActivatedRoute,
    protected loadingOverlayService: LoadingOverlayService,
    protected keyStoreValueService: KeyValueStoreService,
    public assetService: AssetService,
    protected keyboardEventInterpreterService: KeyboardEventInterpreterService,
    protected renderer2: Renderer2,
    @Inject(APP_CONFIG) protected appConfig: IAppConfig,
    protected formBuilder: FormBuilder,
    protected popoverService: PopoverService,
    protected injector: Injector,
    protected apiService: ApiService,
    protected sessionService: SessionService,
    protected l10nSlideContentPipe: L10nSlideContentPipe,
    protected l10nImageNamePipe: L10nImageNamePipe,
    protected translocoService: TranslocoService,
    protected l10nDateFormatPipe: L10nDateFormatPipe,
    public mediaQueryService: MediaQueryService,
  ) {}

  ngOnInit() {
    if (this.recipeType) {
      this.setupRecipe(this.recipeType);
    }

    this.configureSlideWrapperParameters();

    this.navigationService.onVisitSlideComponentInstance(this);
    this.subscriptions['stagerEvents'] = this.stagerService.recordedEvent$.subscribe(this.onRecordedEvent.bind(this));
    this.subscriptions['_response'] = this.responseCollectionService.response$.subscribe(this.onResponseComplete.bind(this));
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    for (let key in this.subscriptions) {
      this.subscriptions[key].unsubscribe();
    }
  }

  nextIsDisabled() {
    return false;
  }

  handleClickNext() {
    this.navigationService.goNext(this.route);
  }

  /**
   * Handle whether user said "yes" to retry question, or "no".
   */
  userRetryResponseHandler(userWantsRetry: boolean) {
    if (userWantsRetry) {
      this.config.askUserToConfirmRetry = false;
      return;
    }

    // user skipping retry
    // below, could have used onResponseCompleteNavigate() instead potentially, but that's overridden
    // on at least one slide in order to help skip the following slide if answered in a certain way,
    // and so would likely cause problems there
    this.navigationService.goNext(this.route);
  }

  /**
   * Convenience method for setting up logic to handle some basic task types,
   * e.g. click on a particular object.
   *
   * @param recipeType
   */
  protected setupRecipe(recipeType) {
    this.validateRecipeTypeOrErrorOut(recipeType);

    const specificSetupMethod = 'setupRecipe' + upperFirst(recipeType);

    // reference different recipe classes for the specific setup methods
    if (typeof this[specificSetupMethod] === 'function') {
      this[specificSetupMethod]();
    }
  }

  private validateRecipeTypeOrErrorOut(recipeType) {
    let usingValidRecipeType = false;

    for (let key in taskRecipes) {
      if (taskRecipes.hasOwnProperty(key) && taskRecipes[key] === recipeType) {
        usingValidRecipeType = true;
      }
    }

    if (!usingValidRecipeType) {
      this.loggerService.error(`Invalid recipe: ${recipeType}`);
    }
  }

  /**
   * Respond to generic pub-sub events. Not currently implemented.
   * May override this method but preferably call super.onRecordedEvent(event) as well if overriding
   * @param event
   */
  protected onRecordedEvent(event: any) {
  }

  protected onResponseComplete(response: ResponseModel) {
    if (response.getSuccess()) {
      this.onResponseCompleteSuccess(response);
    }

    this.toastr.clear();
    this.loadingOverlayService.show();
    setTimeout(() => {
      this.loadingOverlayService.hide();
      this.onResponseCompleteNavigate(response);
    }, this.delayBetweenSlidesMs);
  }

  protected onResponseCompleteSuccess(response: ResponseModel) {
    // pass - provide custom logic on per-component basis if useful
  }

  protected onResponseCompleteNavigate(response: ResponseModel) {
      this.navigationService.goNext(this.route);
  }

  protected configureSlideWrapperParameters() {
    const task = this.navigationService.getTaskForSlideComponentInstance(this);

    this.config = {
      task: task,
      audioPath: this.getAudioPath(),
      l10nNode: this.navigationService.getL10nNodeForComponent(this.getComponentIdForL10nNode()),
      contentL10nNode: this.navigationService.getL10nNodeForComponent(this.getComponentIdForContentL10nNode()),
      l10nParams: this.l10nParams,
      hasCaptions: this.hasCaptions,
      showNextButton: this.showNextButton,
      showIDontKnowButton: this.showIDontKnowButton,
      hasAudio: this.hasAudio,
      photoCreditConfig: this.photoCreditConfig,
      hasBackgroundImage: this.hasBackgroundImage,
      askUserToConfirmRetry: this.navigationService.slideComponentIsCompleted(this.constructor),

      // pass how to handle confirm/deny of retrying a slide, rather than every slide component needing
      // to define via EventEmitter handlers on <task-slide-wrapper>, and similar below
      userRetryResponseHandler: this.userRetryResponseHandler.bind(this),
      nextIsDisabled: this.nextIsDisabled.bind(this),
      customHandleClickNext: this.handleClickNext.bind(this),
    };

    this.mergeL10nPhotoCredits();
  }

  /**
   * Look for photo credits in the l10n file's entry for this slide. Note that within slide component you can specify
   * `photoCreditConfig.expanded` if desired.
   *
   */
  mergeL10nPhotoCredits() {
    // pull photo credits from l10n if available
    const l10nPhotoCredit = this.l10nSlideContentPipe.transform('creditContent', this.config);

    if (l10nPhotoCredit) {
      this.config.photoCreditConfig = {
        creditContent: l10nPhotoCredit,
        expanded: (this.photoCreditConfig && typeof this.photoCreditConfig.expanded === 'boolean') ? this.photoCreditConfig.expanded : false
      };
    }
  }

  /**
   * Return the component ID that l10n data is saved under for purposes of title and slide summary (for nav menu).
   */
  getComponentIdForL10nNode(): string {
    return (<any>this.constructor).nsComponentId;
  }

  /**
   * Return the component ID that l10n data is saved under for purposes of slide content. Often the same as
   * getComponentIdForL10nNode() but need to override if template shared among multiple slides to keep l10n data DRY.
   */
  getComponentIdForContentL10nNode(): string {
    return this.getComponentIdForL10nNode();
  }

  protected getAudioPath() {
    const module = this.navigationService.module;

    return this.hasAudio
      ? `${module.topic.slug}${module.softwareVersion.isBase ? '' : '-' + module.softwareVersion.slug}/${this.translocoService.getActiveLang()}/${this.getAudioPathSubfolder()}${getKebabCoreNameForComponent((<any>this.constructor).nsComponentId)}${this.getAudioPathModifier()}`
      : null;
  }

  /**
   * For children classes, may want to organize audio files into subfolders, e.g. practice/
   * @returns {string}
   */
  protected getAudioPathSubfolder() {
    return '';
  }

  /**
   * In certain cases, may want a dynamic component to the audio path, e.g. changes by the year.
   *
   * @returns {string}
   */
  protected getAudioPathModifier() {
    return '';
  }

  /**
   * Convenience method to simplify marking slide as success/failure since is frequent need.
   *
   * @param success
   * @param variant
   * @param meta
   */
  protected setResponseAs(success: boolean, variant: string = null, meta: any = null) {
    this.responseCollectionService.addResponse(
      this.constructor,
      this.navigationService.getTaskForSlideComponentInstance(this),
      success,
      variant,
      meta
    );
  }

  public setResponseAsSuccess(variant: string = null, meta: any = null) {
    this.setResponseAs(true, variant, meta);
  }

  public setResponseAsFailure(variant: string = null, meta: any = null) {
    this.setResponseAs(false, variant, meta);
  }

  /**
   * Convenience method to get the content path.
   * Useful for translation in the component.
   * For flexibility the returned string ends in 'content' without a trailing dot or slash.
   * 
   * @param basePath The module path
   * @returns The path to the component slide content. 
   */
  protected getL10nSlideContentPath(basePath: string) : string {
    return `${basePath}slides.${new L10nNode(getKebabCoreNameForComponent(this.getComponentIdForContentL10nNode())).getL10NPath("content")}`;
  }
}
