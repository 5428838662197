import {Directive, HostListener, Inject, HostBinding, OnInit, OnDestroy, ElementRef, Input} from '@angular/core';
import {IAppConfig, APP_CONFIG} from "../../../app.config";
import {debounce, keys, each, isEmpty, capitalize} from "lodash";
import {LoggerService} from "../../../core/services/logger.service";
import {MediaQueryService} from "../../../core/environment/media-query.service";

export const minWindowHeightForNormalDisplay = 700;
export const normalPlayerContentHeight = 500;

export const word2016ApplicationDragAndDropBreakpointConfig = {
  0: -260,
  700: 422
};

export const gDocsApplicationMainContentBreakpointConfig = {
  0: -314,
  700: 377
};

export const gDocsApplicationVersioningContentBreakpointConfig = {
  0: -256,
  700: 424
};

/**
 * Adjustments that are shared among a number of templates, so collected here to be DRY.
 * @type {{playerContent: {0: number}}}
 */
let commonResponsiveElementHeightAdjustments = {
  playerContent: {
    0: - 110 - 60 // - player top bar - player bottom bar - padding,
  }
};

commonResponsiveElementHeightAdjustments.playerContent[minWindowHeightForNormalDisplay] = normalPlayerContentHeight;

/**
 * Common pixel diffs to keep track of as options for horizontalBreakpointConfig.diff
 */
export const commonHorizontalResponsiveDiffs = {
  headRow: 50 + 10, // top bar and bottom bar diffs
  headRowWithProgressBar: 50 + 10 - 12 // top bar, bottom bar, progress bar diffs
};

/**
 * In order to try to optimize interactions on screen and minimize scrolling when reasonably possible, some components
 * display differently at different height breakpoints (e.g. Windows will appear shorter so that user can still
 * hopefully see Start menu on shorter screen). Originally developed for NS's users on desktop computers.
 *
 * As need for mobile responsiveness increased, the responsive height at particular times also needs to take into
 * consideration elements that are intentionally hidden from the screen on mobile.
 */
@Directive({
  selector: '[responsiveHeight]'
})
export class ResponsiveHeightDirective implements OnInit, OnDestroy {

  @HostBinding('style.height')
  elementHeight: string = 'auto';

  windowHeightResizedHandler: (windowHeight: number) => void; // handler function in order to take advantage of debounce

  /**
   * Breakpoints as min-window-height key => HTML element's desired height. While many breakpoint-height issues can be solved
   * in CSS, some that are out of component scope cannot, e.g. inner Chrome height.
   *
   * e.g. {
   *  0: 300,
   *  700: 500
   *  }
   *
   *  If one of object's values is negative, will be subtracted from the window height.
   *
   *  e.g. {
   *  0: -100, // when window height is from 0 to 699px, the element this directive is applied to will be (window height - 100)px in height
   *  700: 500
   *  }
   */
  @Input()
  breakpointConfig: any;

  /**
   * Object key that is valid from commonResponsiveElementHeightAdjustments, e.g. `playerContent`
   */
  @Input()
  breakpointKey: string;

  /**
   * In some situations, the horizontal breakpoint also matters for the responsive-height calculation,
   * e.g. practice instruction slides hide the top nav bar on mobile
   *
   * @todo support `between` query if needed
   */
  @Input()
  horizontalBreakpointConfig: {
    query: 'up'|'down'
    breakpoint: 'xs'|'sm'|'md'|'lg'|'xl',
    diff: string|number
  };

  constructor(
    protected el: ElementRef,
    @Inject(APP_CONFIG) public appConfig: IAppConfig,
    protected loggerService: LoggerService,
    protected mediaQueryService: MediaQueryService,
  ) {
    this.windowHeightResizedHandler = debounce(this.setElementHeight.bind(this), 300, {
      maxWait: 1000
    });
  }

  ngOnInit() {
    // confirm user either supplied a custom breakpoint config or that a common shared one is being pointed at; see commonResponsiveElementHeightAdjustments
    if (!this.breakpointConfig || isEmpty(this.breakpointConfig)) {
      if (!this.breakpointKey || !commonResponsiveElementHeightAdjustments[this.breakpointKey]) {
        // element has responsiveHeight directive but no configuration - prob a configurable component w/optional config
        return;
      }

      this.breakpointConfig = commonResponsiveElementHeightAdjustments[this.breakpointKey];
    }

    this.setElementHeight(window.innerHeight);
  }

  ngOnDestroy() {
    this.windowHeightResizedHandler = null;
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    this.windowHeightResizedHandler(event.target.innerHeight);
  }

  /**
   * Determine the vertical and horizontal breakpoints that factor into the element's height, and set.
   *
   * @param windowHeight
   */
  setElementHeight(windowHeight: number) {
    let elementHeight;

    if (!this.breakpointConfig || isEmpty(this.breakpointConfig)) {
      return;
    }

    // if on mobile, assume we use the subtraction from window height method no matter the height of the browser
    if (this.mediaQueryService.mediaBreakpointDown.md) {
      elementHeight = windowHeight + this.breakpointConfig[0]; // subtract some known values from window height
      elementHeight += this._getHorizontalBreakpointModifier();
      this.elementHeight = `${elementHeight}px`;
      return;
    }

    // otherwise, sometimes the height of the browser impacts how we want to display different components
    const highestBreakpointForCurrentWindowHeight = this._getHighestBreakpointForCurrentWindowHeight(windowHeight);

    // i.e. breakpointConfig has a negative value, leading to that just being subtracted from window height
    const breakpointHeightParallelsWindowHeight = this.breakpointConfig[highestBreakpointForCurrentWindowHeight] < 0;
    elementHeight = breakpointHeightParallelsWindowHeight ? (windowHeight + this.breakpointConfig[highestBreakpointForCurrentWindowHeight]) : this.breakpointConfig[highestBreakpointForCurrentWindowHeight];

    // consider optional horizontal breakpoints, since in some cases the height of something changes accordingly,
    // e.g. NSOL instruction slides' content is bumped up to replace the hidden replay-audio bar on mobile
    elementHeight += this._getHorizontalBreakpointModifier();

    this.elementHeight = `${elementHeight}px`;
  }

  _getHighestBreakpointForCurrentWindowHeight(windowHeight: number) {
    // get breakpoint window heights in ascending numerical order - https://medium.com/coding-at-dawn/how-to-sort-an-array-numerically-in-javascript-2b22710e3958
    const windowHeightBreakpoints = keys(this.breakpointConfig).sort((a, b) => {
      return +a - +b;
    });

    let highestBreakpointForCurrentWindowHeight;

    each(windowHeightBreakpoints, (breakpointHeight: number) => {
      if (windowHeight >= breakpointHeight) {
        highestBreakpointForCurrentWindowHeight = breakpointHeight;
      }
    });

    if (!highestBreakpointForCurrentWindowHeight) {
      throw Error(`No breakpoint configured for window height ${windowHeight}`);
    }

    return highestBreakpointForCurrentWindowHeight;
  }

  _getHorizontalBreakpointModifier() {
    if (!this.horizontalBreakpointConfig) {
      return 0;
    }

    // take 'down' or 'up' and turn into `mediaBreakpointDown` or `mediaBreakpointUp` to query service
    const mediaQueryServicePropertyToCheck = `mediaBreakpoint${capitalize(this.horizontalBreakpointConfig.query)}`;

    if (this.mediaQueryService[mediaQueryServicePropertyToCheck][this.horizontalBreakpointConfig.breakpoint]) {
      const diffIsCommonKey = typeof this.horizontalBreakpointConfig.diff === 'string';

      return diffIsCommonKey
        // get common diff
        ? commonHorizontalResponsiveDiffs[this.horizontalBreakpointConfig.diff]
        // or use one-off diff
        : this.horizontalBreakpointConfig.diff;
    }

    return 0;
  }
}
