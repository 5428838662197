import {Component, OnInit, Input} from '@angular/core';
import {AudioSettingsService} from "../../../core/settings/audio-settings.service";

@Component({
  selector: 'volume-button',
  templateUrl: './volume-button.component.html',
  styleUrls: ['./volume-button.component.scss']
})
export class VolumeButtonComponent implements OnInit {

  constructor(
    public audioSettings: AudioSettingsService
  ) {}

  ngOnInit() {
  }

}
