import { BrowserModule } from '@angular/platform-browser';
import {NgModule, ErrorHandler} from '@angular/core';

/* App Root */
import { AppComponent } from './app.component';
import {APP_CONFIG, appConfig} from "./app.config";
import {Angulartics2Module} from "angulartics2";

/* Feature Modules */
import {CoreModule} from "./core/core.module";
import {SharedModule} from "./shared/shared.module";
import {Routing} from "./app.routes";
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {SlimLoadingBarModule} from "ng2-slim-loading-bar";
import {DndModule} from "ng2-dnd";
import {TrackJsErrorHandler} from "northstar-foundation/angular";
import { CookieService } from 'ngx-cookie-service';
import { HttpClientModule } from '@angular/common/http';
import { TranslocoRootModule } from './transloco-root.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    Routing,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    ToastrModule.forRoot(),
    SlimLoadingBarModule.forRoot(),
    Angulartics2Module.forRoot(),
    DndModule.forRoot(),
    HttpClientModule,
    TranslocoRootModule,
  ],
  providers: [
    {
      provide: APP_CONFIG,
      useValue: appConfig
    },
    {
      provide: ErrorHandler,
      useClass: TrackJsErrorHandler
    },
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
