import {Injectable, Inject, ElementRef} from "@angular/core";
import * as YouTubePlayer from 'youtube-player';
import {MediaQueryService} from "../environment/media-query.service";
import {defaultsDeep} from "lodash";
import {LoggerService} from "./logger.service";
import {CaptionsSettingsService} from "../settings/captions-settings.service";
import {IAppConfig, APP_CONFIG} from "../../app.config";
import {AudioSettingsService} from "../settings/audio-settings.service";
import {TranslocoService} from "@ngneat/transloco";

@Injectable()
export class YouTubePlayerService {
  activePlayer: YouTubePlayer;

  constructor(
    protected mediaQueryService: MediaQueryService,
    protected loggerService: LoggerService,
    protected captionsSettingsService: CaptionsSettingsService,
    protected audioSettingsService: AudioSettingsService,
    protected translocoService: TranslocoService,
    @Inject(APP_CONFIG) protected appConfig: IAppConfig
  ) {
    this.audioSettingsService.$audioOn.subscribe(enabled => {
      if (!this.activePlayer) {
        return;
      }

      if (enabled) {
        this.activePlayer.unMute();
      } else {
        this.activePlayer.mute();
      }
    })
  }

  create(element: ElementRef, videoId, config = {}) {
    const shortScreen: boolean = this.mediaQueryService.screenShort();

    // @todo default captions to off in non-English too, once we have all videos available in Spanish audio
    const showCaptions = this.captionsSettingsService.captionsOn || this.translocoService.getActiveLang() !== 'en';

    // cf https://developers.google.com/youtube/player_parameters
    const langCode2Chars = this.translocoService.getActiveLang().slice(0, 2);

    this.activePlayer = YouTubePlayer(element.nativeElement, defaultsDeep(config, {
      width: shortScreen ? 640 : 853,
      height: shortScreen ? 360 : 480,
      videoId: videoId,
      playerVars: {
        autoplay: 1,
        mute: !this.audioSettingsService.audioOn.value,
        hl: langCode2Chars,
        cc_lang_pref: langCode2Chars,
        cc_load_policy: showCaptions ? 1 : 0,
        origin: window.location.origin
      }
    }));


    // YouTube API poorly documented about how to reliably get captioning functioning. Still unsure why the below works
    // rather than their suggested getOptions('captions') type of methodology, but that wasn't reliable.
    // ref https://ableplayer.github.io/ableplayer/demos/youtube.html
    // and https://ableplayer.github.io/ableplayer/build/ableplayer.js
    if (showCaptions) {
        this.activePlayer.on('apiChange', event => {
        this.activePlayer.getOptions().then(getOptionsEvent => {
          if (getOptionsEvent[0] === 'captions') {
            this.activePlayer.setOption('captions', 'reload', true);
            this.activePlayer.setOption('captions', 'fontSize', 2);
            this.activePlayer.setOption('captions', 'track', {'languageCode': langCode2Chars});
          }
        });
      });
    }

    return this.activePlayer;
  }

  reset() {
    if (this.activePlayer) {
      this.activePlayer.destroy();
    }

    this.activePlayer = null;
  }

  replay() {
    if (!this.activePlayer) {
      this.loggerService.warn('No active player to replay.');
      return;
    }

    (<any>this.activePlayer).seekTo(0);
    (<any>this.activePlayer).playVideo();
  }
}
