import {PipeTransform, Pipe} from "@angular/core";
import {TranslocoService} from "@ngneat/transloco";
import {IModuleTaskSlideConfig, IModuleOverviewSlideConfig} from "../../app.config";

/**
 * Shortcut pipe to keep l10n logic in task slides DRY. Given a `config: IModuleTaskSlideConfig` property in
 * the template:
 *
 *    <img [src]="'tablet.png' | moduleImage"
 *         [attr.alt]="'tablet/alt' | l10nSlideContent : config">
 *
 */
@Pipe({
  name: 'l10nSlideContent'
})
export class L10nSlideContentPipe implements PipeTransform {

  constructor(
    protected translocoService: TranslocoService,
  ) {}

  transform(
    contentKey: string,
    config: IModuleTaskSlideConfig|IModuleOverviewSlideConfig
  ): string {
    return this.translocoService.translate(
      config.contentL10nNode.getL10NPathForSlideContent(contentKey)
    );
  }
}
