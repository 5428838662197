import {Component, OnInit} from '@angular/core';
import {ResponseModel} from "../../core/engagement/response.model";
import {ModulePracticeUserCompletion} from "northstar-foundation";
import {ModulePracticeLessonCompleteResolve} from "../../core/resolves/module-practice-lesson-complete.resolve";
import {BaseTaskRecipeMultipleChoiceMultipleSlide} from "./base-task-recipe-multiple-choice-multiple-slide.component";
import {Observable} from "rxjs";
import { translate } from '@ngneat/transloco';

/**
 * Note the custom properties and methods below are mostly copied from PracticeBaseTaskSlideComponent
 * since we want to extend BaseTaskRecipeMultipleChoiceSingleSlide
 */
@Component({
  templateUrl: './base-task-slide.component.html'
})
export class PracticeBaseTaskRecipeMultipleChoiceMultipleSlide extends BaseTaskRecipeMultipleChoiceMultipleSlide {
  showIDontKnowButton: boolean = false;
  showNextButton: boolean = true;
  delayBetweenSlidesMs: number = 1000;

  hasClickedCorrectly: boolean = false;
  allowNextWithoutCorrectAnswer: boolean = false; // some slides may provide feedback w/out correct answer, and allow user to click Next after seeing

  ngOnInit() {
    super.ngOnInit();

    this.responseCollectionService.activateNamespace(
      this.responseCollectionService.getNamespaceForLesson(this.navigationService.getLessonForCurrentComponent())
    );
  }

  handleClickNext() {
    if (!this.hasRequiredNumSelections()) {
      this.toastr.error(translate('common.selectAdditionalResponses'));
      return false;
    }

    if (!this.correctAnswersSelected() && !this.allowNextWithoutCorrectAnswer) {
      this.onIncorrectAnswer();
      return false;
    }

    this.onCorrectAnswer();
    this.setResponseAsSuccess();
  }

  onIncorrectAnswer() {
    this.popoverService.showAll();
  }

  protected onCorrectAnswer() {
    this.popoverService.hideAll();
  }

  onResponseComplete(response: ResponseModel) {
    this.popoverService.hideAll();
    super.onResponseComplete(response);
  }

  configureSlideWrapperParameters() {
    super.configureSlideWrapperParameters();

    this.config.askUserToConfirmRetry = false;
  }

  /**
   * Audio files for NOL are beneath a 'practice' folder to separate from assessment audio.
   */
  getAudioPathSubfolder() {
    return 'practice/';
  }

  recordLessonComplete(): Observable<boolean | ModulePracticeUserCompletion> {
    const lessonCompleteResolver = this.injector.get(ModulePracticeLessonCompleteResolve);

    return lessonCompleteResolver.resolve(this.route.snapshot);
  }
}
