export const MS_PER_SECOND = 1000;
export const MS_PER_MINUTE = 60 * MS_PER_SECOND;

/**
 * Return the next Date representing the day `x` of the week, e.g. 1 => the next Monday.
 *
 * https://stackoverflow.com/a/1579109/4185989
 *
 * @param x
 * @returns {Date}
 */
export function nextDay(x: number = 1){
  const now = new Date();
  let next = new Date();

  next.setDate(now.getDate() + (x + (7 - now.getDay())) % 7);

  // ensure next date isn't today
  if (now.getDate() === next.getDate()) {
    next.setDate(now.getDate() + 7);
  }

  return next;
}
