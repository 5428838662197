import {Component, OnInit} from '@angular/core';
import {BaseOverviewSlideComponent} from "../base-overview-slide.component";
import {sharedSlidesSlugs} from "../shared-slides-slugs.config";
import {ModulePracticeSectionReviewUserCompletion, ModulePracticeLesson, User, AssessmentModule, ModulePracticeSection} from "northstar-foundation";
import {SessionService} from "../../../core/services/session.service";
import {GoogleTagManagerService} from "northstar-foundation/angular";

@Component({
  templateUrl: 'section-review-results.component.html',
  styleUrls: [
    'section-review-results.component.scss',
    '../../layout/slide-wrapper/_shared-button-types.scss'
  ]
})
export class PracticeSectionReviewResultsComponent extends BaseOverviewSlideComponent {
  static nsComponentId: string = 'PracticeSectionReviewResultsComponent';
  static slug: string = sharedSlidesSlugs.sectionReviewResult;
  static title: string = 'Section-Review Results';

  // false because we're not using this as a nav slide
  static isOverviewSlideComponent: boolean = false;
  static isSectionReviewOverview: boolean = true;

  l10nBasePath: string = 'shared.slides.sectionReviewResult.';

  showNextButton: boolean = true;
  showProgressBar: boolean = false;
  hasAudio = false;

  user: User;
  sectionReviewResults: ModulePracticeSectionReviewUserCompletion;
  lessonsRecommendedToRedo: ModulePracticeLesson[] = [];
  module: AssessmentModule;
  practiceCompleteForUser: boolean = false;

  getTitle() {
    return PracticeSectionReviewResultsComponent.title;
  }

  getSlug() {
    return PracticeSectionReviewResultsComponent.slug;
  }

  getComponentId() {
    return PracticeSectionReviewResultsComponent.nsComponentId;
  }

  getIsNavigableSlide() {
    return PracticeSectionReviewResultsComponent.isOverviewSlideComponent;
  }

  ngOnInit() {
    this.sectionReviewResults = (<any>this.route.snapshot.data).sectionReviewResults.completion;

    if (!this.sectionReviewResults) {
      // problem occurred in resolve - at least show functional slide and alert user to problem, cf template
      this.configureSlideWrapperParameters();
      return;
    }

    this.module = this.sectionReviewResults.review.section.module;

    const section = this.sectionReviewResults.review.section;

    // for final section review, don't show the Next button
    const viewingLastSectionOfModule = (<ModulePracticeSection[]>this.module.getPracticeSections()).slice(-1)[0] === section;

    this.showNextButton = !viewingLastSectionOfModule;

    this.configureSlideWrapperParameters();

    this.lessonsRecommendedToRedo = this.sectionReviewResults.getLessonsRecommendedToRedo();

    this.user = (<SessionService>this.injector.get(SessionService)).user;

    // don't call nav service's onVisitSlideComponentInstance since that tries
    // to keep track of the 'visited' status of this slide, but this slide doesn't
    // even show up in the nav menu so it's irrelevant and would complicate logic elsewhere

  }
}
