import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {SlideNavigationService} from "../../../core/engagement/slide-navigation.service";
import {Subscription} from "rxjs";
import {TimerObservable} from "rxjs/observable/TimerObservable";

@Component({
  selector: 'progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit, OnDestroy {
  percentComplete: number;
  timerSubscription: Subscription;

  constructor(public navigation: SlideNavigationService) {}


  ngOnInit() {
    // since progress bar is just being initialized and we want a transition via CSS,
    // first set it to the old task num, then update the num shortly thereafter
    this.updateProgressBar();

    this.timerSubscription = TimerObservable.create(50).subscribe(t => {
      this.navigation.updateCurrentTaskNum();
      this.updateProgressBar();
    });

  }

  ngOnDestroy() {
    this.timerSubscription.unsubscribe();
  }

  updateProgressBar() {
    this.percentComplete = this.navigation.getCurrentTaskNum() / this.navigation.getNumTasks() * 100;

    if (this.percentComplete === 0) {
      this.percentComplete = 1;  // show something
    } else if (this.percentComplete === 100) {
      this.percentComplete = 98; // don't spill out of container
    }
  }

}
