import {Component, Input} from "@angular/core";
import {AssetService} from "../../../core/services/asset.service";

@Component({
  selector: 'blinking-cursor',
  templateUrl: './cursor.component.html',
  styleUrls: ['./cursor.component.scss'],
})
export class CursorComponent {
  @Input()
  altText: string = '';

  constructor(
    public assetService: AssetService
  ) {}
}
