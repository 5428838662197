import {Injectable} from "@angular/core";
import {Observable, BehaviorSubject, Subject} from "rxjs";

export interface ITransparentBackdropEnabledUpdate {
  status: boolean,
  namespace: string
}

const transparentBackdropNavigationDefaultNamespace = 'TRANSPARENT_BACKDROP_DEFAULT_NAMESPACE';

/**
 * A generic backdrop that sits on top of (most of) the content on the screen.
 * Can be used by other components,
 * e.g. other slides' nav menu, in order to watch for
 * clicks of the transparent backdrop and react by hiding nav menu.
 */
@Injectable()
export class TransparentBackdropService {
  enabled: BehaviorSubject<ITransparentBackdropEnabledUpdate> = new BehaviorSubject({
    status: false,
    namespace: transparentBackdropNavigationDefaultNamespace
  });
  enabled$: Observable<ITransparentBackdropEnabledUpdate> = this.enabled.asObservable();

  grayed: boolean = false;

  /**
   * Namespace for different consumers to know whether it was them doing the enable/disable.
   */
  currentNamespace: string;

  /**
   * Enable the backdrop, and optionally provide a namespace to be included in the disable observable;
   *
   * @param namespace
   * @returns {TransparentBackdropService}
   */
  enable(namespace: string = transparentBackdropNavigationDefaultNamespace) {
    this.currentNamespace = namespace;

    this.enabled.next({
      status: true,
      namespace: this.currentNamespace
    });

    return this;
  }

  disable() {
    this.enabled.next({
      status: false,
      namespace: this.currentNamespace
    });

    // reset coloring
    this.setGrayed(false);
    this.currentNamespace = transparentBackdropNavigationDefaultNamespace;

    return this;
  }

  toggle() {
    this.enabled.next({
      status: !this.enabled.value.status,
      namespace: this.currentNamespace
    });

    if (!this.enabled.value.status) {
      // reset coloring
      this.setGrayed(false);
    }

    return this;
  }

  setGrayed(grayedOver: boolean = true) {
    this.grayed = grayedOver;

    return this;
  }
}
