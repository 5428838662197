import {Component, ElementRef} from '@angular/core';
import {TranslateFade} from "../../animations";
import {PhotoCreditComponent} from "./photo-credit.component";
import {CaptionsSettingsService} from "../../../core/settings/captions-settings.service";
import {AssetService} from "../../../core/services/asset.service";
import {TranslocoService} from "@ngneat/transloco";
import {
  TransparentBackdropService,
  ITransparentBackdropEnabledUpdate
} from "../../../core/engagement/transparent-backdrop.service";
import {Subscription} from "rxjs";

const transparentBackdropPhotoCreditInfoButtonNamespace = 'TRANSPARENT_BACKDROP_PHOTO_CREDIT_INFO_BUTTON_NAMESPACE';

/**
 * Photo credit (i) button for mobile breakpoints.
 */
@Component({
  selector: 'photo-credit-info-button',
  templateUrl: './photo-credit-info-button.component.html',
  styleUrls: [
    './_shared-slide-button.scss',
    './photo-credit-info-button.component.scss',
  ],
  animations: [TranslateFade(10)]
})
export class PhotoCreditInfoButtonComponent extends PhotoCreditComponent {

  creditsVisible: boolean = false;
  backdropSubscription: Subscription;

  constructor(
    public captionsSettingsService: CaptionsSettingsService,
    public assetService: AssetService,
    protected translocoService: TranslocoService,
    protected transparentBackdropService: TransparentBackdropService,
    protected el: ElementRef,
  ) {
    super(
      captionsSettingsService,
      assetService,
      translocoService
    );

    this.backdropSubscription = this.transparentBackdropService.enabled$.subscribe(
      this.backdropChangedHandler.bind(this)
    )
  }

  ngOnDestroy() {
    this.backdropSubscription.unsubscribe();
  }

  toggle(event) {
    event.preventDefault();

    this.creditsVisible = !this.creditsVisible;

    if (this.creditsVisible) {
      this.transparentBackdropService.setGrayed();
      this.transparentBackdropService.enable(transparentBackdropPhotoCreditInfoButtonNamespace);
      return;
    }

    this.transparentBackdropService.disable();
  }

  /**
   * If user interacts with the backdrop, hide credits.
   */
  backdropChangedHandler(backdropEnabledUpdate: ITransparentBackdropEnabledUpdate) {
    if (!backdropEnabledUpdate.status) {
      this.creditsVisible = false;

      if (backdropEnabledUpdate.namespace === transparentBackdropPhotoCreditInfoButtonNamespace) {
        this.focusOnToggleButton();
      }
    }
  }

  protected focusOnToggleButton() {
    const toggleButton = this.el.nativeElement.querySelector('.info-button');

    if (toggleButton) {
      toggleButton.focus();
    }
  }
}
