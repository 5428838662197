import {Injectable, Inject} from "@angular/core";
import {IAppConfig, APP_CONFIG} from "../../app.config";
import {SerializationService} from "./serialization.service";
import {User, Sponsor} from "northstar-foundation";
import {ApiService, apiEndpoints} from "./api.service";
import {LoggerService} from "./logger.service";
import {ToastrService} from 'ngx-toastr';
import {MS_PER_SECOND, MS_PER_MINUTE} from "../utilities/time";
import { translate } from "@ngneat/transloco";

@Injectable()
export class SessionService {
  /**
   * How long server keeps user's session alive if they haven't interacted w/the server. In the context of Angular,
   * note that this means if they haven't done any API requests.
   *
   * @type {number}
   */
  idleLogoutMinutes: number;

  static checkForKeepAliveFrequencySeconds: number = 60;

  user: User = null;
  userName: string = null;
  sponsor: Sponsor = null;
  lastKeptAliveRemote: Date = new Date();

  keepAliveInterval: number;

  /**
   * Flag for whether to make API request to server for sole purpose of keeping session alive. Intended for setting
   * for any user interaction: typing, clicking, navigating.
   *
   * @type {boolean}
   */
  shouldKeepAliveRemote: boolean = false;

  /**
   * Flag for whether other logic points to idea that user is logged out. Using this extra flag rather than just
   * removing User object from class in order to keep UI looking good.
   *
   * @type {boolean}
   */
  appearsLoggedOut: boolean = false;

  constructor(
    @Inject(APP_CONFIG) protected appConfig: IAppConfig,
    protected apiService: ApiService,
    protected loggerService: LoggerService,
    protected toastr: ToastrService,
    protected serializationService: SerializationService
  ) {
    this.apiService.apiCallSuccess$.subscribe(this.onApiCallSuccess.bind(this));
    this.apiService.apiCallError$.subscribe(this.onApiCallError.bind(this));
    this.keepAliveInterval = setInterval(this.checkForKeepAlive.bind(this), SessionService.checkForKeepAliveFrequencySeconds * MS_PER_SECOND); // check whether ought to keep alive
  }

  setUser(user: User|string) {
    if (typeof user === 'object') { // i.e. User object
      // this.loggerService.log(['Setting active user', user]);
      this.user = <User>user;
    } else {
      this.userName = <string>user;
    }
  }

  setSessionSettingLogoutIdleMinutes(mins: number) {
    this.loggerService.log(['Setting SessionService.idleLogoutMinutes', mins]);
    this.idleLogoutMinutes = mins;
  }

  getUserFullName() {
    if (this.userIsLoggedIn()) {
      return this.user.fullName;
    }

    return this.userName;
  }

  clearUser() {
    this.user = null;
  }

  userIsLoggedIn() {
    return !this.appearsLoggedOut
      && typeof this.user === 'object'
      && this.user !== null
      && this.lastKeptAliveRemote > new Date(Date.now() - this.idleLogoutMinutes * MS_PER_MINUTE);
  }

  /**
   * Whenever an API call is successful, track when that happened, in order to track when session may be coming to an end.
   */
  protected onApiCallSuccess() {
    if (this.userIsLoggedIn()) {
      this.loggerService.log(['Appears user session extended since remote request successful and user previously was logged in.']);
      this.registerKeptAliveRemote();
    }
  }

  protected onApiCallError(error) {
    if (error.status === 403) {
      this.handleUserAppearingLoggedOut();
    }
  }

  /**
   * Sees whether keep-alive API call is necessary, or gives messaging to user if appropriate.
   */
  protected checkForKeepAlive() {
    // @todo return early if !this.user

    if (typeof this.idleLogoutMinutes === 'undefined') {
      this.loggerService.warn(['`idleLogoutMinutes` not set. Skip remote keep-alive since unsure how often it is expected.']);
      return;
    }

    // allow for a few cycles of keep-alive behavior after alerting user to nearness of logout
    // @todo this calculation may be mistaken....
    const nearingLogoutTime = (new Date()) > this.getTimeForNearingLogout();

    // already logged out
    const appearsLoggedOut = (new Date()) > this.getTimeForLogout();

    if (appearsLoggedOut) {
      // this.loggerService.log(['Skip remote keep-alive since user not logged in.']);
      this.handleUserAppearingLoggedOut();
      return;
    }

    if (!nearingLogoutTime) {
      // this.loggerService.log(['Skip remote keep-alive since not yet nearing logout. Time waiting for, to consider near logout:', this.getTimeForNearingLogout()]);
      return;
    }

    // not logged out yet but nearly, and seem idle so shouldn't attempt to keep session alive till they prove they're there
    if (!this.shouldKeepAliveRemote) {
      // this.loggerService.log(['User seems idle, nearing logout.']);
      this.toastr.warning('Still there? Interact with the screen if you would like to stay logged in. Otherwise your session will expire momentarily.', null, {
        timeOut: SessionService.checkForKeepAliveFrequencySeconds * MS_PER_SECOND
      });
      return;
    }

    // user has demonstrated activity, so keep their session alive
    this.attemptKeepAlive();
  }

  /**
   * API call to keep server session alive.
   */
  protected attemptKeepAlive() {
    // note: no need to subscribe to success below due to existing `this.apiService.apiCallSuccess$.subscribe`
    this.apiService.httpGet(apiEndpoints.me)
      .subscribe(
        () => {
          this.toastr.clear();
          // this subscribe needed for some reason to make the API service's Subject behave as expected?
        },
        error => {
         this.loggerService.warn('Error attempting to keep alive remotely.');
        }
      );
  }

  protected handleUserAppearingLoggedOut() {
      if (!this.user) {
        this.loggerService.log(['User appears logged out, but not alerting them since they never seemed to be logged in.']);
        return;
      }

      this.toastr.error(translate('common.youAppearToBeLoggedOut', { currentUrlForLoginRedirect: this.getCurrentUrlForLoginRedirect() }), null, {
        enableHtml: true,
        closeButton: true,
        timeOut: 99999 * MS_PER_SECOND
      });
      clearInterval(this.keepAliveInterval);
      this.appearsLoggedOut = true;
  }

  protected getTimeForNearingLogout() {
    return new Date(this.lastKeptAliveRemote.getTime() + this.idleLogoutMinutes * MS_PER_MINUTE - SessionService.checkForKeepAliveFrequencySeconds * MS_PER_SECOND * 4);
  }

  protected getTimeForLogout() {
    return new Date(this.lastKeptAliveRemote.getTime() + this.idleLogoutMinutes * MS_PER_MINUTE);
  }

  /**
   * For other components/services to call, flagging that user is active and deserves session preservation.
   */
  registerKeptAliveLocal() {
    if (!this.userIsLoggedIn()) {
      // this.loggerService.log(['Skipping local flag for keep-alive since user already seems logged out.']);
      return;
    }

    if (!this.shouldKeepAliveRemote) {
      // this.loggerService.log(['Flagging local session to be kept alive.']);
    }

    this.shouldKeepAliveRemote = true;

    // if almost logged out, don't wait for the interval that `checkForKeepAlive` is on - just do it
    if ((new Date()) > this.getTimeForNearingLogout()) {
      this.checkForKeepAlive();
    }
  }

  protected registerKeptAliveRemote() {
      this.lastKeptAliveRemote = new Date();
      this.shouldKeepAliveRemote = false; // reset to require user to interact more
      this.loggerService.log(['Session was kept alive remotely at ', this.lastKeptAliveRemote, ' so new auto-logout time:', this.getTimeForLogout()]);
  }

  getCurrentUrlForLoginRedirect() {
    return `${this.appConfig.loginUrl}?next=${window.location.href}`;
  }

  // SPONSOR DATA

  setSponsor(sponsor: Sponsor) {
    this.sponsor = sponsor;

    if (this.sponsor) {
      this.loggerService.log(['Setting sponsor', sponsor.id]);
    }
  }

  getSponsor() {
    return this.sponsor;
  }
}
