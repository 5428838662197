import {Component, OnInit, Input, HostBinding} from '@angular/core';
import {SlideNavigationService} from "../../../core/engagement/slide-navigation.service";
import {ActivatedRoute} from "@angular/router";
import {KeyValueStoreService} from "../../../core/services/key-value-store.service";

@Component({
  selector: 'next-button',
  templateUrl: './next-button.component.html',
  styleUrls: [
    './_shared-controls-button.scss',
    './_shared-button-types.scss',
    './next-button.component.scss'
  ]
})
export class NextButtonComponent implements OnInit {

  @Input() isDisabled;
  @Input() customHandleClickNext;

  constructor(
    protected slideNavigationService: SlideNavigationService,
    protected route: ActivatedRoute,
    public keyValueStoreService: KeyValueStoreService
  ) {
  }

  handleClick(event) {
    event.preventDefault();

    if (typeof this.customHandleClickNext === 'undefined') {
      this.slideNavigationService.goNext(this.route);
      return;
    }

    this.customHandleClickNext();
  }

  nextIsDisabled() {
    if (typeof this.isDisabled === 'undefined') {
      return false;
    }

    return this.isDisabled;
  }

  ngOnInit() {
  }

}
