import {Injectable} from "@angular/core";
import {TranslocoService} from "@ngneat/transloco";
import {getWidthOfText} from "../utilities/text";

// in JSON files, the special key to indicate no separate caption is necessary,
// but rather to direct user to refer to the slide's content
export const referToContentCaptionKey = 'REFER_TO_CONTENT';

@Injectable()
export class CaptionsSettingsService {
  static viewportWidth: number = 1100;

  measurementCanvas: any;

  captionsOn: boolean = false;
  captions: string[] = [];
  currentCaption: string = '';

  constructor(protected translocoService: TranslocoService) {}

  toggleCaptions(event) {
    event.preventDefault();

    this.captionsOn = !this.captionsOn;
  }

  queueCaption(captionHtml) {
    // resetting caption to null state
    if (!captionHtml) {
      this.currentCaption = null;
      return;
    }

    // Need to create temporary element here to make the 'document fragment'
    // passed as argument usable. Otherwise ng2's [innerHTML] doesn't work w/in template
    // and just shows [Object object] instead.
    let tempElement = document.createElement('div');
    tempElement.appendChild(captionHtml);

    setTimeout(() => {
      this.currentCaption = tempElement.innerHTML;
    }, 1);
  }

  queueCaptionText(text) {
    if (text === referToContentCaptionKey) {
      this.queueCaption(document.createRange().createContextualFragment(this.translocoService.translate('shard.referToContent')));
      return;
    }

    // https://stackoverflow.com/a/25214113/4185989
    this.queueCaption(document.createRange().createContextualFragment(text));
  }
}
