import {Component, OnInit, Input} from '@angular/core';
import {CaptionsSettingsService} from "../../../core/settings/captions-settings.service";
import {AssetService} from "../../../core/services/asset.service";
import {IPhotoCreditConfig} from "../../../app.config";
import {isArray} from "lodash";
import {TranslocoService} from "@ngneat/transloco";

@Component({
  selector: 'photo-credit',
  templateUrl: './photo-credit.component.html',
  styleUrls: ['./photo-credit.component.scss']
})
export class PhotoCreditComponent implements OnInit {

  predefinedCredits: any = {
    google: {
      expanded: true
    },
    googleAndGmail: {
      expanded: true
    },
    googleWorkspacePanel: {
      expanded: true
    },
    microsoft: {
      expanded: true
    },
    microsoftPlusCalendarForPowerPoint: {
      expanded: true
    },
    facebook: {
      expanded: true
    },
    twitter: {
      expanded: true
    },
    gDocs: {
      expanded: true,
    },
    googleAndMicrosoft: {
      expanded: true
    },
    chromeAndMicrosoft: {
      expanded: true
    },
    officeChromeMacOs: {
      expanded: true
    },
    microsoftGoogleApple: {
      expanded: true
    },
    windowsAndMacOs: {
      expanded: true
    },
    macOs: {
      expanded: true
    },
    instagram: {
      expanded: true
    },
    facebookAndMessenger: {
      expanded: true
    }
  };

  @Input() config: IPhotoCreditConfig = {expanded: false};

  predefinedCredit: string = '';

  hasMultipleLines: boolean = false;

  constructor(
    public captionsSettingsService: CaptionsSettingsService,
    public assetService: AssetService,
    protected translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    if (typeof this.config !== 'undefined'
        && typeof this.config.creditKey !== 'undefined'
        && typeof this.predefinedCredits[this.config.creditKey] !== 'undefined'
    ) {
      // get predefined credits from l10n files
      this.predefinedCredit = this.translocoService.translate(`photoCredits.${this.config.creditKey}`);

      if (typeof this.predefinedCredits[this.config.creditKey].expanded !== 'undefined') {
        this.config.expanded = this.predefinedCredits[this.config.creditKey].expanded;
      }
    }

    this.hasMultipleLines = isArray(this.config.creditContent);
  }

}
