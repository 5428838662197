import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {ApiService, apiEndpoints} from "../services/api.service";
import {SessionService} from "../services/session.service";
import {CustomDataStore} from "../data-store/custom-data-store.service";
import {LoggerService} from "../services/logger.service";
import {User, Sponsor, SponsorLearnerMembership, ILanguage} from "northstar-foundation";
import {TimeOnTaskService} from "../services/time-on-task.service";
import {of} from "rxjs";
import {catchError, switchMap} from "rxjs/operators";
import {TranslocoService} from "@ngneat/transloco";
import {snakeCase} from "lodash";
import {ServerSettingsService} from "../settings/server-settings.service";

declare var trackJs: any;

/**
 * Logged-in user data.
 */
export interface IApiMeUserResponse extends User {
  id: number,
  firstName: string,
  lastName: string,
  email: string,
  fullName: string,
  isStaff: boolean,
  isActive: boolean,
  lastLogin: string
}

/**
 * Settings from server or user-session-specific data. Note that this may or may not be a logged-in user,
 * e.g. logged-out user choosing language on server should have that same language respected in assessments.
 *
 */
export interface IApiSessionResponse {
  language: string,
  nsTimeOnTaskSponsorId?: number,
  nsTimeOnTaskLocation?: 'onsite'|'offsite',
  settings: {
    timeOnTaskIdleMinutes: number,
    logoutIdleMinutes: number,
    languages: ILanguage[]
  }
}

export interface IApiMeResponse {
  me: IApiMeUserResponse,
  session: {
  },
  sponsorLearnerMemberships: SponsorLearnerMembership[],
  sponsors: Sponsor[]
}

@Injectable()
export class MeResolve implements Resolve<any> {

  constructor(protected loggerService: LoggerService,
              protected dataStore: CustomDataStore,
              protected sessionService: SessionService,
              protected timeOnTaskService: TimeOnTaskService,
              protected translocoService: TranslocoService,
              protected apiService: ApiService,
              protected serverSettingsService: ServerSettingsService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot) {
    return this.apiService.httpGet(apiEndpoints.me)
                          .pipe(
                            switchMap(this.onMeResponse.bind(this)),
                            catchError(this.onMeError.bind(this)),
                            switchMap(sessionResponse => this.apiService.httpGet(apiEndpoints.session)),
                            switchMap(this.onSessionResponse.bind(this)),
                            catchError(this.onSessionError.bind(this)),
                          );
  }

  onMeResponse(response: IApiMeResponse) {
    this.dataStore.add('sponsor', response.sponsors);
    this.sessionService.setUser(<User>this.dataStore.add('user', response.me));
    this.dataStore.add('sponsor_learner_membership', response.sponsorLearnerMemberships);

    if (this.sessionService.userIsLoggedIn()) {
      if (typeof trackJs !== 'undefined') {
        trackJs.addMetadata('NS User ID', '' + this.sessionService.user.id); // coerce int to str
      }
    }

    return of(response.me);
  }

  onMeError(response) {
    this.loggerService.warn('Error querying user status - probably is logged out.');

    return of(false);
  }

  onSessionResponse(response: IApiSessionResponse) {
    this.serverSettingsService.configure(response.settings);
    this.sessionService.setSessionSettingLogoutIdleMinutes(response.settings.logoutIdleMinutes);
    this.timeOnTaskService.setIdleSessionEndMinutes(response.settings.timeOnTaskIdleMinutes);

    if (response.nsTimeOnTaskLocation) {
      this.timeOnTaskService.setLocation(response.nsTimeOnTaskLocation);
      this.timeOnTaskService.setSponsor(response.nsTimeOnTaskSponsorId);
      this.sessionService.setSponsor(this.dataStore.get('sponsor', response.nsTimeOnTaskSponsorId));
    }

    this.translocoService.setActiveLang(snakeCase(response.language));

    return of(response);
  }

  onSessionError(response) {
    this.loggerService.warn('Error querying session status - server probably down.');

    return of(false);
  }
}
