import {TranslocoService} from "@ngneat/transloco";
import {PipeTransform, Pipe} from "@angular/core";

/**
 * Convenience pipe to make templates more DRY and reconfigurable by allowing a component's .ts file to specify
 * the base l10n path, e.g. l10nBasePath: string = 'shared.slides.audioSetup.';
 *
 * and then in the template:     <slide-title [text]="'title' | l10nForPath : l10nBasePath"></slide-title>
 */
@Pipe({
  name: 'l10nForPath'
})
export class L10nForPathPipe implements PipeTransform {

  constructor(protected translocoService: TranslocoService,) {
  }

  transform(contentKey: string, l10nBasePath: string): string {
    return this.translocoService.translate(l10nBasePath + contentKey);
  }
}
