import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {LoggerService} from "../services/logger.service";

export interface IRecordedClick {
  id: string,
  event: any
}

export interface IRecordedEvent {
  type: string,
  meta: any
}

@Injectable()
export class InterfaceInteractionStagerService {

  private recordedClick = new Subject<IRecordedClick>();
  private recordedEvent = new Subject<any>();

  /**
   * Main observable in modules to track whether user has clicked UI components for success/failure of questions.
   * @type {Observable<IRecordedClick>}
   */
  recordedClick$ = this.recordedClick.asObservable();

  /**
   * Broader option of pub-sub than just clicks (associated with recordedClick$), for use with generic events, e.g. drag and drop.
   *
   * @type {Observable<any>}
   */
  recordedEvent$ = this.recordedEvent.asObservable();


  constructor(
    protected loggerService: LoggerService
  ) {}

  recordClick(clickEventId: string, event: Event|CustomEvent) {
    this.loggerService.log(['Recording click', clickEventId, event]);

    this.recordedClick.next({
      id: clickEventId,
      event: event
    });
  }

  recordEvent(event: IRecordedEvent) {
    this.loggerService.log(['Recording event', event]);

    this.recordedEvent.next(event);
  }
}
