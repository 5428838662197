import {PipeTransform, Pipe} from "@angular/core";
import {AssetService} from "../../core/services/asset.service";

/**
 * Pipe to make it easy to get at image-assets folder rather than hardcoding absolute path each time.
 * Cf ModuleImagePipe for easier way of getting at current module's images.
 */
@Pipe({
  name: 'imageAsset'
})
export class ImageAssetPipe implements PipeTransform {

  constructor(
    protected assetService: AssetService
  ) {}

  transform(filename: string): string {
    return this.assetService.getImage(filename);
  }
}
