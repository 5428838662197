import {Directive, HostListener, Input, ElementRef, Renderer2} from '@angular/core';
import {InterfaceInteractionStagerService} from "../../core/engagement/interface-interaction-stager.service";
import {LoggerService} from "../../core/services/logger.service";

/**
 * Sends click info on to the InterfaceInteractionStagerService for potential scoring.
 * Shortcut for common cases where an anchor isn't supposed to do anything except be tracked for clicks;
 * perhaps not suitable for cases where the element is also supposed to do something else, like open
 * a menu.
 *
 * As convenience for DRY, auto-sets the role=button and href=# attributes if not already set.
 */
@Directive({
  selector: '[recordClick]'
})
export class RecordClickDirective {

  @Input('recordClick') clickId: string;

  @Input()
  allowDefaultEvent: boolean = false;

  constructor(
    protected el: ElementRef,
    protected stagerService: InterfaceInteractionStagerService,
    protected loggerService: LoggerService,
    protected renderer: Renderer2
  ) {
    const nativeElement = el.nativeElement;
    const isAnchor = nativeElement.tagName.toLowerCase() === 'a';
    const isInput = nativeElement.tagName.toLowerCase() === 'input';

    if (isAnchor) {
      if (!nativeElement.getAttribute('role')) {
        this.renderer.setAttribute(nativeElement, 'role', 'button');
      }

      if (!nativeElement.getAttribute('href')) {
        this.renderer.setAttribute(nativeElement, 'href', '#');
      }
    }
  }

  /**
   * For convenience, if something that's worth tracking clicks on, hasn't been assigned an ID explicitly,
   * set its ID according to what the recordClick value is, prefixed with `auto-id-` to distinguish slightly.
   *
   * Useful for constructing targets for popovers' hug attribute.
   */
  ngOnInit() {
    if (!this.el.nativeElement.getAttribute('id')) {
      this.renderer.setAttribute(this.el.nativeElement, 'id', `auto-id-${this.clickId}`);
    }
  }

  /**
   * Record click to stager service when element clicked, so that components can observe and react if needed.
   *
   * @param event
   */
  @HostListener('click', ['$event'])
  onClick(event) {
    if (!this.allowDefaultEvent) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (!this.clickId) {
      this.loggerService.error('No click ID provided');
    }

    this.stagerService.recordClick(this.clickId, event);
  }

  /**
   * Per MDN respond to spacebar clicks on a button same as if Enter were pressed.
   *
   * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_button_role
   *
   * @param event
   */
  @HostListener('keydown.space', ['$event'])
  handleSpacebarPress(event: KeyboardEvent) {
    if (this.el.nativeElement.getAttribute('role') === 'button') {
      this.loggerService.log('Handling spacebar click on element');
      this.onClick(event);
    }
  }

}
