import {Component, Input, HostBinding, OnInit} from "@angular/core";
import {AssessmentModuleStandard, ModulePracticeLesson, AssessmentModule, User} from "northstar-foundation";
import * as location_origin from "location-origin";
import {SessionService} from "../../../core/services/session.service";
import {find} from "lodash";
import {CustomDataStore} from "../../../core/data-store/custom-data-store.service";
import {LoggerService} from "../../../core/services/logger.service";

@Component({
  selector: 'standard-practice-button',
  templateUrl: 'standard-practice-button.component.html',
  styleUrls: ['standard-practice-button.component.scss']
})
export class StandardPracticeButton implements OnInit {
  @Input() module: AssessmentModule;
  @Input() standard: AssessmentModuleStandard;
  @Input() alwaysShowIncomplete: boolean = false; // configurable to allow assessment-results page to show Needs Improvement icons w/dumbbells
  @Input() variant: 'default'|'narrow' = 'default';
  @Input() openNewWin: boolean = false;
  @Input() user: User = null; // which user we should check whether they've done the practice

  @HostBinding('class')
  hostClass: string = 'float-right';

  window: any = window;
  baseUrl: string = window.location.origin + '/';
  standardCompleted: boolean;
  relevantPracticeLesson: ModulePracticeLesson;

  constructor(
    protected sessionService: SessionService,
    protected loggerService: LoggerService,
    protected dataStore: CustomDataStore,
  ) {}

  ngOnInit() {
    if (!this.user) {
      if (!this.sessionService.user) {
        throw Error('No user to check standards progress for.');
      }

      this.user = <User>this.sessionService.user;
    }

    this.hostClass += ` ${this.variant}`;

    this.determineCompletion();
  }

  determineCompletion() {
    // no practice available currently
    if (this.standard.practiceLessons.length <= 0) {
      this.standardCompleted = false;
      return;
    }

    const lessons = this.dataStore.filter('module_practice_lesson', {
      where: {
        moduleId: this.module.id,
        standardIds: {
          contains: this.standard.id
        }
      }
    });

    if (lessons.length <= 0) {
      this.standardCompleted = false;
      return;
    }

    this.relevantPracticeLesson = lessons[0];
    this.standardCompleted = this.relevantPracticeLesson.completedByUser(this.user.id);
  }
}
