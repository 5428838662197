import {PipeTransform, Pipe} from "@angular/core";
import {AssetService} from "../../core/services/asset.service";
import {ModuleImagePipe} from "./module-image.pipe";

/**
 * Get image for current module, but look within subfolder 'practice' for sake of NSOL content.
 */
@Pipe({
  name: 'modulePracticeImage'
})
export class ModulePracticeImagePipe implements PipeTransform {

  constructor(
    protected assetService: AssetService,
    protected moduleImagePipe: ModuleImagePipe,
  ) {}

  transform(filename: string): string {
    return this.moduleImagePipe.transform('practice/' + filename);
  }
}
