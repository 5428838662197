import {Injectable} from "@angular/core";
import {PlatformService} from "../environment/platform.service";
import {forEach} from "lodash";

export interface IKeyboardKey {
  code: string,
  key: string,
  // `which` and `keycode` deprecated https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent
  // but are the only reliable means while holding modifier (e.g. Ctrl) key down for IE and Edge
  which?: number
}

export interface IOSDependentText {
  windows: string,
  mac: string
}

export interface IKeyboardKeyCollection {
  letterA: IKeyboardKey,
  letterB: IKeyboardKey,
  letterC: IKeyboardKey,
  letterD: IKeyboardKey,
  letterE: IKeyboardKey,
  letterF: IKeyboardKey,
  letterI: IKeyboardKey,
  letterL: IKeyboardKey,
  letterN: IKeyboardKey,
  letterP: IKeyboardKey,
  letterR: IKeyboardKey,
  letterS: IKeyboardKey,
  letterU: IKeyboardKey,
  letterV: IKeyboardKey,
  letterW: IKeyboardKey,
  letterX: IKeyboardKey,
  letterZ: IKeyboardKey,
  equal: IKeyboardKey,
  minus: IKeyboardKey
  spacebar: IKeyboardKey,
  enter: IKeyboardKey,
  backspace: IKeyboardKey,
  deleteKey: IKeyboardKey,
  numpadAdd: IKeyboardKey,
  numpadMinus: IKeyboardKey,
  lessThan: IKeyboardKey,
  greaterThan: IKeyboardKey,
  f5: IKeyboardKey,
  arrowDown: IKeyboardKey,
  arrowUp: IKeyboardKey,
  arrowLeft: IKeyboardKey,
  arrowRight: IKeyboardKey,
  tab: IKeyboardKey,
  escape: IKeyboardKey
  home: IKeyboardKey,
  end: IKeyboardKey,
  pageUp: IKeyboardKey,
  pageDown: IKeyboardKey
}

export const keyboardKeys: IKeyboardKeyCollection = {
  letterA: {
    code: 'KeyA',
    key: 'a',
    which: 65
  },
  letterB: {
    code: 'KeyB',
    key: 'b',
    which: 66
  },
  letterC: {
    code: 'KeyC',
    key: 'c',
    which: 67
  },
  letterD: {
    code: 'KeyD',
    key: 'd',
    which: 68
  },
  letterE: {
    code: 'KeyE',
    key: 'e',
    which: 69
  },
  letterF: {
    code: 'KeyF',
    key: 'f',
    which: 70
  },
  letterI: {
    code: 'KeyI',
    key: 'i',
    which: 73
  },
  letterL: {
    code: 'KeyL',
    key: 'l',
    which: 76
  },
  letterN: {
    code: 'KeyN',
    key: 'n',
    which: 78
  },
  letterP: {
    code: 'KeyP',
    key: 'p',
    which: 80
  },
  letterR: {
    code: 'KeyR',
    key: 'r',
    which: 82
  },
  letterS: {
    code: 'KeyS',
    key: 's',
    which: 83
  },
  letterU: {
    code: 'KeyU',
    key: 'u',
    which: 85
  },
  letterV: {
    code: 'KeyV',
    key: 'v',
    which: 86
  },
  letterW: {
    code: 'KeyW',
    key: 'w',
    which: 87
  },
  letterX: {
    code: 'KeyX',
    key: 'x',
    which: 88
  },
  letterZ: {
    code: 'KeyZ',
    key: 'z',
    which: 90
  },
  equal: {
    code: 'Equal',
    key: '=',
    which: 187
  },
  minus: {
    code: 'Minus',
    key: '-',
    which: 189
  },
  numpadAdd: {
    code: 'NumpadAdd',
    key: '+',
    which: 107
  },
  numpadMinus: {
    code: 'NumpadSubtract',
    key: '-',
    which: 109
  },
  spacebar: {
    code: 'Space',
    key: ' ',
    which: 32
  },
  enter: {
    code: 'Enter',
    key: 'Enter'
  },
  backspace: {
    code: 'Backspace',
    key: 'Backspace'
  },
  // named such to not conflict w/JS reserved words
  deleteKey: {
    code: 'Delete',
    key: 'Delete',
    which: 46
  },
  lessThan: {
    code: 'Comma',
    key: '<',
    which: 188
  },
  greaterThan: {
    code: 'Period',
    key: '>',
    which: 190
  },
  f5: {
    code: 'F5',
    key: 'F5',
    which: 116
  },
  arrowDown: {
    code: 'ArrowDown',
    key: 'ArrowDown',
    which: 40
  },
  arrowUp: {
    code: 'ArrowUp',
    key: 'ArrowUp',
    which: 38
  },
  arrowLeft: {
    code: 'ArrowLeft',
    key: 'ArrowLeft',
    which: 37
  },
  arrowRight: {
    code: 'ArrowRight',
    key: 'ArrowRight',
    which: 39
  },
  tab: {
    code: 'Tab',
    key: 'Tab',
    which: 9
  },
  escape: {
    code: 'Escape',
    key: 'Escape',
    which: 27
  },
  pageDown: {
    code: 'PageDown',
    key: 'PageDown',
    which: 34
  },
  pageUp: {
    code: 'PageUp',
    key: 'PageUp',
    which: 33
  },
  end: {
    code: 'End',
    key: 'End',
    which: 35
  },
  home: {
    code: 'Home',
    key: 'Home',
    which: 36
  },
};

@Injectable()
export class KeyboardEventInterpreterService {

  constructor(protected platformService: PlatformService) {}

  eventIsKeyboardEvent(event: Event) {
    return event instanceof KeyboardEvent;
  }

  eventIsAmongKeys(event: KeyboardEvent, keys: IKeyboardKey[]) {
    let keyFound = false;

    forEach(keys, (keyboardKey: IKeyboardKey) => {
      if (this.eventIsKey(event, keyboardKey)) {
        keyFound = true;
        return false; // break
      }
    });

    return keyFound;
  }

  eventIsKey(event: KeyboardEvent, key: IKeyboardKey) {
    // `code` is the modern standard for comparing, where `which` is deprecated;
    // only relying on `which` when needed, e.g. IE and Edge while holding down Ctrl key, since otherwise
    // they return 'Unidentified' as the key
    return event.code ===  key.code || event.key === key.key || (key.which && event.which === key.which);
  }

  /**
   * Many key combos use Command on Mac, Ctrl on other systems. Convenience method to test.
   *
   * @param event
   * @returns {boolean}
   */
  eventIsValidComboForPlatform(event: KeyboardEvent) {
    if (this.platformService.isMac) {
      return event.metaKey;
    }

    return event.ctrlKey;
  }

  eventIncludesCtrlWhilePlatformIsMac(event: KeyboardEvent) {
    return event.ctrlKey && this.platformService.isMac;
  }

  eventIsCloseTab(event: KeyboardEvent) {
    if (!this.eventIsKey(event, keyboardKeys.letterW)) {
      return false;
    }

    return this.eventIsValidComboForPlatform(event);
  }

  eventIsSave(event: KeyboardEvent) {
    if (!this.eventIsKey(event, keyboardKeys.letterS)) {
      return false;
    }

    return this.eventIsValidComboForPlatform(event);
  }

  _eventIsZoomKey(event: KeyboardEvent) {
    return this.eventIsKey(event, keyboardKeys.equal) || this.eventIsKey(event, keyboardKeys.numpadAdd);
  }

  eventIsZoom(event: KeyboardEvent) {
    if (!this._eventIsZoomKey(event)) {
      return false;
    }

    return this.eventIsValidComboForPlatform(event);
  }

  _eventIsZoomOutKey(event: KeyboardEvent) {
    return this.eventIsKey(event, keyboardKeys.minus) || this.eventIsKey(event, keyboardKeys.numpadMinus);
  }

  eventIsZoomOut(event: KeyboardEvent) {
    if (!this._eventIsZoomOutKey(event)) {
      return false;
    }

    return this.eventIsValidComboForPlatform(event);
  }

  eventIsFind(event: KeyboardEvent) {
    if (!this.eventIsKey(event, keyboardKeys.letterF)) {
      return false;
    }

    return this.eventIsValidComboForPlatform(event);
  }

  eventIsBold(event: KeyboardEvent) {
    if (!this.eventIsKey(event, keyboardKeys.letterB)) {
      return false;
    }

    return this.eventIsValidComboForPlatform(event);
  }

  eventIsUnderline(event: KeyboardEvent) {
    if (!this.eventIsKey(event, keyboardKeys.letterU)) {
      return false;
    }

    return this.eventIsValidComboForPlatform(event);
  }

  eventIsPrint(event: KeyboardEvent) {
    if (!this.eventIsKey(event, keyboardKeys.letterP)) {
      return false;
    }

    return this.eventIsValidComboForPlatform(event);
  }

  eventIsUndo(event: KeyboardEvent) {
    if (!this.eventIsKey(event, keyboardKeys.letterZ)) {
      return false;
    }

    return this.eventIsValidComboForPlatform(event);
  }

  eventIsCut(event: KeyboardEvent) {
    if (!this.eventIsKey(event, keyboardKeys.letterX)) {
      return false;
    }

    return this.eventIsValidComboForPlatform(event);
  }

  eventIsCopy(event: KeyboardEvent) {
    if (!this.eventIsKey(event, keyboardKeys.letterC)) {
      return false;
    }

    return this.eventIsValidComboForPlatform(event);
  }

  eventIsPaste(event: KeyboardEvent) {
    if (!this.eventIsKey(event, keyboardKeys.letterV)) {
      return false;
    }

    return this.eventIsValidComboForPlatform(event);
  }

  /////////////////////////////////
  // MS-Office specific shortcuts
  /////////////////////////////////

  eventIsMSOfficeAlignCenter(event: KeyboardEvent) {
    if (!this.eventIsKey(event, keyboardKeys.letterE)) {
      return false;
    }

    return this.eventIsValidComboForPlatform(event);
  }

  eventIsMSOfficeAlignLeft(event: KeyboardEvent) {
    if (!this.eventIsKey(event, keyboardKeys.letterL)) {
      return false;
    }

    return this.eventIsValidComboForPlatform(event);
  }

  eventIsMSOfficeDecreaseFont(event: KeyboardEvent) {
    if (!this.eventIsKey(event, keyboardKeys.lessThan) || !event.shiftKey) {
      return false;
    }

    return this.eventIsValidComboForPlatform(event);
  }

  eventIsMSOfficeIncreaseFont(event: KeyboardEvent) {
    if (!this.eventIsKey(event, keyboardKeys.greaterThan) || !event.shiftKey) {
      return false;
    }

    return this.eventIsValidComboForPlatform(event);
  }
}
