import {Component, OnInit, Inject, HostListener, OnDestroy, Input, HostBinding} from '@angular/core';
import {Sponsor, ProctorSessionForUser} from "northstar-foundation";
import {SlideNavigationService} from "../../../core/engagement/slide-navigation.service";
import {ActivatedRoute, Router} from "@angular/router";
import {LoggerService} from "../../../core/services/logger.service";
import {AssetService} from "../../../core/services/asset.service";
import {CustomDataStore} from "../../../core/data-store/custom-data-store.service";
import {APP_CONFIG, IAppConfig} from "../../../app.config";
import {BootstrapDataService} from "../../../core/bootstrap/bootstrap-data.service";
import {TranslateFade} from "../../animations";
import {SessionService} from "../../../core/services/session.service";
import {LoadingOverlayService} from "../../../core/engagement/loading-overlay.service";
import {ProctorSessionForUserService} from "../../../core/services/proctor-session-for-user.service";
import {MediaQueryService} from "../../../core/environment/media-query.service";

@Component({
  selector: 'ns-module-wrapper-user-info',
  templateUrl: './module-wrapper-user-info.component.html',
  styleUrls: ['./module-wrapper-user-info.component.scss'],
  animations: [TranslateFade()]
})
export class ModuleWrapperUserInfoComponent {
  @HostBinding('class')
  @Input()
  context: 'assessment'|'nsol';

  constructor(
    @Inject(APP_CONFIG) protected appConfig: IAppConfig,
    protected loggerService: LoggerService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected navigation: SlideNavigationService,
    protected assetService: AssetService,
    protected dataStore: CustomDataStore,
    protected bootstrapData: BootstrapDataService,
    public sessionService: SessionService,
    public proctorSessionForUserService: ProctorSessionForUserService,
    protected loadingOverlayService: LoadingOverlayService,
    public mediaQueryService: MediaQueryService,
  ) {
  }

  ngOnInit() {
    // this.testProctored();
    // this.testUnproctored();
  }

  testProctored() {
    this.sessionService.userName = 'Some User With Some Long Combination of Names';
    this.sessionService.sponsor = <Sponsor>{name: 'Demo Location With A Very Long Name that Seems to Go On for Quite a While'};
    this.proctorSessionForUserService.session = <ProctorSessionForUser>{};
  }

  testUnproctored() {
    this.sessionService.userName = 'Some User With Some Long Combination of Names';
    this.sessionService.sponsor = <Sponsor>{name: 'Demo Location With A Very Long Name that Seems to Go On for Quite a While'};
    this.proctorSessionForUserService.session = null;
  }
}
