import {OnInit, Component} from "@angular/core";
import {TranslateFade} from "../../animations";
import {ModuleWrapperComponent} from "../module-wrapper/module-wrapper.component";
import {AssessmentModule, ModulePracticeLesson} from "northstar-foundation";
import {NavigationEnd} from "@angular/router";
import {forEach} from "lodash";
import {IPracticeLessonLocalMeta, IPracticeSectionReviewLocalMeta} from "../../interfaces/practice.interfaces";

@Component({
  templateUrl: './practice-wrapper.component.html',
  styleUrls: ['./practice-wrapper.component.scss'],
  animations: [TranslateFade()]
})
export class PracticeWrapperComponent extends ModuleWrapperComponent implements OnInit {
  lesson: ModulePracticeLesson|boolean;
  sectionMeta: any;

  /**
   * Similar to super's, but don't rely on bootstrapData for user or proctor info.
   */
  ngOnInit() {
    this.cascadeSectionMetaToLessonLocalMeta();
    this.initModule();
    this.lesson =  this.navigation.getLessonForCurrentComponent();

    this.loadingOverlayService.setMode('congrats');
  }

  /**
   * Make it easy to reference what section a particular lesson or review is for by making the section slug available.
   * Used to automate the namespacing of response collections so that different lessons and reviews' outcomes are
   * housed in different buckets.
   */
  cascadeSectionMetaToLessonLocalMeta() {
    forEach(this.sectionMeta, (sectionContent, sectionKey) => {
      forEach(sectionContent.lessons, (lessonLocalMeta: IPracticeLessonLocalMeta, lessonKey) => {
        lessonLocalMeta.parentSectionSlug = sectionContent.slug;
      });

      (<IPracticeSectionReviewLocalMeta>sectionContent.review).parentSectionSlug = sectionContent.slug;
    });
  }

  // urls will like /basic-computer-skills/etc rather than having variantCode "-1" or "-2" at the end
  getExpectedUrlForModule(module: AssessmentModule) {
    return module.topic.slug;
  }

  onRouterEvents(routerValue) {
    if (routerValue instanceof NavigationEnd) {
      this.updateLessonShown();
    }
  }

  /**
   * Update lesson when navigating amongst them.
   */
  updateLessonShown() {
    setTimeout(() => {
      this.lesson = this.navigation.getLessonForCurrentComponent();
    }, 1);
  }
}
