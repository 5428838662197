import {Injectable, Inject} from "@angular/core";
import {IAppConfig, APP_CONFIG} from "../../app.config";
import {LoggerService} from "../services/logger.service";
import {each, keys} from "lodash";
import {ToastrService} from "ngx-toastr";

/**
 * Cf ~bootstrap/scss/_variables.scss line 195
 *
 * Bootstrap grid breakpoints and their min width.
 *
 * @type [string, number][]
 */
export const bootstrapGridBreakpoints = [
  ['xs', 0],
  ['sm', 576],
  ['md', 768],
  ['lg', 992],
  ['xl', 1200]
];

export const bootstrapGridKeys = keys(bootstrapGridBreakpoints);

/**
 * Add event listener for media-query changes in x-browser-compatible way.
 *
 * https://stackoverflow.com/a/60000747/4185989
 *
 * @param query
 * @param handler
 */
function addCrossBrowserMediaEventListener(query: MediaQueryList, handler: MediaQueryListListener) {
  try {
    // Chrome & Firefox
    (<any>query).addEventListener('change', handler);
  } catch (e1) {
    try {
      // Safari
      query.addListener(handler);
    } catch (e2) {
      console.error(e2);
    }
  }
}

/**
 * JS-based media queries. Helpful for inside Typescript or if using CSS-based media queries alone would result in
 * taxing template computations (unused complex components).
 *
 * Ref https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
 */
@Injectable()
export class MediaQueryService {

  /**
   * Object that stores boolean value for whether window is at or above given breakpoints, e.g.
   *
   * `this.mediaBreakpointUp.md` will return true/false if current breakpoint is at or above `md`
   */
  public mediaBreakpointUp: any = {};

  /**
   * Object that stores boolean value for whether window is at or below given breakpoints, e.g.
   *
   * `this.mediaBreakpointDown.md` will return true/false if current breakpoint is at or below `md`
   */
  public mediaBreakpointDown: any = {};

  debug: boolean = this.appConfig.debug;

  constructor(
    protected loggerService: LoggerService,
    @Inject(APP_CONFIG) protected appConfig: IAppConfig,
    protected toastr: ToastrService,
  ) {
    this.init();
  }

  init() {
    each(bootstrapGridBreakpoints, this.initBreakpoints.bind(this));
  }

  /**
   * Get initial value upon page load for queries, then listen for changes.
   *
   * @param breakpointData
   * @param index
   */
  initBreakpoints(breakpointData: [string, number], index) {
    const key = breakpointData[0];
    const breakpointPixels = breakpointData[1];
    const nextBreakpointPixels = bootstrapGridBreakpoints[index + 1] ? bootstrapGridBreakpoints[index + 1][1] : 999999;

    const minWidthQueryString: string = `(min-width: ${breakpointPixels}px)`;
    const maxWidthQueryString: string = `(max-width: ${<number>nextBreakpointPixels - .02}px)`;

    const minWidthQuery: MediaQueryList = window.matchMedia(minWidthQueryString);
    const maxWidthQuery: MediaQueryList = window.matchMedia(maxWidthQueryString);

    // set init
    this.mediaBreakpointUp[key] = minWidthQuery.matches;
    this.mediaBreakpointDown[key] = maxWidthQuery.matches;

    // listen for breakpoint changes
    addCrossBrowserMediaEventListener(minWidthQuery, (e) => {
      this.mediaBreakpointUp[key] = e.matches;
    });

    addCrossBrowserMediaEventListener(maxWidthQuery, (e) => {
      this.mediaBreakpointDown[key] = e.matches;
    });

    if (this.debug) {
      const atBreakpointQuery: MediaQueryList = window.matchMedia(`${minWidthQueryString} and ${maxWidthQueryString}`);

      // show toastr whenever breakpoint changes
      addCrossBrowserMediaEventListener(atBreakpointQuery, (e) => {
        if (e.matches) {
          this.toastr.info(key, null, {
            timeOut: 1000
          })
        }
      });
    }

    // @todo if need be, make corresponding mediaBreakpointBetween, mediaBreakpointOnly
  }

  screenShort() {
    return !window.matchMedia(`(min-height: ${this.appConfig.minWindowHeightForNormalView}px)`).matches;
  }

}
