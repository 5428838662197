import {Injectable, Inject} from "@angular/core";
import {IAppConfig, APP_CONFIG} from "../../app.config";
import {LoggerService} from "../services/logger.service";

/**
 * Service to provide logic for decisions based on the context assessment is taken in,
 * e.g. public vs sponsor. Previously would just rely on appConfig values directly, but
 * abstracting it into this service instead so that the methdology of decisions is configurable
 * from a central location if moving away from appConfig environment settings and instead
 * had a shared-host codebase accessed w/different parameters, for example, to distinguish public
 * from sponsor users.
 */
@Injectable()
export class EnvironmentService {
  constructor(
    protected loggerService: LoggerService,
    @Inject(APP_CONFIG) protected appConfig: IAppConfig
  ) {}

  /**
   * Whether taking an assessment requires name of user.
   *
   * @todo long-term consider user ID
   */
  requiresIdentifiableUser() {
    return this.appConfig.isSponsorVersion;
  }
}
