import {Component, OnInit, Input, HostBinding} from '@angular/core';
import {FadeInOut, FadeOut} from "../../animations";
import {BaseSlideWrapperComponent} from "../base-slide-wrapper.component";

/**
 * Wrapper for NSOL Try-It slides. Has Try-It icon and title at top.
 */
@Component({
  selector: 'practice-slide-wrapper',
  templateUrl: './practice-slide-wrapper.component.html',
  styleUrls: ['./practice-slide-wrapper.component.scss'],
  animations: [FadeInOut(500), FadeOut(250)]
})
export class PracticeSlideWrapperComponent extends BaseSlideWrapperComponent {
}
