import {Component, OnInit} from '@angular/core';
import {BaseTaskSlideComponent} from "./base-task-slide.component";
import {find, remove, clone, forEach, sortBy} from "lodash";

@Component({
  templateUrl: './base-task-slide.component.html'
})
export class BaseTaskRecipeDragAndDropMatchingSlide extends BaseTaskSlideComponent {
  showNextButton: boolean = true;

  /**
   * Value of elements to be drag-and-dropped
   *
   * @type {{id: string, label: string, order: number}[]}
   */
  dragOptions: any[];

  /**
   * Value of drop zones
   * @type {{id: string, label: string, content: null}[]}
   */
  dropZones: any[];

  emptyDropZones: any[] = [];
  showAccessibleDropZonesForOption: any = {};

  ngOnInit() {
    super.ngOnInit();

    // set order of dragOptions programmatically in case user clicks Reset; want options to show in good order
    forEach(this.dragOptions, (dragOption, index) => {
      dragOption.order = index;
    });

    forEach(this.dropZones, (dropZone) => {
      dropZone.content = null;
    });

    this.resetDragAndDrop();
  }

  setFieldWithValue(dropZoneId, dragOptionId) {
    const dropZone = find(this.dropZones, {id: dropZoneId});
    const dragOption = find(this.dragOptions, {id: dragOptionId});

    this.markDropZoneAsUnavailable(dropZone);
    this.markOptionAsUsed(dragOption);
    this.setDropZoneContent(dropZone, dragOption);
  }

  sendOptionToDropZone(dragOption, dropZone, event) {
    event.preventDefault();

    this.setDropZoneContent(dropZone, dragOption);
    this.markDropZoneAsUnavailable(dropZone);
    this.markOptionAsUsed(dragOption);
  }

  markOptionAsUsed(dragOption) {
    remove(this.dragOptions, (option) => option.id === dragOption.id);
  }

  markDropZoneAsUnavailable(dropZone) {
    remove(this.emptyDropZones, (zone) => zone.id === dropZone.id);
  }

  setDropZoneContent(dropZone, dragOption) {
    // if user overrides existing content in a dropzone, make that existing content draggable again
    if (dropZone.content) {
      this.reAddDragOption(dropZone.content);
    }

    dropZone.content = dragOption;
  }

  toggleAccessibleDropZonesMenu(dragOption, event) {
    event.preventDefault();

    this.showAccessibleDropZonesForOption[dragOption.id] = !this.showAccessibleDropZonesForOption[dragOption.id];
  }

  confirmReset() {
    if (!window.confirm('Are you sure you want to start the question again?')) {
      return;
    }

    this.resetDragAndDrop();
  }

  resetDragAndDrop() {

    this.emptyDropZones = clone(this.dropZones);

    forEach(this.emptyDropZones, (dropZone) => {
      this.showAccessibleDropZonesForOption[dropZone.id] = false;
    });

    forEach(this.dropZones, (dropZone) => {
      if (dropZone.content) {
        this.reAddDragOption(dropZone.content);
      }

      dropZone.content = null;
    });
  }

  reAddDragOption(dragOption) {
    this.dragOptions.push(dragOption);
    this.dragOptions = sortBy(this.dragOptions, 'order');
  }

  nextIsDisabled() {
    return this.emptyDropZones.length > 0;
  }

  handleClickNext() {
    if (this.emptyDropZones.length > 0) {
      this.toastr.error(this.translocoService.translate('common.completeSlidesOptionsBeforeContinuing'));

      return false;
    }

    this.responseCollectionService.addResponse(
      this.constructor,
      this.navigationService.getTaskForSlideComponentInstance(this),
      this.correctAnswersSelected()
    )
  }

  correctAnswersSelected() {
    let allCorrect = true;

    forEach(this.dropZones, (dropZone) => {
      const dropZoneHasNoContent = !dropZone.content;

      if (dropZoneHasNoContent) {
        allCorrect = false;
        return false; // break
      }

      const dropZoneContentIncorrect = dropZone.content.id !== dropZone.id;

      if (dropZoneContentIncorrect) {
        allCorrect = false;
        return false; // break
      }
    });

    return allCorrect;
  }
}
