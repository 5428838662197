import { Pipe, PipeTransform } from '@angular/core';
import { L10nDateFormatPipe } from './l10n-date-format.pipe';

/**
 * Format a time for the active transloco language.
 * 
 *    STRING | l10nTimeFormat : [OPTIONS]
 *  
 * Options documentation: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 *    
 * Examples with time = '2:20 PM':
 * active language 'en'
 * {{ time | l10nTimeFormat : { hour: 'numeric', minute: 'numeric' } }} 
 * 2:20 PM
 * 
 * active language 'es_419'
 * {{ time | l10nTimeFormat : { hour: 'numeric', minute: 'numeric' } }} 
 * 14:20
 * 
 */

@Pipe({
  name: 'l10nTimeFormat'
})
export class L10nTimeFormatPipe implements PipeTransform {
  
  constructor(protected l10nDateFormatPipe: L10nDateFormatPipe,) {
  }

  transform(time: string, options?: any): string {
    const date = new Date(0);
    const am = new RegExp('[aA]').test(time);
    const pm = new RegExp('[pP]').test(time);
    const digitGroups = time.match(/[0-9]+/g);
    let hours = parseInt(digitGroups[0]);
    const minutes = parseInt(digitGroups[1]);
    
    if(am && hours == 12){
      hours = 0;
    } else if (pm && hours < 12){
      hours += 12;
    }

    date.setHours(hours, minutes);

    return this.l10nDateFormatPipe.transform(date, options);
  }

}
 



