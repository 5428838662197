import {Resolve, ActivatedRouteSnapshot, Router} from "@angular/router";
import {Injectable, Inject} from "@angular/core";
import {CustomDataStore} from "../../data-store/custom-data-store.service";
import {IAppConfig, APP_CONFIG} from "../../../app.config";
import {TranslocoService, Translation} from "@ngneat/transloco";
import {L10nNode} from "../../i18n/l10n-node";
import {AssessmentModule} from "northstar-foundation";
import {LoggerService} from "../../services/logger.service";
import {find} from "lodash";
import {forkJoin, Observable, of} from "rxjs"
import {MocksForDevResolve} from "../mocks-for-dev.resolve";

@Injectable()
export class L10nForAssessmentResolve implements Resolve<any> {

  constructor(
    protected router: Router,
    @Inject(APP_CONFIG) protected appConfig: IAppConfig,
    protected dataStore: CustomDataStore,
    protected translocoService: TranslocoService,
    protected loggerService: LoggerService,
    protected mocksForDevResolve: MocksForDevResolve,
  ) {
  }

  resolve(route:ActivatedRouteSnapshot): Observable<Translation[]>  {

    const mocksComplete = this.mocksForDevResolve.resolve(route).subscribe();

    const module = find(this.dataStore.filter('module', {where: {variantCode: {'>': 0}}}), (module: AssessmentModule) => {
      return route.url[0].path.indexOf(module.getResolvedModulePath()) > -1;
    });

    if (!module) {
      this.loggerService.warn(['No module found to fetch translaton for']);
      return of([]);
    }

    return forkJoin([
      this.translocoService.selectTranslation(this.translocoService.getActiveLang()),

      // below shouldn't be necessary to lazy load modules' translation files per transloco docs at
      // https://ngneat.github.io/transloco/docs/scope-configuration but consistently got the first slide's content
      // not translated in development env, so loading here explicitly instead
      this.translocoService.selectTranslation(L10nNode.pathForSelectModule(module, 'assessment') + this.translocoService.getActiveLang())
    ]);
  }
}
