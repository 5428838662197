import {Injectable, Inject, Component} from "@angular/core";
import {IModuleTask, APP_CONFIG, IAppConfig} from "../../app.config";
import {ResponseCollectionService} from "./response-collection.service";
import {Router, ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {ResponseModel} from "./response.model";
import {LoggerService} from "../services/logger.service";
import {sharedSlidesSlugs} from "../../shared/slides/shared-slides-slugs.config";
import {ToastrService} from 'ngx-toastr';
import {BootstrapDataService} from "../bootstrap/bootstrap-data.service";
import {LoadingOverlayService} from "./loading-overlay.service";
import {AssessmentModule, ModulePracticeLesson, ModulePracticeSection, ModulePracticeSectionReview} from "northstar-foundation";
import {isArray, each, find, lowerFirst, upperFirst, reduce, sortBy, findIndex, kebabCase, camelCase, assign} from "lodash";
import {CustomDataStore} from "../data-store/custom-data-store.service";
import {SessionService} from "../services/session.service";
import {IPracticeLessonLocalMeta} from "../../shared/interfaces/practice.interfaces";
import {L10nNode} from "../i18n/l10n-node";
import {translate, TranslocoService} from "@ngneat/transloco";
import {getKebabCoreNameForComponent, getCoreNameForComponent} from "../../shared/slides/slide-helper";
import {GoogleTagManagerService} from "northstar-foundation/angular";

export interface SlideNavLinkStatus {
  visited: boolean,
  complete: boolean,
  dontKnow: boolean
}

// [component, optional-task] pairs
export interface SlideToTaskSequence extends Array<any> {}

export interface GroupedSlideToTaskSequenceMeta {
  slug?: string;
  id?: number;
  parentSectionSlug?: string;
}

export interface GroupedSlideToTaskSequences {
  meta: GroupedSlideToTaskSequenceMeta,
  content: SlideToTaskSequence[]
}

declare var trackJs: any;

@Injectable()
export class SlideNavigationService {
  currentTaskNum: number|null;
  numTasks: number;

  module: AssessmentModule;

  private responseSubscription: Subscription;
  public slideGroups: GroupedSlideToTaskSequences[]; // public due to other-slides-navigation component
  public slides: SlideToTaskSequence[]|GroupedSlideToTaskSequences[]; // `slides` property may hold differently structured data
  private components: any[] = [];
  private tasks: any[] = [];
  private slideNavLinkStatuses: SlideNavLinkStatus[] = [];
  private assessmentComplete: boolean = false;

  /**
   * Hash of components indexed by tasks' `id`.
   */
  private taskToComponentHash: Object = {};

  /**
   * Hash of tasks indexed by components' `nsComponentId`. Previously used JS's native `name`
   * property that equaled the function name, but this is lost in Angular AOT compilation since
   * functions get turned into short single-character names. Found that they equal one another
   * in their `name` after compilation as well, so messed up this hash.
   *
   * @type {{}}
   */
  private componentToTaskHash: Object = {};

  /**
   * Hash of readable task numbers indexed by tasks' `id`.
   * @type {{}}
   */
  private taskToTaskNumHash: Object = {};

  constructor(
    protected responseCollectionService: ResponseCollectionService,
    protected router: Router,
    protected loggerService: LoggerService,
    protected toastr: ToastrService,
    protected bootstrapData: BootstrapDataService,
    protected loadingOverlayService: LoadingOverlayService,
    protected dataStore: CustomDataStore,
    protected sessionService: SessionService,
    @Inject(APP_CONFIG) public appConfig: IAppConfig,
    protected translocoService: TranslocoService,
    protected gtmService: GoogleTagManagerService,
  ) {
    this.responseSubscription = this.responseCollectionService.response$.subscribe(this.onTaskResponseComplete.bind(this));
  }

  setModule(module) {
    this.module = module;

    return this;
  }


  /**
   * Set the slides that make up the assessment's content, which will then impact
   * the navigation menu as well as the titles shown.
   *
   * May not be component-task pairs only
   * e.g. overview slide without a task may appear in the menu.
   *
   * @param slidesCollection Array of [component, optional-task] pairs
   *
   *               OR
   *
   *               Array of objects:
   *
   *               {
   *                   sectionTitle: string,
   *                   content:
   *               }
   */
  populateSlides(slidesCollection: SlideToTaskSequence[]|GroupedSlideToTaskSequences[]) {
    this.slides = slidesCollection;

    // reset in case user navigated Back in browser and component is getting reloaded,
    // to avoid a 20-question assessment showing there's 40 questions
    this.tasks = [];
    this.components = [];
    this.slideGroups = [];

    const slidesAreSectioned = !isArray(slidesCollection[0]); // i.e. dealing with {sectionTitle: string, content: [][]}[]

    if (slidesAreSectioned) {
      let slideContentToPopulateL10nJson = [];

      this.slideGroups = <GroupedSlideToTaskSequences[]>slidesCollection;

      each(<GroupedSlideToTaskSequences[]>slidesCollection, (singleSlideCollection: GroupedSlideToTaskSequences) => {

        // cf ResponseCollectionService.getNamespaceForSectionReview()
        const sectionNamespace = (<IPracticeLessonLocalMeta>singleSlideCollection.meta).parentSectionSlug;
        const slideCollectionNamespace = singleSlideCollection.meta.slug ? singleSlideCollection.meta.slug : 'review';

        this.populateSlidesCollectionContent(singleSlideCollection.content, `${sectionNamespace}-${slideCollectionNamespace}`);

        if (this.appConfig.debug) {
          slideContentToPopulateL10nJson.push(...singleSlideCollection.content);
        }
      });

      if (this.appConfig.debug) {
        this.printJsonForL10nFile(slideContentToPopulateL10nJson);
        this.printFilenamesWithNarration(slideContentToPopulateL10nJson);
      }
    } else {
      this.populateSlidesCollectionContent(slidesCollection);

      if (this.appConfig.debug) {
        // enable this when doing i18n for assessments and NSOL modules to quickly populate the right structure
        // for the l10n JSON
        this.printJsonForL10nFile(slidesCollection);
        this.printFilenamesWithNarration(slidesCollection);
      }
    }

    this.numTasks = this.tasks.length;

    return this;
  }

  private populateSlidesCollectionContent(slidesCollection, namespace: string = null) {
    let taskNumIterator = 1;
    const length = slidesCollection.length;

    for (let i = 0; i < length; i++) {

      let component = slidesCollection[i][0];
      this.components.push(component);

      // for nav menu, track user's interaction w/the component
      this.slideNavLinkStatuses[component.nsComponentId] = {
        visited: false,
        complete: false,
        dontKnow: false
      };

      let hasTask = slidesCollection[i].length > 1;

      // component may be an overview slide only, not a task slide
      if (!hasTask) {
        continue;
      }

      let task = slidesCollection[i][1];
      this.tasks.push(task);
      this.taskToComponentHash[task.id] = component;

      this.taskToTaskNumHash[task.id] = taskNumIterator;
      this.responseCollectionService.registerTask(task, taskNumIterator, namespace);
      taskNumIterator++;

      this.componentToTaskHash[component.nsComponentId] = task;
    }
  }

  /**
   * Convenience method to output the JSON that ought to be pasted into a l10n file for easier population. Pulls
   * captions from old l10n file and tasks' title and summary from *.tasks.ts for each assessment and module.
   *
   * @param slidesCollection
   */
  printJsonForL10nFile(slidesCollection) {
    return;

    let l10nObjectCollection = reduce(slidesCollection, (l10nObject, slide) => {
      let component = slide[0];
      let componentId = component.nsComponentId;
      let hasTask = slide.length > 1;
      let key = camelCase(getCoreNameForComponent(componentId));

      l10nObject[key] = {
        content: {
        }
      };

      // check in old i18n/en.json file for captions that correspond to the component
      // @todo l10n note that this check won't work for more complicated slides' getAudioPath() settings such as
      // including dates in the captions, so would need to manually copy those over from i18n/en.json
      // to the correct en.json file that's particular to the assessment or NSOL module
      let slugForModule = this.module.softwareVersion.isBase ? this.module.topic.slug : `${this.module.topic.slug}-${this.module.softwareVersion.slug}`;

      let slugGuesstimateForAudioCaption = `${camelCase(slugForModule)}.${key}`;
      let slugGuesstimate2ForAudioCaption = `${camelCase(slugForModule)}.practice.${key}`;

      if (this.translocoService.translate(slugGuesstimateForAudioCaption)) {
        l10nObject[key].captions = this.translocoService.translate(slugGuesstimateForAudioCaption);
      } else if (this.translocoService.translate(slugGuesstimate2ForAudioCaption)) {
        l10nObject[key].captions = this.translocoService.translate(slugGuesstimate2ForAudioCaption);
      }

      if (hasTask) {
        let task = slide[1];

        assign(l10nObject[key], {
          title: task.title || '',
          summary: task.summary || '',
        });
      }

      return l10nObject;
    }, {});

    console.log(JSON.stringify(l10nObjectCollection));
  }

  /**
   * Print filename and caption for each slide.
   *
   * @param slidesCollection
   */
  printFilenamesWithNarration(slidesCollection) {
    return;
    const htmlTagsRegEx = /<\/?[^>]+(>|$)/g;
    const slidesAreSectioned = !isArray(slidesCollection[0]); // i.e. dealing with {sectionTitle: string, content: [][]}[]

    let finalL10nScript = reduce(slidesCollection, (l10nScript, slide) => {
      let component = slide[0];
      let componentId = component.nsComponentId;
      let hasTask = slide.length > 1;

      // instruction slides have their slug statically defined
      let filenameKey = hasTask ? lowerFirst(getCoreNameForComponent(componentId)) : component.slug;

      // keys used for l10n entries in json files differ from some of the filename keys above
      let l10nKey = lowerFirst(getCoreNameForComponent(componentId));

      // skip slides that are re-used across NSOL
      if (filenameKey === 'practiceSectionReview'
        || filenameKey === 'review'
      ) {
        return l10nScript;
      }

      let scriptForSlide = [kebabCase(filenameKey) + '.mp3'];
      let captionsContent = '';

      // check in old i18n/en.json file for captions that correspond to the component
      // @todo l10n note that this check won't work for more complicated slides' getAudioPath() settings such as
      // including dates in the captions, so would need to manually copy those over from i18n/en.json
      // to the correct en.json file that's particular to the assessment or NSOL module
      let slugForModule = `${this.module.topic.slug}-${this.module.softwareVersion.slug}`;
      slugForModule = camelCase(slugForModule);

      let slugGuesstimatesForAudioCaption = [
        // assessment q's and practice-review-qs
        `${slugForModule}V${this.module.variantCode}.slides.${l10nKey}.captions`,
        `${slugForModule}V${this.module.variantCode}.slides.${l10nKey}.title`,

        // practice try-its caption, overrides tasks' title (below) for narration
        `${slugForModule}Practice.slides.${l10nKey}.captions`,
        `${slugForModule}Practice.slides.${l10nKey}.title`,
        `${slugForModule}Practice.slides.${l10nKey}.content.descr`, // some instruction slides have a general 'descr' key
      ];

      each(slugGuesstimatesForAudioCaption, guessPath => {
        if (this.translocoService.translate(guessPath)) {
          captionsContent = this.translocoService.translate(guessPath);
          return false; // break
        }
      });

      // https://stackoverflow.com/a/5002161/4185989
      captionsContent = captionsContent.replace(htmlTagsRegEx, '');

      scriptForSlide.push(captionsContent);

      l10nScript.push(scriptForSlide);

      return l10nScript;
    }, []);

    // take array and make into one large string for console
    let printableScript = "";

    each(finalL10nScript, slideScript => {
      printableScript += `--------------\n${slideScript[0]}\n--------------\n\n${slideScript[1]}\n\n`;
    });

    console.log(printableScript);
  }

  /**
   * Return whether assessment has been completed.
   *
   * @returns {boolean}
   */
  getAssessmentComplete() {
    return this.assessmentComplete;
  }

  getTaskForSlideComponent(component) {
    return this.componentToTaskHash[component.nsComponentId] || false;
  }

  getTaskForSlideComponentInstance(componentInstance) {
    return this.componentToTaskHash[componentInstance.constructor.nsComponentId] || false;
  }

  getSlideComponentForTask(task: IModuleTask) {
    return this.taskToComponentHash[task.id] || false;
  }

  viewingSlideForTask(task: IModuleTask) {
    return this.getCurrentComponent() === this.getSlideComponentForTask(task);
  }

  getReadableTaskNum(task: IModuleTask) {
    return this.taskToTaskNumHash[task.id];
  }

  getCurrentComponent() {
    if (this.slideGroups.length <= 0) {
      return this.getCurrentComponentForSlides(<SlideToTaskSequence[]>this.slides);
    }

    let component = null;

    each(this.slideGroups, (slideGroup: GroupedSlideToTaskSequences) => {
      let componentOrFalse = this.getCurrentComponentForSlides(slideGroup.content);

      if (!!componentOrFalse) {
        component = componentOrFalse;
        return false;
      }
    });

    return component;
  }

  getCurrentComponentForSlides(slides: SlideToTaskSequence[]) {
    const currentSegment = this.getCurrentComponentSegment();
    const slidesLength = slides.length;

    for (let i = 0; i < slidesLength; i++) {
      let slideComponent = slides[i][0];

      // access to a slug differs between tasks and overview slides, tasks having them
      // as obj literal properties to themselves, whereas overview slides have them in the component class
      const slideFoundAsTask = slideComponent.isTaskSlideComponent && slides[i][1]['slug'] === currentSegment;
      const slideFoundAsOverview = slideComponent.isOverviewSlideComponent && slideComponent['slug'] === currentSegment;
      const slideFoundAsSectionReviewOverview = slideComponent.isSectionReviewOverview && slideComponent['slug'] === currentSegment;

      if (slideFoundAsTask || slideFoundAsOverview || slideFoundAsSectionReviewOverview) {
        return slideComponent;
      }
    }

    return false;
  }

  protected getComponentSegment(slideComponent) {
    if (slideComponent.isTaskSlideComponent) {
      return this.componentToTaskHash[slideComponent.nsComponentId]['slug'];
    }

    if (slideComponent.isOverviewSlideComponent) {
      return slideComponent['slug'];
    }

    return false;
  }

  getCurrentComponentSegment() {
    const segments = this.router.url.split('/');
    const lastSegment = segments[segments.length - 1];
    const lastSegmentNoQueryParams = lastSegment.split('?')[0]; // e.g. in case of ?via=require_login

    return lastSegmentNoQueryParams;
  }

  /**
   * Keep track of current task number for sake of the progress bar.
   *
   * @returns {number|null}
   */
  updateCurrentTaskNum() {
    const currentSegment = this.getCurrentComponentSegment();
    const slidesLength = this.slides.length;
    const component = this.getCurrentComponent();
    const task = this.getTaskForSlideComponent(component);

    // simple case: currently viewing a slide that is a task
    if (task) {
      this.currentTaskNum = this.taskToTaskNumHash[task.id];
      return this.currentTaskNum;
    }

    // more complicated case: currently viewing an overview slide;
    // figure out the last task # that is relevant
    let taskNumIterator = 0;

    for (let i = 0; i < slidesLength; i++) {
      let component = this.slides[i][0];
      let componentTask = this.getTaskForSlideComponent(component);

      if (currentSegment === this.getSlugForComponent(component)) {
        if (taskNumIterator === 0) {
          taskNumIterator = 1;
        }

        this.currentTaskNum = taskNumIterator;
        return this.currentTaskNum;
      }

      if (componentTask) {
        taskNumIterator++;
      }
    }
  }

  goNext(activatedRoute:ActivatedRoute) {
    // ASSESSMENTS
    if (this.slideGroups.length <= 0) {
      this.goNextForSlideGroup(activatedRoute, <SlideToTaskSequence[]>this.slides);
      return;
    }

    // LMS INSTRUCTION / PRACTICE

    // section title slides not logically structured in `slidesToTasks` values due to historical development of hierarchy,
    // but can figure out which section we're in via URL and forward user to first lesson
    if (this.viewingSectionLevel()) {
      const urlParts = this.router.url.split('/');
      const sectionSlug = urlParts[urlParts.length - 1];
      const section = <ModulePracticeSection>this.dataStore.filter('module_practice_section', {
        slug: sectionSlug,
        moduleId: this.module.id
      })[0];
      this.goToLesson(section.getLessons()[0]);
      return;
    }

    // non-section-title slides, i.e. all other slides in LMS
    this.goNextForSlideGroup(activatedRoute, (<GroupedSlideToTaskSequences>this.getSlideGroupForCurrentComponent()).content);
  }

  /**
   * Convenience method rather than needing to specify which component to go to.
   *
   * @todo need to take into consideration the most recent response? it's possible there'd
   * be variants of the task based on how previous task was accomplished
   */
  goNextForSlideGroup(activatedRoute:ActivatedRoute, slides: SlideToTaskSequence[]) {
    // after viewing last slide, should go to submission confirmation;
    // or, after submitting a reviewed question, should go to the submission confirmation slide
    if (this.viewingLastNavigationSlide(slides) || this.allSlidesVisited() || this.viewingSectionReviewOutcome()) {
      // assessments
      if (this.slideGroups.length <= 0) {
        this.goToSubmissionConfirmationSlide(activatedRoute);
        return;
      }

      // viewing instruction/practice as opposed to regular assessment

      if (this.viewingSectionReview() && !this.viewingSectionReviewOutcome()) {
        // show results of section review
        // (note that if already viewing review outcome, proceed down to goToNextSlideGroup)
        if (this.sessionService.userIsLoggedIn()) {
          this.goToSectionReviewResultsSlide(activatedRoute);
          return;
        }
      }

      // this.goToSlideAfterCurrentLesson(activatedRoute);
      this.goToNextSlideGroup(activatedRoute);
      return;
    }

    const currentComponent = this.getCurrentComponent();
    const slidesLength = slides.length;

    for (let i = 0; i < slidesLength; i++) {
      if (currentComponent.nsComponentId === slides[i][0]['nsComponentId']) {
        const currentSlideNum = i + 1;
        const nextSlideNum = currentSlideNum + 1;
        this.goToSlideNum(slides, nextSlideNum, activatedRoute);
      }
    }
  }




  goToSlideComponent(component, activatedRoute: ActivatedRoute) {
    const route = '../' + this.getComponentSegment(component);

    this.router.navigate([route], {relativeTo: activatedRoute});
  }

  goToSlideComponentForLesson(component, lesson: ModulePracticeLesson) {
    let parts = <string[]>lesson.getRouterLinkParts();
    parts.pop(); // don't want the lesson slug itself as part of it, since practice URLs take syntax of /{section}/{slide}
    parts.push(this.getComponentSegment(component));

    this.router.navigate(parts);
  }

  goToSection(section: ModulePracticeSection) {
    this.router.navigate(section.getRouterLinkParts());
  }

  goToFirstSection() {

    const moduleSections = this.dataStore.filter('module_practice_section', {
      moduleId: this.module.id
    });

    const firstSection: ModulePracticeSection = sortBy(moduleSections, ['order'])[0];

    this.goToSection(firstSection);
  }

  goToLesson(lesson: ModulePracticeLesson) {
    this.router.navigate(lesson.getRouterLinkParts());
  }

  goToSectionReview(section: ModulePracticeSection) {
    let sectionReviewRouterParts = section.getRouterLinkParts();
    sectionReviewRouterParts.push('review');

    this.router.navigate(sectionReviewRouterParts);
  }

  goToSlideNum(slides: SlideToTaskSequence[], slideNum:number, activatedRoute:ActivatedRoute) {
    if (slideNum > slides.length) {
      throw Error('Erroneous slide number.');
    }

    const index = slideNum - 1;
    const component = slides[index][0];
    const route = '../' + this.getComponentSegment(component);

    this.router.navigate([route], {relativeTo: activatedRoute});
  }

  goToSubmissionConfirmationSlide(activatedRoute:ActivatedRoute) {
    const confirmationRoute = '../' + sharedSlidesSlugs.submissionConfirmation;
    this.router.navigate([confirmationRoute], {relativeTo: activatedRoute});
  }

  goToSectionReviewResultsSlide(activatedRoute: ActivatedRoute) {
    const resultsRoute = '../' + sharedSlidesSlugs.sectionReviewResult;
    this.router.navigate([resultsRoute], {relativeTo: activatedRoute});
  }

  getSlideGroupForLesson(lesson: ModulePracticeLesson) {
    if (this.slideGroups.length <= 0) {
      return;
    }

    if (!lesson) {
      return false;
    }

    return find(this.slideGroups, (slideGroup: GroupedSlideToTaskSequences) => {
      return slideGroup.meta.slug === lesson.slug && lesson.module.id === this.module.id
    });
  }

  /**
   * Get section review slide group. Note it's based on finding last lesson slide group, and adding 1 to its index,
   * since there's otherwise no currently programmed away of identifying a section-review slide group's association w/a section.
   *
   * @param section
   * @returns {any}
   */
  getSlideGroupForSectionReview(section: ModulePracticeSection) {
    if (this.slideGroups.length <= 0) {
      return;
    }

    const lastLessonSlideGroupForSection = this.getSlideGroupForLesson(<ModulePracticeLesson>(<ModulePracticeLesson[]>section.getLessons()).slice(-1)[0]);

    if (!lastLessonSlideGroupForSection) {
      // prob in dev mode building out the module - return here to avoid errors when expanding nav menu
      return;
    }

    const indexOfLastLessonSlideGroup = findIndex(this.slideGroups, (slideGroup: GroupedSlideToTaskSequences) => {
      return slideGroup.meta.slug === lastLessonSlideGroupForSection.meta.slug;
    });

    return this.slideGroups[indexOfLastLessonSlideGroup + 1];
  }

  goToResultsSlide(activatedRoute:ActivatedRoute) {
    this.loadingOverlayService.show();
    this.assessmentComplete = true;

    const resultsRoute = '../' + sharedSlidesSlugs.assessmentResults;
    this.router.navigate([resultsRoute], {relativeTo: activatedRoute});
  }

  goToNextSlideGroup(activatedRoute: ActivatedRoute) {
    const currentSlideGroup = this.getSlideGroupForCurrentComponent();

    this.loggerService.log(['currentSlideGroup', currentSlideGroup]);

    each(this.slideGroups, (slideGroup: GroupedSlideToTaskSequences, index: number) => {
      if (slideGroup === currentSlideGroup && typeof this.slideGroups[index + 1] !== 'undefined') {
        const currentSection = <ModulePracticeSection>this.getCurrentSection();
        const nextSlideGroup = this.slideGroups[index + 1];
        const nextSlideGroupMeta = nextSlideGroup.meta;
        const nextLesson = <ModulePracticeLesson>this.dataStore.filter('module_practice_lesson', {
          slug: nextSlideGroupMeta.slug,
          moduleId: this.module.id
        })[0];

        // if !nextLesson then next slide group must be a section review
        if (!nextLesson) {
          // section reviews' entry page may be shown to logged-in or logged-out users, so no need to check status here
          this.goToSectionReview(currentSection);
          return false;
        }

        if (this.sessionService.userIsLoggedIn()
          && currentSection.lessonsCompletedByUser(this.sessionService.user.id)
          && !this.viewingSectionReview()
          && !currentSection.review.completedByUser(this.sessionService.user.id, false) // if user has completed review, don't force them to return to it
        ) {
          // e.g. user just finished lessons out of order

          this.goToSectionReview(currentSection);
          return false;
        }

        if (this.viewingSectionReview()) {
          // e.g. viewing review outcome slide, Next should go to next section
          this.goToSection(nextLesson.section);
          return false;
        }

        // default expected behavior
        this.goToLesson(nextLesson);
        return false;


        // const slideGroupExistsAfterSectionReview = typeof this.slideGroups[index + 2] !== 'undefined';
        //
        // if (!slideGroupExistsAfterSectionReview) {
        //   // @todo try to avoid this case from ever happening by showing appropriate messaging on last slide of module when logged out?
        //   return false;
        // }
        //
        // const slideGroupAfterSectionReviewMeta = this.slideGroups[index + 2].meta;
        // const lessonAfterSectionReview = <ModulePracticeLesson>this.dataStore.filter('module_practice_lesson', {
        //   slug: slideGroupAfterSectionReviewMeta.slug,
        //   moduleId: this.module.id
        // })[0];
        //
        //
        // // don't want to launch right into next section's lessons; rather, show section overview page
        // this.goToSection(lessonAfterSectionReview.section);
        // return false;
      }
    });
  }

  onVisitSlideComponentInstance(componentInstance) {
    this.slideNavLinkStatuses[componentInstance.constructor.nsComponentId].visited = true;
  }

  onTaskResponseComplete(response: ResponseModel) {
    const task = response.getTask();
    const component = this.getSlideComponentForTask(task);
    const navLink = this.slideNavLinkStatuses[component.nsComponentId];

    if (response.variantIsDontKnow()) {
      navLink.complete = false;
      navLink.dontKnow = true;
      return;
    }

    navLink.complete = true;
    navLink.dontKnow = false;
  }

  slideComponentIsVisited(component) {
    return this.slideNavLinkStatuses[component.nsComponentId].visited;
  }

  slideComponentIsCompleted(component) {
    return this.slideNavLinkStatuses[component.nsComponentId].complete;
  }

  slideComponentMarkedDontKnow(component) {
    return this.slideNavLinkStatuses[component.nsComponentId].dontKnow;
  }

  lessonIsCompleted(lesson: ModulePracticeLesson) {
    const completions = this.dataStore.filter('module_practice_user_completion', {
      modulePracticeLessonId: lesson.id
    });

    return completions.length > 0;
  }

  lessonCurrentlyViewed(lesson: ModulePracticeLesson) {
    const currentSegment = this.getCurrentComponentSegment();
    const slideGroup = find(this.slideGroups, (slideGroup: GroupedSlideToTaskSequences) => {
      return slideGroup.meta.slug === lesson.slug;
    });

    // none found; possibly lesson is in DB but not programmed as a <IPracticeLessonLocalMeta> yet?
    if (!slideGroup) {
      return false;
    }

    let viewing = false;

    each(slideGroup.content, (slideToTaskSequences: SlideToTaskSequence[]) => {
      const componentSlug = this.getSlugForComponent(slideToTaskSequences[0]);

      if (currentSegment === componentSlug) {
        // user viewing one of the pages within the section
        viewing = true;
        return false;
      }
    });

    if (currentSegment === `${slideGroup.meta.slug}-review`) {
      // user just finished section
      viewing = true;
    }

    return viewing;
  }

  getSlugForComponent(component) {
    const taskOrFalse = this.getTaskForSlideComponent(component);

    if (taskOrFalse) {
      return taskOrFalse['slug'];
    }

    return component.slug;
  }

  slideComponentCurrentlyViewed(component) {
    const currentSegment = this.getCurrentComponentSegment();

    return this.getSlugForComponent(component) === currentSegment;
  }

  getNumIncompleteTasks() {
    const numTasks = this.tasks.length;
    let numIncompleteTasks = 0;

    // not using this.slideNavLinkStatuses here because that includes non-task slides
    for (let i = 0; i < numTasks; i++) {
      let responseForTask = this.responseCollectionService.getResponeForTask(this.tasks[i]);

      if (!responseForTask) {
        numIncompleteTasks++;
        continue;
      }

      if ((<ResponseModel>responseForTask).variantIsDontKnow()) {
        numIncompleteTasks++;
      }
    }

    return numIncompleteTasks;
  }

  /**
   * Return tasks that either don't have a response or have a I Don't Know response.
   *
   * @returns {Array}
   */
  getIncomplete(): {task: any, title: string}[] {
    const numTasks = this.tasks.length;
    let incomplete = [];

    for (let i = 0; i < numTasks; i++) {
      let task = this.tasks[i];
      let responseForTask = this.responseCollectionService.getResponeForTask(task);

      if (!responseForTask || (<ResponseModel>responseForTask).variantIsDontKnow()) {
        incomplete.push({
          task: task,
          title: this.translocoService.translate(this.getL10nNodeForComponent(this.getSlideComponentForTask(task).nsComponentId).getL10NPath('summary'))
        });
      }
    }

    return incomplete;
  }

  /**
   * Return # slides not yet visited.
   *
   * @returns {number}
   */
  getNumSlidesNotViewed() {
    let numSlidesNotViewed = 0;

    for (let key in this.slideNavLinkStatuses) {
      if (!this.slideNavLinkStatuses[key].visited) {
        numSlidesNotViewed++;
      }
    }

    return numSlidesNotViewed;
  }

  allSlidesVisited() {
    return this.getNumSlidesNotViewed() <= 0;
  }

  getCurrentTaskNum() {
    return this.currentTaskNum;
  }

  getNumTasks() {
    return this.tasks.length;
  }

  getNumTasksWithNoResponse() {
    const numTasks = this.tasks.length;
    let numTasksWithNoResponse = 0;

    // not using this.slideNavLinkStatuses here because that includes non-task slides
    for (let i = 0; i < numTasks; i++) {
      let responseForTask = this.responseCollectionService.getResponeForTask(this.tasks[i]);

      if (!responseForTask) {
        numTasksWithNoResponse++;
      }
    }

    return numTasksWithNoResponse;
  }

  protected viewingLastNavigationSlide(slides: SlideToTaskSequence[]) {
    const currentComponent = this.getCurrentComponent();
    const lastSlideComponent = slides[slides.length - 1][0];

    return currentComponent && this.getComponentSegment(currentComponent) === this.getComponentSegment(lastSlideComponent);
  }

  protected viewingSlideGroupReviewSlide(slides: SlideToTaskSequence[]) {
    if (this.slideGroups.length <= 0) {
      return false;
    }

    const slideGroup =  this.getSlideGroupForCurrentComponent();
    const reviewSlug = `${slideGroup.meta.slug}-review`;
    return this.getCurrentComponentSegment() === reviewSlug;
  }

  public getSlideGroupForCurrentComponent(): GroupedSlideToTaskSequences {
    const currentComponent = this.getCurrentComponent();
    const currentSection = <ModulePracticeSection>this.getCurrentSection();
    let currentSlideGroup;

    each(this.slideGroups, (slideGroup: GroupedSlideToTaskSequences) => {

      const slideGroupSection = <ModulePracticeSection>this.dataStore.filter('module_practice_section', {
        slug: slideGroup.meta.parentSectionSlug,
        moduleId: this.module.id
      })[0];

      const currentSectionIsSlideGroupSection = currentSection === slideGroupSection;

      if (!currentSectionIsSlideGroupSection) {
        // skip further testing for this slideGroup, since must not be the right one
        return;
      }

      // below need to test special cases that aren't part of the explicit slideGroup.content arrays

      // reviewing outcomes of user's practice slides for a lesson
      // const userViewingLessonReview = this.getCurrentComponentSegment() === `${slideGroup.meta.slug}-${sharedSlidesSlugs.practiceLessonReview}`;
      //
      // if (userViewingLessonReview) {
      //   currentSlideGroup = slideGroup;
      //   return false;
      // }

      // const userViewingSectionReviewOverview = this.getCurrentComponentSegment() === sharedSlidesSlugs.sectionReviewOverview;
      // const slideGroupIsSectionReview = slideGroup.meta.slug === sharedSlidesSlugs.sectionReviewOverview;
      // const viewingSectionReviewOutcome = this.viewingSectionReviewOutcome();

      // note: section-review slide groups don't have a meta slug defined in GroupedSlideToTaskSequences[]
      // since they're all the same and kept DRY, so can use that fact to determine if we're
      // iterating over such a slide group
      if (this.viewingSectionReview(slideGroupSection) && !slideGroup.meta.slug) {
        currentSlideGroup = slideGroup;
        return false;
      }

      each(slideGroup.content, (slide: SlideToTaskSequence) => {

        // currently on a practice slide
        if (slide[0] === currentComponent) {
          currentSlideGroup = slideGroup;
          return false;
        }
      });
    });

    return currentSlideGroup;
  }

  public getLessonForCurrentComponent(): ModulePracticeLesson|boolean {
    if (!this.slideGroups || this.slideGroups.length <= 0) {
      return false;
    }

    if (this.viewingSectionLevel()) {
      return false;
    }

    if (this.viewingSectionReview()) {
      return false;
    }

    const slideGroup = this.getSlideGroupForCurrentComponent();

    if (!slideGroup) {
      // e.g. intro slide to NSOL module, see Career Search Skills
      return false;
    }

    const matches = this.dataStore.filter('module_practice_lesson', {
      slug: slideGroup.meta.slug,
      module: this.module,
    });

    if (!matches.length) {
      return false;
    }

    return matches[0];
  }

  public viewingSectionLevel(section: ModulePracticeSection = null) {
    const slugSegmentsSeemToIndicateSectionLevel: boolean = this.router.url.split('/').length === 4; // e.g. ["", "basic-computer-skills", "practice", "controlling-the-computer"]

    if (!section) {
      return slugSegmentsSeemToIndicateSectionLevel;
    }

    return slugSegmentsSeemToIndicateSectionLevel && (<string[]>section.getRouterLinkParts()).join('/').slice(1) === this.router.url; // slice 1 because at beginning would have // after the join
  }

  public viewingSectionReview(section: ModulePracticeSection = null) {
    const segments = this.router.url.split('/');
    const viewingAnySectionReview = segments.length > 4 && segments[4] === 'review'; // e.g. ["", "basic-computer-skills", "practice", "controlling-the-computer", "review"]

    if (!section) {
      return viewingAnySectionReview;
    }

    return viewingAnySectionReview && segments[3] === section.slug;
  }

  public viewingSectionReviewOutcome(section: ModulePracticeSection = null) {
    const segments = this.router.url.split('/');
    const viewingAnySectionReviewOutcome = segments.length > 5 && segments[4] === 'review' && segments[5] === 'result'; // e.g. ["", "basic-computer-skills", "practice", "controlling-the-computer", "review", "result"]

    if (!section) {
      return viewingAnySectionReviewOutcome;
    }

    return viewingAnySectionReviewOutcome && segments[3] === section.slug;
  }

  public getCurrentSection(): ModulePracticeSection|boolean {
    const segments = this.router.url.split('/'); // e.g. ["", "basic-computer-skills", "practice", "controlling-the-computer"]

    const sections = this.dataStore.filter('module_practice_section', {
      slug: segments[3],
      moduleId: this.module.id
    });

    if (sections.length <= 0) {
      return false
    }

    return sections[0];
  }

  canActivateSlide(component): boolean {
    if (this.appConfig.debug) {
      return true;
    }

    if (this.assessmentComplete && component.slug !== sharedSlidesSlugs.assessmentResults) {
      const cannotReturnMsg = 'Cannot return to assessment after viewing results screen. Close this browser tab/window to start another module.';
      this.toastr.error(cannotReturnMsg);

      if (typeof trackJs !== 'undefined') {
        trackJs.track(cannotReturnMsg);
      }

      return false;
    }

    if (!this.bootstrapData.isBootstrapped()) {
      const cannotAccessDirectlyMsg = 'Cannot directly access this page.';
      this.toastr.error(cannotAccessDirectlyMsg);
      this.loggerService.log(window.location.href);

      if (typeof trackJs !== 'undefined') {
        trackJs.track(cannotAccessDirectlyMsg);
      }

      this.router.navigate(['/']);
      return false;
    }

    // if component is one of the navigable task or overview slides,
    // ensure any prior task has been completed
    if (component.isTaskSlideComponent || component.isOverviewSlideComponent) {
      return this.priorTaskForComponentHasResponse(component);
    }

    if (component.slug === sharedSlidesSlugs.submissionConfirmation && !this.tasks.length) {
      this.toastr.error(translate('common.tasksNotLoadedAndCompleted'));
      return false;
    }

    // if confirm-submission or results screen, ensure tasks exist (i.e. not viewing before bootstrap)
    // and that tasks have at least been responded to, even if just with I Don't Know;
    // above logic should have already handled this in typical browsing scenarios,
    // but below logic guards against person accessing via URL rather than clicking through
    if (component.slug === sharedSlidesSlugs.assessmentResults
        && (this.getNumTasksWithNoResponse() > 0 || !this.tasks.length)
    ) {
      this.toastr.error(translate('common.notAllTasksHaveResponses'));
      return false;
    }

    // if some other screen that's not even accessible via menu
    // (audio setup, overview), allow
    return true;
  }

  priorTaskForComponentHasResponse(component) {
    // user trying to access a slide before the slides have even been bootstrapped,
    // i.e. by direct URL, rather than by accessing from beginning of presentation; disallow
    if (!this.slides) {
      return false;
    }

    const priorTaskComponent = this.getPriorTaskComponentForComponent(component);

    // at beginning of slides
    if (priorTaskComponent === false) {
      return true;
    }

    const priorTaskResponse = this.responseCollectionService.getResponeForTask(
          this.getTaskForSlideComponent(priorTaskComponent)
    );

    return !!priorTaskResponse;
  }

  getPriorTaskComponentForComponent(component) {
    const slidesLength = this.slides.length;
    let maxIndex = 0;

    for (let i = 0; i < slidesLength; i++) {
      let iteratedComponent = this.slides[i][0];

      if (component.nsComponentId === iteratedComponent.nsComponentId) {

        // can't deliver prior task to the first one
        if (i === 0) {
          return false;
        }

        maxIndex = i - 1;
        break;
      }
    }

    for (let i = maxIndex; i >= 0; i--) {
      let iteratedComponent = this.slides[i][0];

      if (iteratedComponent.isTaskSlideComponent) {
        return iteratedComponent;
      }
    }

    return false;
  }

  getPriorTaskMetaForComponent(component) {
    if (!this.priorTaskForComponentHasResponse(component)) {
      return null;
    }

    const priorSlideComponent = this.getPriorTaskComponentForComponent(component);
    return this.getTaskMetaForComponent(priorSlideComponent);
  }

  getTaskMetaForComponent(component) {
    const slideTask = this.getTaskForSlideComponent(component);
    const slideResponse = this.responseCollectionService.getResponeForTask(slideTask);

    if (!slideResponse || (<ResponseModel>slideResponse).variantIsDontKnow()) {
      return null;
    }

    return (<ResponseModel>slideResponse).getMeta();
  }

  getPriorTaskSuccessForComponent(component) {
    if (!this.priorTaskForComponentHasResponse(component)) {
      return null;
    }

    const priorSlideComponent = this.getPriorTaskComponentForComponent(component);
    const priorSlideTask = this.getTaskForSlideComponent(priorSlideComponent);
    const priorSlideResponse = this.responseCollectionService.getResponeForTask(priorSlideTask);

    if (!priorSlideResponse) {
      return null;
    }

    return (<ResponseModel>priorSlideResponse).getSuccess();
  }

  /**
   * Get the L10n file's node to provide Transloco for translation.
   *
   * The formatting of uppercasing etc relates to TRANSLOCO_SCOPE's provided:
   *
   * `basic-computer-skills/assessments/base/v1` will look in that directory beneath i18n directory for a en.json file,
   * and the resulting cache node is at BasicComputerSkillsAssessmentsBaseV1
   *
   * @param componentId
   * @returns {L10nNode}
   */
  getL10nNodeForComponent(componentId) {
    const categoryOfComponent = <'practice'|'assessment'>(this.slideGroups.length ? 'practice' : 'assessment');

    return new L10nNode(
      `${L10nNode.pathForTranslateModule(this.module, categoryOfComponent)}/slides/${getKebabCoreNameForComponent(componentId)}`
    );
  }
}
