import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

/**
 * Insert the transloco active language in an image name.
 * 
 * Image names for en language ar unchanged.
 * 
 * For other languages a hyphen followed by the transloco active language is inserted before the dot extension.
 * 
 * Example with active language set to es_419: 
 * 
 * [attr.src]="'/assets/img/shared-interface-ms-word-office2016/tabs/word-file-interface-tab-info.png' | l10nImageName"
 * 
 * Inserts '-es_419' between 'info' and '.png'
 * 
 * Returning:
 * src="/assets/img/shared-interface-ms-word-office2016/tabs/word-file-interface-tab-info-es_419.png"
 * 
 */

@Pipe({
  name: 'l10nImageName'
})
export class L10nImageNamePipe implements PipeTransform {

  constructor(protected translocoService: TranslocoService,) {
  }

  lang: string =  this.translocoService.getActiveLang();

  transform(imageName: string): string {
    // No change for en language image names
    if (this.lang === 'en') {
      return imageName;
    }
    let insertIndex: number = imageName.length - 4;
    return imageName.substring(0, insertIndex) + '-' + this.lang + imageName.substring(insertIndex);
  }
}
