import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, UrlSegment, Router, ActivatedRoute} from "@angular/router";
import {ApiService, apiEndpoints} from "../services/api.service";
import {SessionService} from "../services/session.service";
import {CustomDataStore} from "../data-store/custom-data-store.service";
import {LoggerService} from "../services/logger.service";
import {ModulePracticeLessonsResolve} from "./module-practice-lessons.resolve";
import {ModulesResolve} from "./modules.resolve";
import {MeResolve} from "./me.resolve";
import {AssessmentModule, User} from "northstar-foundation";
import {find} from "lodash";
import {BootstrapDataService} from "../bootstrap/bootstrap-data.service";
import {forkJoin, of} from "rxjs";
import {switchMap} from "rxjs/operators";


@Injectable()
export class PracticeBootstrapResolve implements Resolve<any> {

  constructor(protected loggerService: LoggerService,
              protected bootstrapData: BootstrapDataService,
              protected dataStore: CustomDataStore,
              public sessionService: SessionService,
              protected apiService: ApiService,
              protected router: Router,
              protected meResolver: MeResolve,
              protected modulesResolver: ModulesResolve,
              protected modulePracticeLessonResolver: ModulePracticeLessonsResolve
  ) {
  }

  /**
   * Get various resolve data needed for practice player.
   *
   * Ref https://stackoverflow.com/a/46793244/4185989 for multiple resolves sequentially.
   *
   * @param route
   * @returns {Promise<void>}
   */
  resolve(
    route: ActivatedRouteSnapshot
  ) {
    return forkJoin([
      this.meResolver.resolve(route),
      this.modulesResolver.resolve(route)
    ]).pipe(
      switchMap((results: any[]) => {
        const v2Modules = this.dataStore.filter('module', {
          where: {
            variantCode: {
              '>=': 1
            }
          },
          orderBy: [
            ['variantCode', 'DESC'] // sort by latest variant code in order to get most recent module first
          ]
        });

        // find newest v2 module represented by current topic slug in URL
        // e.g. URL of /basic-computer-skills/practice/select-checkbox
        const module: AssessmentModule = find(v2Modules, (module: AssessmentModule) => {
          return route.url[0].path === `${module.topic.slug}${module.softwareVersion.isBase ? '' : '-' + module.softwareVersion.slug}`;
        });

        this.bootstrapData.setModule(module);

        // fetch practice lessons for the relevant topic
        this.modulePracticeLessonResolver.setModuleId(module.id);
        return this.modulePracticeLessonResolver.resolve(route);
      }),
      switchMap(practiceLessons => {
        return of({
          practiceLessons: practiceLessons
        })
      }),
    );
  }
}
