import {Directive, HostListener} from '@angular/core';
import {LoggerService} from "../../core/services/logger.service";

/**
 * Prevents default behavior of an event. Easy way of making more DRY than frequently having event.preventDefault() in
 * components for (click) events.
 *
 */
@Directive({
  selector: '[preventDefault]'
})
export class PreventDefaultDirective {

  constructor(
    protected loggerService: LoggerService
  ) {
  }

  @HostListener('click', ['$event'])
  onClick(event:MouseEvent) {
    event.preventDefault();

    this.loggerService.log(['Prevent default', event]);
  }

}
