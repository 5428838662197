import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule, {
  // see https://github.com/angular/angular-cli/issues/10859#issuecomment-390808375
  // and https://github.com/angular/angular/issues/21049#issuecomment-402654380
  preserveWhitespaces: true
});
