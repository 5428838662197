import {Component, OnInit, OnDestroy, ElementRef, Inject, HostListener} from '@angular/core';
import {trigger, style, animate, transition} from '@angular/animations';
import {SlideNavigationService} from "../../../core/engagement/slide-navigation.service";
import {
  TransparentBackdropService,
  ITransparentBackdropEnabledUpdate
} from "../../../core/engagement/transparent-backdrop.service";
import {Subscription} from "rxjs";
import {IAppConfig, APP_CONFIG} from "../../../app.config";
import {SessionService} from "../../../core/services/session.service";
import {MediaQueryService} from "../../../core/environment/media-query.service";

const transparentBackdropNavigationNamespace = 'TRANSPARENT_BACKDROP_NAVIGATION_NAMESPACE';

@Component({
  selector: 'other-slides-navigation',
  templateUrl: './other-slides-navigation.component.html',
  styleUrls: [
    './_shared-slide-button.scss',
    './other-slides-navigation.component.scss'
  ],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateY(5%)', opacity: 0}),
          animate('100ms', style({transform: 'translateY(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateY(0)', opacity: 1}),
          animate('100ms', style({transform: 'translateY(5%)', opacity: 0}))
        ])
      ]
    )
  ],
})
export class OtherSlidesNavigationComponent implements OnInit, OnDestroy {
  visible: boolean = false;

  /**
   * Where we want to auto-focus the toggle button after menu disappears via subscription to
   * transparentBackdropService, need to test for whether the service's value has ever been enabled/visible,
   * so that menu-toggle button doesn't get focused immediately upon instantiation due to backdropChangedHandler firing.
   *
   * @type {boolean}
   */
  backdropHasAppearedOnCurrentPageView: boolean = false;
  backdropSubscription: Subscription;

  /**
   * For LMS navigation, optionally show individual practice slides rather than just section and lesson titles.
   * Applicable to debug/dev mode only.
   *
   * @type {boolean}
   */
  showIndividualPracticeSlides: boolean = false;

  constructor(
    protected navigation: SlideNavigationService,
    protected transparentBackdropService: TransparentBackdropService,
    public mediaQueryService: MediaQueryService,
    protected el: ElementRef,
    public sessionService: SessionService,
    @Inject(APP_CONFIG) public appConfig: IAppConfig
  ) {
    this.backdropSubscription = this.transparentBackdropService.enabled$.subscribe(
      this.backdropChangedHandler.bind(this)
    )
  }

  toggle(event) {
    event.preventDefault();

    this.visible = !this.visible;

    if (this.visible) {
      if (this.mediaQueryService.mediaBreakpointDown.md) {
        this.transparentBackdropService.setGrayed();
      }

      this.transparentBackdropService.enable(transparentBackdropNavigationNamespace);
      this.focusOnMenuCurrentSlideLink();
      return;
    }

    this.transparentBackdropService.disable();
    this.focusOnMenuToggleButton();
  }

  /**
   * If user interacts with the backdrop, hide menu.
   */
  backdropChangedHandler(backdropEnabledUpdate: ITransparentBackdropEnabledUpdate) {
    if (!backdropEnabledUpdate.status) {
      this.visible = false;

      if (backdropEnabledUpdate.namespace === transparentBackdropNavigationNamespace) {
        this.focusOnMenuToggleButton();
      }
    }
  }

  protected focusOnMenuCurrentSlideLink() {
      setTimeout(() => {
        const focusableElement = this.el.nativeElement.querySelector('li.current a');

        if (focusableElement) {
          focusableElement.focus();
        }
      }, 1);
  }

  protected focusOnMenuToggleButton() {
    const toggleButton = this.el.nativeElement.querySelector('.launch-other-slides-navigation');

    if (toggleButton) {
      toggleButton.focus();
    }
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.backdropSubscription.unsubscribe();
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyPress(event: KeyboardEvent) {
    // only do special handling of Shift key press when nav menu is open
    if (this.appConfig.debug && this.visible && event.shiftKey) {
      event.preventDefault();
      this.showIndividualPracticeSlides = !this.showIndividualPracticeSlides;
    }
  }
}
