import {Component, OnInit, OnDestroy, Input, HostBinding, ElementRef} from '@angular/core';
import {PopoverService} from "../../../core/popover/popover.service";
import {FadeInOut} from "../../animations";

@Component({
  selector: 'pointer',
  templateUrl: './pointer.component.html',
  styleUrls: [
    './pointer.component.scss'
  ],
  animations: [FadeInOut(400)]
})
export class PointerComponent implements OnInit, OnDestroy {
  hidden: boolean = true;

  @Input()
  direction: 'up'|'right'|'down'|'left' = 'up';

  @Input()
  variant: string = '';

  @Input()
  id: string;

  constructor(
    public popoverService: PopoverService,
    public elRef: ElementRef,
  ) {
  }

  ngOnInit() {
    this.popoverService.register(this);
  }

  ngOnDestroy() {
    this.popoverService.unregister(this);
  }

  show() {
    this.hidden = false;
  }

  hide() {
    this.hidden = true;
  }

  toggle() {
    this.hidden = !this.hidden;
  }

  scrollIntoView() {
    this.elRef.nativeElement.scrollIntoView();
  }
}
