export const DontKnowVariant = 'dont-know';

export const variantStringMaxLength = 64;

export class ResponseModel {
  private datetime: Date;

  constructor(
    private task: any,
    private success: boolean,
    private variant: string,
    private meta: any
  ) {
    this.datetime = new Date();
    this.variant = variant || ''; // cannot be null

    if (this.variant) {
      this.variant = this.variant.slice(0, variantStringMaxLength);
    }

    this.meta = meta || null;
  }

  public getTask() {
    return this.task;
  }

  public getSuccess() {
    return this.success;
  }

  public getTime() {
    return this.datetime;
  }

  public getVariant() {
    return this.variant;
  }

  public getMeta() {
    return this.meta;
  }

  public variantIsDontKnow() {
    return this.variant === DontKnowVariant;
  }
}
