
import {tap} from 'rxjs/operators';
import {
  HttpEvent, HttpInterceptor, HttpXsrfTokenExtractor, HttpRequest, HttpHandler,
  HttpResponse
} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

import {SerializationService} from "./serialization.service";
import {LoggerService} from "./logger.service";

// https://github.com/angular/angular/issues/18859#issuecomment-343386182
@Injectable()
export class XsrfInterceptor implements HttpInterceptor {

    constructor(
      private tokenExtractor: HttpXsrfTokenExtractor,
      protected serializationService: SerializationService,
      protected loggerService: LoggerService
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let requestToForward = req;

        // CSRF token isn't required by server side for safe requests (e.g. GET),
        // but including it for unsafe requests like POST; otherwise request rejected
        let token = this.tokenExtractor.getToken() as string;
        if (token !== null) {
          requestToForward = req.clone({ setHeaders: { "X-CSRFToken": token } });
        }

        return next.handle(requestToForward).pipe(tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.interceptResponse(event);
          }
        }));
    }

    interceptResponse(event: any) {
      // check not undefined, not null (e.g. null for empty 200 OK response)
      if (typeof event.body !== 'undefined' && event.body) {
        event.body = this.serializationService.deserialize(event.body);
      }

      // this.loggerService.log(['Intercepted response. Result:', event.body]);
    }
}
