import {Resolve, ActivatedRouteSnapshot, Router} from "@angular/router";
import {Injectable, Inject} from "@angular/core";
import {CustomDataStore} from "../../data-store/custom-data-store.service";
import {IAppConfig, APP_CONFIG} from "../../../app.config";
import {TranslocoService, Translation} from "@ngneat/transloco";
import {L10nNode} from "../../i18n/l10n-node";
import {AssessmentModule} from "northstar-foundation";
import {LoggerService} from "../../services/logger.service";
import {find} from "lodash";
import {forkJoin, Observable, of} from "rxjs"
import {switchMap} from "rxjs/operators";
import {PracticeBootstrapResolve} from "../practice-bootstrap.resolve";

@Injectable()
export class L10nForPracticeResolve implements Resolve<any> {

  constructor(
    protected router: Router,
    @Inject(APP_CONFIG) protected appConfig: IAppConfig,
    protected dataStore: CustomDataStore,
    protected translocoService: TranslocoService,
    protected loggerService: LoggerService,
    protected practiceBootstrapResolve: PracticeBootstrapResolve,
  ) {
  }

  resolve(route:ActivatedRouteSnapshot): Observable<Translation[]> {

    // first ensure we have the necessary module data to determine which l10n info to fetch
    return this.practiceBootstrapResolve.resolve(route)
      .pipe(
        switchMap(practiceBootstrapResult => {
          const module = find(this.dataStore.filter('module', {where: {variantCode: {'>': 0}}}), (module: AssessmentModule) => {
            let firstSegmentIsBaseTopic = route.url[0].path === module.topic.slug;
            let firstSegmentIsVersionedTopic = route.url[0].path === `${module.topic.slug}-${module.softwareVersion.slug}`;

            return (firstSegmentIsBaseTopic || firstSegmentIsVersionedTopic) && route.url[1] && route.url[1].path === 'practice';
          });

          if (!module) {
            this.loggerService.warn(['No module found to fetch translation for']);
            return of([]);
          }

          const practiceL10nFile = L10nNode.pathForSelectModule(module, 'practice') + this.translocoService.getActiveLang();

          this.loggerService.log(['Fetch practice translation file at ' + practiceL10nFile]);

          return forkJoin([
            this.translocoService.selectTranslation(this.translocoService.getActiveLang()),

            // below shouldn't be necessary to lazy load modules' translation files per transloco docs at
            // https://ngneat.github.io/transloco/docs/scope-configuration but consistently got the first slide's content
            // not translated in development env, so loading here explicitly instead
            this.translocoService.selectTranslation(practiceL10nFile)
          ]);
        })
      );
  }
}
