import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {BaseOverviewSlideComponent} from "./base-overview-slide.component";
import {ModulePracticeLessonCompleteResolve} from "../../core/resolves/module-practice-lesson-complete.resolve";
import {YouTubePlayerService} from "../../core/services/youtube-player.service";
import * as YouTubePlayer from 'youtube-player';
import {ResponsiveVideoWrapperComponent} from "../layout/ui-helpers/responsive-video-wrapper.component";

@Component({
  templateUrl: './base-overview-slide.component.html'
})
export class PracticeBaseOverviewSlideComponent extends BaseOverviewSlideComponent {

  showProgressBar: boolean = false;
  recordLessonCompleteOnInit: boolean = false;

  // set this in child classes; the rest below are not needing editing in children
  // string if same video appears in all languages; object w/lang keys if video available per language
  youtubeVideoId: string|any;

  audioVariant: 'youtube';
  player: YouTubePlayer = null;
  @ViewChild(ResponsiveVideoWrapperComponent, {read: ElementRef})
  responsiveVideoWrapperComponent: ElementRef;

  getIsNavigableSlide() {
    return PracticeBaseOverviewSlideComponent.isOverviewSlideComponent;
  }

  ngOnInit() {
    // affects whether captions overlay shown
    this.audioVariant = this.youtubeVideoId ? 'youtube' : null;

    super.ngOnInit();

    if (this.recordLessonCompleteOnInit) {
      this.recordLessonComplete();
    }

    this.responseCollectionService.activateNamespace(this.getNamespaceForSlideType());
  }

  getNamespaceForSlideType() {
    return this.responseCollectionService.getNamespaceForLesson(this.navigation.getLessonForCurrentComponent());
  }

  ngAfterViewInit() {
    const ytPlayerService = this.injector.get(YouTubePlayerService);

    let youtubeVideoIdForLang;

    if (this.youtubeVideoId) {
      if (typeof this.youtubeVideoId === 'string') {
        // only English available
        youtubeVideoIdForLang = this.youtubeVideoId;
      } else {
        // at least some translations available, so check for current lang or default to English
        youtubeVideoIdForLang = typeof this.youtubeVideoId[this.translocoService.getActiveLang()] !== 'undefined'
          ? this.youtubeVideoId[this.translocoService.getActiveLang()]
          : this.youtubeVideoId['en'];
      }

      const innerPlayerWrapper = this.renderer.createElement('div');
      this.renderer.appendChild(this.responsiveVideoWrapperComponent.nativeElement, innerPlayerWrapper);

      this.player = ytPlayerService.create({nativeElement: innerPlayerWrapper}, youtubeVideoIdForLang);
    } else {
      ytPlayerService.reset();
    }
  }

  /**
   * Audio files for NOL are beneath a 'practice' folder to separate from assessment audio.
   */
  getAudioPathSubfolder() {
    return 'practice/';
  }

  recordLessonComplete(): void {
    const lessonCompleteResolver = this.injector.get(ModulePracticeLessonCompleteResolve);
    let subscription = lessonCompleteResolver.resolve(this.route.snapshot).subscribe(() => {
      subscription.unsubscribe();
    });
  }
}
