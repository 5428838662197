import {Component, OnInit, Input, Output, EventEmitter, Inject} from '@angular/core';
import {ModulePracticeLesson} from "northstar-foundation";

@Component({
  selector: 'lesson-nav-link',
  templateUrl: './lesson-nav-link.component.html',
  styleUrls: ['./slide-nav-link.component.scss']
})
export class LessonNavLinkComponent {
  @Input() lesson: ModulePracticeLesson;

  @Output('onToggleSelf')
  toggleSelf: EventEmitter<any> = new EventEmitter<any>();
}
