import { HttpClient } from '@angular/common/http';
import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule, TRANSLOCO_MISSING_HANDLER, TranslocoMissingHandler, TranslocoMissingHandlerData, HashMap
} from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(
    private http: HttpClient
  ) {}

  getTranslation(lang: string) {
    const date = new Date();

    // bust cache of lang files every 10 mins since crucial to experience and difficult to troubleshoot users' cache
    return this.http.get<Translation>(`./assets/i18n/${lang}.json?cacheversion=${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}-${date.getUTCHours()}-${Math.floor(date.getUTCMinutes() / 10)}`);
  }
}

/**
 * Return null if translation missing, rather than returning key.
 */
export class NSTranslocoMissingHandler implements TranslocoMissingHandler {
  handle(key: string, data: TranslocoMissingHandlerData) {

    if (!environment.production && console && console.warn) {
      console.warn(`Missing translation key ${key}`);
    }

    return null;
  }
}

@NgModule({
  exports: [ TranslocoModule ],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['en' ,'es_419'],
        defaultLang: 'en',
        fallbackLang: 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
        flatten: {
          aot: false
        }
      })
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    { provide: TRANSLOCO_MISSING_HANDLER, useClass: NSTranslocoMissingHandler}
  ]
})
export class TranslocoRootModule {}
