import {Component, OnInit} from '@angular/core';
import {ResponseModel} from "../../core/engagement/response.model";
import {
  BaseTaskRecipeMultipleChoiceSingleSlide,
  IMultipleChoiceSingleInterpreterConfig, TaskPath, TaskPathStep
} from "./base-task-recipe-multiple-choice-single-slide.component";
import {IRecordedClick} from "../../core/engagement/interface-interaction-stager.service";
import {ModulePracticeLessonCompleteResolve} from "../../core/resolves/module-practice-lesson-complete.resolve";
import {Observable} from "rxjs";
import {ModulePracticeUserCompletion} from "northstar-foundation";
import {find, each, includes, findLastIndex} from "lodash";

/**
 * Note the custom properties and methods below are mostly copied from PracticeBaseTaskSlideComponent
 * since we want to extend BaseTaskRecipeMultipleChoiceSingleSlide
 */
@Component({
  templateUrl: './base-task-slide.component.html'
})
export class PracticeBaseTaskRecipeMultipleChoiceSingleSlide extends BaseTaskRecipeMultipleChoiceSingleSlide {
  showIDontKnowButton: boolean = false;
  showNextButton: boolean = true;
  delayBetweenSlidesMs: number = 1000;

  hasClickedCorrectly: boolean = false;
  allowNextWithoutCorrectAnswer: boolean = false; // some slides may provide feedback w/out correct answer, and allow user to click Next after seeing

  ngOnInit() {
    super.ngOnInit();

    this.responseCollectionService.activateNamespace(
      this.responseCollectionService.getNamespaceForLesson(this.navigationService.getLessonForCurrentComponent())
    );
  }

  handleClickNext() {
    if (!this.hasCorrectAnswer() && !this.allowNextWithoutCorrectAnswer) {
        // for predefined paths of clicks, auto-show which appropriate message to display instead of
        // default show-all of onIncorrectAnswer()
        if (this.taskPaths.length > 0) {
          this.handleMultipleChoiceSingleResponseClickWithPaths({
            id: 'CLICKED-NEXT-WITHOUT-ANSWER',
            event: null
          });
          return;
        }

      this.onIncorrectAnswer();
      return false;
    }

    this.setResponseAsSuccess();
  }

  onIncorrectAnswer() {
    this.popoverService.showAll();
  }

  protected onCorrectAnswer() {
    this.popoverService.hideAll();
  }

  onResponseComplete(response: ResponseModel) {
    this.popoverService.hideAll();
    super.onResponseComplete(response);
  }

  hasCorrectAnswer() {
    return this.hasClickedCorrectly;
  }

  configureSlideWrapperParameters() {
    super.configureSlideWrapperParameters();

    this.config.askUserToConfirmRetry = false;
  }

  /**
   * Audio files for NOL are beneath a 'practice' folder to separate from assessment audio.
   */
  getAudioPathSubfolder() {
    return 'practice/';
  }

  /**
   * Because we always want practice users to click Next, don't automatically do addSuccess or addFailure, just change
   * a boolean. Remove this method entirely if we end up not always requiring user to click Next.
   *
   * @param config
   * @param recordedClick
   */
  protected handleMultipleChoiceSingleResponseClick(
    config: IMultipleChoiceSingleInterpreterConfig,
    recordedClick: IRecordedClick
  ) {
    if (this.multipleChoiceSingleResponseClickIsSuccess(config, recordedClick)) {
      this.loggerService.log('Storing user success - awaiting user to click Next.');
      this.hasClickedCorrectly = true;
      this.onCorrectAnswer();
      return;
    }

    if (this.multipleChoiceSingleResponseClickIsFailure(config, recordedClick)) {
      this.onIncorrectAnswer();
      return;
    }

    this.onRecordedClick(recordedClick);
  }

  recordLessonComplete(): Observable<boolean | ModulePracticeUserCompletion> {
    const lessonCompleteResolver = this.injector.get(ModulePracticeLessonCompleteResolve);
    return lessonCompleteResolver.resolve(this.route.snapshot);
  }

  /**
   * Handle user click in slides that have sequential paths defined.
   */
  protected handleMultipleChoiceSingleResponseClickWithPaths(
    recordedClick: IRecordedClick
  ) {
    this.popoverService.hideAll();
    const activePath: TaskPath = find(this.taskPaths, {started: true});

    if (!activePath) {
      // user hasn't yet started a path, so see if current click matches one of the paths
      const newPath = find(this.taskPaths, (path: TaskPath) => path.startsWithClick(recordedClick));

      if (!newPath) {
        // user clicked incorrect area and hasn't started on legit path
        this.popoverService.showIds(this.taskPaths[0].steps[0].popoverIds);
        return;
      }

      newPath.start();
      newPath.steps[0].complete();

      if (newPath.steps.length <= 1) {
        this.hasClickedCorrectly = true;
        this.onCorrectAnswer();
      } else {
        this.onRecordedClick(recordedClick);
      }

      return;
    }

    // user has started a path, so check if made right next choice on that path
    const currentIndex = activePath.getCurrentStepIndex();
    const currentStep = activePath.steps[currentIndex];

    if (typeof currentStep === 'undefined') {
      // user probably already succeeded and is clicking wrong answer afterward - avoid needing various handlers below
      return;
    }

    if (!activePath.clickValid(recordedClick)) {

      // use custom onIncorrectAnswer() per step if available
      if (typeof activePath.steps[currentIndex].onIncorrectAnswer !== 'undefined') {
        setTimeout(() => currentStep.onIncorrectAnswer(recordedClick), 1);
      }

      setTimeout(() => this.popoverService.showIds(currentStep.popoverIds), 1);
      return;
    }

    currentStep.complete();

    if (activePath.steps.length - 1 === currentIndex) {
      this.hasClickedCorrectly = true;
      this.onCorrectAnswer();
    } else {
      this.onRecordedClick(recordedClick);
    }
  }

  /**
   * Convenience method to both save lesson completion, and the usual behavior of handling click-next so
   * that user doesn't manually need to click Next button.
   */
  recordLessonCompleteAndHandleClickNext() {
    let saveSubscription = this.recordLessonComplete().subscribe(() => {
      this.handleClickNext();

      saveSubscription.unsubscribe();
    });
  }
}
