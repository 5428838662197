import {InjectionToken} from '@angular/core';
import {environment} from "../environments/environment";
import {ITrackJsErrorHandlerAppConfig} from "northstar-foundation/angular";
import {L10nNode} from "./core/i18n/l10n-node";

export let APP_CONFIG = new InjectionToken('app.config');

export const taskRecipes = {
  multipleChoiceSingle: 'MultipleChoiceSingle',
  multipleChoiceMultiple: 'MultipleChoiceMultiple',
  dragAndDropMatching: 'DragAndDropMatching'
};

export interface IModuleTask {
  id: number,
  title: string,
  summary: string,
  slug: string
}

// see https://github.com/Microsoft/TypeScript/issues/7803#issuecomment-205279410
// for index signature explanation
export interface IModuleTasks {
  [key: string]: IModuleTask
}

export interface IPhotoCreditConfig {
  /**
   * Allow specification of reusable content by key, to keep DRY.
   */
  creditKey?: string,

  /**
   * If not using `creditKey` input above, provide HTML content to be shown.
   *
   * Alternatively, leaving legacy methodology of providing template within the component's tag.
   * e.g. <photo-credit>My photo credit</photo-credit>
   */
  creditContent?: string|string[],

  /**
   * Photo credits default to be hidden and require user clicking icon to see credits. Can override to true.
   */
  expanded?: boolean
}

export interface IModuleTaskSlideConfig {
  task: IModuleTask,
  hasAudio: boolean,
  audioPath: string,
  l10nNode?: L10nNode, // the node of the component in l10n file that pertains to slide title and summary
  contentL10nNode?: L10nNode, // node of the component in l10n file that contains slide content -
                              // differs from above in cases where multiple slides share the same template
  l10nParams: any, // values that may be interpolated into title text or captions, e.g.
  hasCaptions?: boolean,
  showNextButton: boolean,
  nextIsDisabled: any,
  customHandleClickNext: any,
  showIDontKnowButton: boolean,
  photoCreditConfig: IPhotoCreditConfig,
  hasBackgroundImage: boolean,
  askUserToConfirmRetry: boolean,
  userRetryResponseHandler: any
}

// for slides among the tasks that don't themselves have tasks associated,
// e.g. introducing the following few task slides
export interface IModuleOverviewSlideConfig {
  title: string,
  hasAudio: boolean,
  audioPath: string,
  l10nNode?: L10nNode, // the node of the component in l10n file that pertains to slide title and summary
  contentL10nNode?: L10nNode, // node of the component in l10n file that contains slide content -
                              // differs from above in cases where multiple slides share the same template
  audioVariant?: 'youtube',
  hasCaptions?: boolean,
  showNextButton: boolean,
  showProgressBar: boolean,
  isNavigableSlide: boolean,
  photoCreditConfig: IPhotoCreditConfig,
  customHandleClickNext?: any
}

// -------------------------------------------------
// APP CONFIG
// -------------------------------------------------

export interface IAppConfig extends ITrackJsErrorHandlerAppConfig {
  mainSiteBase: string,
  adminPortalBase: string,
  apiBase: string,
  loginUrl: string,
  logoutUrl: string,
  isPublicVersion: boolean,
  isSponsorVersion: boolean,
  debug: boolean,
  saveLogsToTrackJs: boolean, // TrackJS watches console.log and console.info: https://docs.trackjs.com/tracker/tips#use-consolelog-in-production
                              // ensure window._trackJs.console.display = false in index.html to hide from users though
  assetsFolder: string,
  imgFolder: string,
  sharedImgFolder: string,
  audioFolder: string,
  captionsFolder: string,
  minWindowHeightForNormalView: number,
  staticContentHeightForNormalView: number,
  apiMocks: any
}

export const appConfig: IAppConfig = {
  mainSiteBase: environment.mainSiteBase,
  apiBase: environment.apiBase,
  adminPortalBase: environment.adminPortalBase,
  loginUrl: environment.mainSiteBase + 'login/',
  logoutUrl: environment.mainSiteBase + 'logout/',
  isPublicVersion: environment.isPublicVersion,
  isSponsorVersion: environment.isSponsorVersion,
  assetsFolder: environment.assetsFolder,
  imgFolder: environment.assetsFolder + 'img/',
  sharedImgFolder: environment.assetsFolder + 'img/shared/',
  audioFolder: environment.assetsFolder + 'audio/',
  captionsFolder: environment.assetsFolder + 'captions/',
  debug: environment.debug,
  saveLogsToTrackJs: environment.saveLogsToTrackJs,
  minWindowHeightForNormalView: 700,
  staticContentHeightForNormalView: 500,
  apiMocks: environment.apiMocks // allow dev env to be self-sufficient rather than relying on full API
};
