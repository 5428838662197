import {RouterModule, ExtraOptions} from "@angular/router";
import { MocksForDevResolve } from "./core/resolves/mocks-for-dev.resolve";
import {PracticeBootstrapResolve} from "./core/resolves/practice-bootstrap.resolve";
import {LMSBootstrapResolve} from "./core/resolves/lms/lms-bootstrap.resolve";
import {L10nForAssessmentResolve} from "./core/resolves/l10n/l10n-for-assessment.resolve";
import {L10nForPracticeResolve} from "./core/resolves/l10n/l10n-for-practice.resolve";

const commonAssessmentResolves = {
  mocksForDev: MocksForDevResolve,
  l10nForAssessment: L10nForAssessmentResolve
};

const commonPracticeResolves = {
  mocksForDev: MocksForDevResolve,
  l10nForPractice: L10nForPracticeResolve
};

// see https://egghead.io/lessons/angular-2-lazy-load-angular-2-modules-with-the-router#/tab-transcript
// for how this accomplishes lazy loading
const routes = [
  {
    path: '',
    loadChildren: 'app/home/home.module#HomeModule',
    resolve: {
      mocksForDev: MocksForDevResolve
    }
  },
  {
    path: 'account',
    loadChildren: 'app/lms/lms.module#LMSModule',
    resolve: {
      lmsBootstrap: LMSBootstrapResolve
    }
  },
  {
    path: 'basic-computer-skills-1',
    loadChildren: 'app/basic-computer/base/v1/basic-computer-base-v1.module#BasicComputerBaseV1Module',
    resolve: commonAssessmentResolves
  },
  {
    path: 'basic-computer-skills/practice',
    loadChildren: 'app/basic-computer/practice/basic-computer-practice.module#BasicComputerPracticeModule',
    resolve: commonPracticeResolves
  },
  {
    path: 'internet-basics-1',
    loadChildren: 'app/internet-basics/base/v1/internet-basics-base-v1.module#InternetBasicsBaseV1Module',
    resolve: commonAssessmentResolves
  },
  {
    path: 'internet-basics/practice',
    loadChildren: 'app/internet-basics/base/practice/internet-basics-practice.module#InternetBasicsPracticeModule',
    resolve: commonPracticeResolves
  },
  {
    path: 'windows-win10-1',
    loadChildren: 'app/windows/win10/v1/windows-win10-v1.module#WindowsWin10V1Module',
    resolve: commonAssessmentResolves
  },
  {
    path: 'windows-win10/practice',
    loadChildren: 'app/windows/win10/practice/windows-win10-practice.module#WindowsWin10PracticeModule',
    resolve: commonPracticeResolves
  },
  {
    path: 'email-1',
    loadChildren: 'app/email/base/v1/email-base-v1.module#EmailBaseV1Module',
    resolve: commonAssessmentResolves
  },
  {
    path: 'email/practice',
    loadChildren: 'app/email/base/practice/email-practice.module#EmailPracticeModule',
    resolve: commonPracticeResolves
  },
  {
    path: 'ms-word-office2016-1',
    loadChildren: 'app/ms-word/office2016/v1/ms-word-office2016-v1.module#MSWordOffice2016V1Module',
    resolve: commonAssessmentResolves
  },
  {
    path: 'ms-word-office2016/practice',
    loadChildren: 'app/ms-word/office2016/practice/ms-word-office2016-practice.module#MSWord2016PracticeModule',
    resolve: commonPracticeResolves
  },
  {
    path: 'ms-excel-office2016-1',
    loadChildren: 'app/ms-excel/office2016/v1/ms-excel-office2016-v1.module#MSExcelOffice2016V1Module',
    resolve: commonAssessmentResolves
  },
  {
    path: 'ms-excel-office2016/practice',
    loadChildren: 'app/ms-excel/office2016/practice/ms-excel-office2016-practice.module#MSExcel2016PracticeModule',
    resolve: commonPracticeResolves
  },
  {
    path: 'ms-ppt-office2016-1',
    loadChildren: 'app/ms-powerpoint/office2016/v1/ms-powerpoint-office2016-v1.module#MSPowerPointOffice2016V1Module',
    resolve: commonAssessmentResolves
  },
  {
    path: 'ms-ppt-office2016/practice',
    loadChildren: 'app/ms-powerpoint/office2016/practice/ms-powerpoint-office2016-practice.module#MSPowerPoint2016PracticeModule',
    resolve: commonPracticeResolves
  },
  {
    path: 'g-docs-1',
    loadChildren: 'app/g-docs/base/v1/g-docs-base-v1.module#GDocsBaseV1Module',
    resolve: commonAssessmentResolves
  },
  {
    path: 'g-docs/practice',
    loadChildren: 'app/g-docs/base/practice/g-docs-base-practice.module#GDocsBasePracticeModule',
    resolve: commonPracticeResolves
  },
  {
    path: 'distance-learning-support-1',
    loadChildren: 'app/distance-learning-support/base/v1/distance-learning-support-base-v1.module#DistanceLearningSupportBaseV1Module',
    resolve: commonAssessmentResolves
  },
  {
    path: 'distance-learning-support/demo',
    loadChildren: 'app/distance-learning-support/base/demo/distance-learning-support-base-demo.module#DistanceLearningSupportBaseDemoModule',
    resolve: {
      mocksForDev: MocksForDevResolve
    }
  },
  {
    path: 'info-literacy-1',
    loadChildren: 'app/info-literacy/base/v1/info-literacy-base-v1.module#InfoLiteracyV1Module',
    resolve: commonAssessmentResolves
  },
  {
    path: 'info-literacy/practice',
    loadChildren: 'app/info-literacy/base/practice/info-literacy-base-practice.module#InfoLiteracyBasePracticeModule',
    resolve: commonPracticeResolves
  },
  {
    path: 'career-search-skills-1',
    loadChildren: 'app/career-search/base/v1/career-search-base-v1.module#CareerSearchBaseV1Module',
    resolve: commonAssessmentResolves
  },
  {
    path: 'career-search-skills/practice',
    loadChildren: 'app/career-search/base/practice/career-search-practice.module#CareerSearchPracticeModule',
    resolve: commonPracticeResolves
  },
  {
    path: 'mac-os-1',
    loadChildren: 'app/mac-os/base/v1/mac-os-base-v1.module#MacOSBaseV1Module',
    resolve: commonAssessmentResolves
  },
  {
    path: 'mac-os/practice',
    loadChildren: 'app/mac-os/base/practice/mac-os-base-practice.module#MacOsBasePracticeModule',
    resolve: commonPracticeResolves
  },
  // {
  //   path: 'digital-footprint-1',
  //   loadChildren: 'app/digital-footprint/base/v1/digital-footprint-base-v1.module#DigitalFootprintBaseV1Module',
  //   resolve: commonAssessmentResolves
  // },
  {
    path: 'digital-footprint-2',
    loadChildren: 'app/digital-footprint/base/v2/digital-footprint-base-v2.module#DigitalFootprintBaseV2Module',
    resolve: commonAssessmentResolves
  },
  {
    path: 'digital-footprint/practice',
    loadChildren: 'app/digital-footprint/base/practice/digital-footprint-base-practice.module#DigitalFootprintBasePracticeModule',
    resolve: commonPracticeResolves
  },
  {
    path: 'social-media-1',
    loadChildren: 'app/social-media/base/v1/social-media-base-v1.module#SocialMediaBaseV1Module',
    resolve: commonAssessmentResolves
  },
  {
    path: 'social-media/practice',
    loadChildren: 'app/social-media/base/practice/social-media-practice.module#SocialMediaPracticeModule',
    resolve: commonPracticeResolves
  },
  {
    path: 'telehealth-1',
    loadChildren: 'app/telehealth/base/v1/telehealth-base-v1.module#TelehealthBaseV1Module',
    resolve: commonAssessmentResolves
  },
  {
    path: 'finding-info-online-1',
    loadChildren: 'app/cilia-t/finding-info-online/base/v1/finding-info-online-base-v1.module#FindingInfoOnlineBaseV1Module',
    resolve: commonAssessmentResolves
  },
  {
    path: 'results',
    loadChildren: 'app/results/results.module#ResultsModule'
  }
];

export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always'
};

// ref https://github.com/angular/angular-cli/issues/3826
export const Routing = RouterModule.forRoot(routes, routingConfiguration);
