import {Component, OnInit, Input, HostBinding} from '@angular/core';
import {style, animate, transition, trigger} from "@angular/animations";

@Component({
  selector: 'mobile-controls-settings',
  templateUrl: './mobile-controls-settings.component.html',
  styleUrls: [
    './_shared-controls-button.scss',
    './_shared-slide-button.scss',
    './_shared-button-types.scss',
    './mobile-controls-settings.component.scss'
  ],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateY(5%)', opacity: 0}),
          animate('100ms', style({transform: 'translateY(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateY(0)', opacity: 1}),
          animate('100ms', style({transform: 'translateY(5%)', opacity: 0}))
        ])
      ]
    )
  ]
})
export class MobileControlsSettingsComponent {
  @Input() config;

  public visible: boolean = false;

  toggle(event) {
    event.preventDefault();

    this.visible = !this.visible;
  }
}
