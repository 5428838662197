import {Component, OnInit, Input} from '@angular/core';
import {CaptionsSettingsService} from "../../../core/settings/captions-settings.service";

@Component({
  selector: 'cc-toggle-button',
  templateUrl: './cc-toggle-button.component.html',
  styleUrls: ['./cc-toggle-button.component.scss']
})
export class CCToggleButtonComponent implements OnInit {

  constructor(public captionsSettingsService: CaptionsSettingsService) {

  }

  ngOnInit() {
  }

}
