import {Component, OnInit} from '@angular/core';
import {BaseOverviewSlideComponent} from "../base-overview-slide.component";
import {User, ModulePracticeSection} from "northstar-foundation";
import { translate } from '@ngneat/transloco';

@Component({
  templateUrl: 'section-review.component.html',
  styleUrls: [
    'section-review.component.scss',
    '../../layout/slide-wrapper/_shared-button-types.scss'
  ]
})
export class PracticeSectionReviewComponent extends BaseOverviewSlideComponent {
  static nsComponentId: string = 'PracticeSectionReviewComponent';
  static slug: string = 'review';

  // false because we're not using this as a nav slide
  static isOverviewSlideComponent: boolean = false;
  static isSectionReviewOverview: boolean = true;

  l10nBasePath: string = 'shared.slides.sectionReview.';

  showProgressBar: boolean = false;
  hasAudio = false;

  alertUserToLookAtOtherLessons: boolean = false;
  currentSection: ModulePracticeSection;

  getTitle() {
    return translate(`${this.l10nBasePath}sectionReviewTitle`);
  }

  getSlug() {
    return PracticeSectionReviewComponent.slug;
  }

  getComponentId() {
    return PracticeSectionReviewComponent.nsComponentId;
  }

  getIsNavigableSlide() {
    return PracticeSectionReviewComponent.isOverviewSlideComponent;
  }

  ngOnInit() {
    this.currentSection = <ModulePracticeSection>this.navigation.getCurrentSection();

    this.alertUserToLookAtOtherLessons = !this.sessionService.userIsLoggedIn() || !this.currentSection.lessonsCompletedByUser((<User>this.sessionService.user).id);

    this.showNextButton = !this.alertUserToLookAtOtherLessons || this.appConfig.debug;

    this.configureSlideWrapperParameters();

    // don't call nav service's onVisitSlideComponentInstance since that tries
    // to keep track of the 'visited' status of this slide, but this slide doesn't
    // even show up in the nav menu so it's irrelevant and would complicate logic elsewhere
  }
}
