import {Injectable} from '@angular/core';
import {Resolve, ActivatedRouteSnapshot, ActivatedRoute, Router} from '@angular/router';
import {ApiService, apiEndpoints} from "../services/api.service";
import {ToastrService} from 'ngx-toastr';
import {CustomDataStore} from "../data-store/custom-data-store.service";
import {BootstrapDataService} from "../bootstrap/bootstrap-data.service";
import {
  AssessmentModule,
  ModuleTopic,
  ModuleSoftwareVersion,
  Sponsor,
  User,
  ProctorSessionForUser
} from "northstar-foundation";
import {assign} from "lodash";
import {IApiAssessmentCreateResponse} from './results-data.resolve';
import {MeResolve} from './me.resolve';
import {SessionService} from "../services/session.service";
import {
  ProctorSessionForUserService,
  placeholderProctorSessionForUser
} from "../services/proctor-session-for-user.service";
import {of} from "rxjs";
import {catchError, switchMap} from "rxjs/operators";
import {TranslocoService} from "@ngneat/transloco";

// using prefix 'current' because serialization.service.ts otherwise would turn keys like `topic` into `topicId`,
// which is desirable much of the time, but not here
export interface IApiAssessmentHistoricalResponse extends IApiAssessmentCreateResponse {
  currentSoftwareVersion: ModuleSoftwareVersion,
  currentTopic: ModuleTopic,
  currentModule: AssessmentModule,
  currentSponsor: Sponsor,
  currentProctor: User,
  currentUserName: string,
  currentUser: User,
  currentProctorSessionForUser: ProctorSessionForUser,
}

@Injectable()
export class ResultsDataHistoricalResolve implements Resolve<any> {

  l10nBasePath: string = 'shared.slides.assessmentResults';

  constructor(
    protected apiService: ApiService,
    protected toastr: ToastrService,
    protected dataStore: CustomDataStore,
    protected bootstrapDataService: BootstrapDataService,
    protected meResolver: MeResolve,
    protected sessionService: SessionService,
    protected proctorSessionForUserService: ProctorSessionForUserService,
    protected translocoService: TranslocoService,
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    // get user login status and set to sessionService in order to determine whether to show practice links, how to handle badging, etc.
    return this.meResolver.resolve(route)
      .pipe(
        switchMap(me => this.translocoService.selectTranslation(this.translocoService.getActiveLang())),
        switchMap(translation => this.apiService.httpGet(
          apiEndpoints.getAssessmentResults,
          {
            map: {
              id: route.queryParams['id'],
            },
            params: {
              verification_code: route.queryParams['code']
            }
          }
        )),
        switchMap((result: IApiAssessmentHistoricalResponse) => {
          if (!result) {
            // @todo unsure whether this is ever run - unsuccessful responses were previously
            // being caught via `catch` below
            this.toastr.error(this.translocoService.translate(`${this.l10nBasePath}.noMatchesFound`));
            return of(false);
          }

          this.dataStore.add('module_software_version', result.currentSoftwareVersion);
          this.dataStore.add('module_topic', result.currentTopic);
          this.dataStore.add('module_standard_relation', result.standardRelations);
          this.dataStore.add('module_practice_lesson', result.lessons);
          this.dataStore.add('module_practice_section', result.sections);
          this.dataStore.add('module_practice_user_completion', result.lessonCompletions);
          this.dataStore.add('module_standard', result.standards);

          if (result.currentUser) {
            this.sessionService.setUser(<User>this.dataStore.add('user', result.currentUser));
          } else {
            // if admin's logged in, don't show their info but rather assessment taker
            // @todo awkward to be updating sessionService to do this, since likely logged in as a proctor, etc.
            // long-term consider alternative solution, e.g. assessment-meta.service.ts
            this.sessionService.clearUser();
            this.sessionService.setUser(result.currentUserName);
          }

          if (result.currentProctorSessionForUser) {
            // set session data for sake of UI display but don't fetch updates to session data
            this.proctorSessionForUserService._setSession(result.currentProctorSessionForUser);
          } else if (result.currentAssessment.proctored) {
            // support case for old proctored assessments prior to ProctorSessionForUser implementation
            // and/or assessments that were unproctored but later toggled to be proctored
            this.proctorSessionForUserService._setSession(placeholderProctorSessionForUser);
          }

          this.sessionService.setSponsor(<Sponsor>this.dataStore.add('sponsor', result.currentSponsor));
          this.bootstrapDataService.setProctor(result.currentProctor);
          this.bootstrapDataService.setModule(<AssessmentModule>this.dataStore.add('module', result.currentModule));

          return of(this.dataStore.add('assessment', assign(result.currentAssessment, {'historical': true})));
        }),
        catchError(error => {
          this.toastr.error(this.translocoService.translate(`${this.l10nBasePath}.noMatchesFound`));
          return of(false);
        })
      );
  }
}
