import {Injectable, ViewContainerRef, ComponentFactoryResolver} from "@angular/core";
import {includes} from "lodash";
import {Subject, Observable, Subscription} from "rxjs";
import {TransparentBackdropService} from "../engagement/transparent-backdrop.service";
import {TranslocoService} from "@ngneat/transloco";

export const modalTypes = {
  info: 'info',
  confirm: 'confirm',
  error: 'error'
};

@Injectable()
export class ModalService {
  show: boolean = false;
  showPromptFlag: boolean = false;
  promptText: string = '';
  promptConfirmText: string = '';
  type: string;
  title: string;
  bodyText: string;
  backdropSubscription: Subscription;

  private returnValue: Subject<any> = new Subject<any>();
  returnValue$: Observable<any> = this.returnValue.asObservable();

  constructor(
    protected transparentBackdropService: TransparentBackdropService,
    protected translocoService: TranslocoService,
  ) {
    this.backdropSubscription = this.transparentBackdropService.enabled$.subscribe(
      this.backdropChangedHandler.bind(this)
    );
    this.translocoService.selectTranslate('common.ok').subscribe(value => this.setPromptConfirmText(value));
  }

  backdropChangedHandler(backdropEnabled) {
    if (!backdropEnabled) {
      // calling `reset` rather than `close` to avoid circular loop w/TransparentBackdropService
      // due to disabling backdrop service via `close`
      this.reset();
    }
  }

  setType(type: string) {
    if (!includes(modalTypes, type)) {
      return;
    }

    this.type = type;

    return this;
  }

  showPrompt(text: string = '') {
    this.showPromptFlag = true;
    this.promptText = text;

    return this;
  }

  setPromptConfirmText(text: string = 'OK') {
    this.promptConfirmText = text;

    return this;
  }

  setTitle(title: string) {
    this.title = title;

    return this;
  }

  setBody(body: string) {
    this.bodyText = body;

    return this;
  }

  open() {
    this.show = true;
    this.transparentBackdropService.setGrayed().enable();

    return this;
  }

  setReturnValueAndClose(value) {
    this.returnValue.next(value);
    this.close();
  }

  close() {
    this.reset();
    this.transparentBackdropService.disable();

    return this;
  }

  reset() {
    this.show = false;
    this.showPromptFlag = false;
    this.promptText = '';
    this.translocoService.selectTranslate('common.ok').subscribe(value => this.setPromptConfirmText(value));
    this.type = modalTypes.info;
    this.title = '';
    this.bodyText = '';
  }
}
