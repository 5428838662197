import {Injectable} from "@angular/core";
import {ILanguage} from "northstar-foundation";


export interface IServerSettings {
  logoutIdleMinutes: number,
  timeOnTaskIdleMinutes: number,
  languages: ILanguage[]
}

/**
 * General place for storage of server settings obtained via API that aren't better suited for some other service.
 *
 * Cf MeResolve, SessionService, TimeOnTaskService
 */
@Injectable()
export class ServerSettingsService {

  settings: IServerSettings;

  configure(settings: IServerSettings) {
    this.settings = settings;
  }
}
