import {Component, OnInit, Input} from '@angular/core';
import {ModalService} from "../../../core/modal/modal.service";
import {FadeInOut} from "../../../shared/animations";

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: [
    './modal.component.scss',
    '../slide-wrapper/_shared-button-types.scss'
  ],
  animations: [FadeInOut()]
})
export class ModalComponent {

  constructor(
    public modalService: ModalService
  ) {
  }
}
