import {Component} from "@angular/core";

@Component({
  selector: 'external-link-flag',
  templateUrl: './external-link-flag.component.html',
  styleUrls: [
    './external-link-flag.component.scss'
  ]
})
export class ExternalLinkFlagComponent {

}
