import {Component, OnInit, Injector, OnDestroy, Inject, Renderer2} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {ResponseCollectionService} from "../../core/engagement/response-collection.service";
import {SlideNavigationService} from "../../core/engagement/slide-navigation.service";
import {IModuleOverviewSlideConfig, IPhotoCreditConfig, IAppConfig, APP_CONFIG} from "../../app.config";
import {ActivatedRoute} from "@angular/router";
import {AssetService} from "../../core/services/asset.service";
import {SessionService} from "../../core/services/session.service";
import {PopoverService} from "../../core/popover/popover.service";
import {
  InterfaceInteractionStagerService,
  IRecordedClick
} from "../../core/engagement/interface-interaction-stager.service";
import {KeyValueStoreService} from "../../core/services/key-value-store.service";
import {ProctorSessionForUserService} from "../../core/services/proctor-session-for-user.service";
import {L10nSlideContentPipe} from "../i18n/l10n-slide-content.pipe";
import { TranslocoService } from '@ngneat/transloco';

@Component({
  templateUrl: './base-overview-slide.component.html'
})
export class BaseOverviewSlideComponent implements OnInit, OnDestroy {
  static isOverviewSlideComponent: boolean = true;

  config: IModuleOverviewSlideConfig;

  showNextButton: boolean = true;
  showProgressBar: boolean = true;
  hasAudio: boolean = true;
  audioVariant: 'youtube' = null;
  hasCaptions: boolean = true;
  photoCreditConfig: IPhotoCreditConfig;
  subscriptions: any = {};

  constructor(
    public toastr: ToastrService,
    protected responseCollectionService: ResponseCollectionService,
    public navigation: SlideNavigationService,
    public route: ActivatedRoute,
    public assetService: AssetService,
    protected injector: Injector,
    protected sessionService: SessionService,
    public popoverService: PopoverService,
    protected stagerService: InterfaceInteractionStagerService,
    public keyValueStoreService: KeyValueStoreService,
    @Inject(APP_CONFIG) protected appConfig: IAppConfig,
    protected proctorSessionForUserService: ProctorSessionForUserService,
    protected l10nSlideContentPipe: L10nSlideContentPipe,
    protected translocoService: TranslocoService,
    protected renderer: Renderer2,
  ) {}

  getSlug() {
    return 'error-overwrite-getSlug';
  }

  getTitle() {
    return 'error-overwrite-getTitle';
  }

  getIsNavigableSlide() {
    return BaseOverviewSlideComponent.isOverviewSlideComponent;
  }

  ngOnInit() {
    this.configureSlideWrapperParameters();
    this.navigation.onVisitSlideComponentInstance(this);

    this.subscriptions['stager'] = this.stagerService.recordedClick$.subscribe(this.onRecordedClick.bind(this));
  }

  ngOnDestroy() {
    for (let key in this.subscriptions) {
      this.subscriptions[key].unsubscribe();
    }
  }

  protected onRecordedClick(recordedClick: IRecordedClick) {
    // override in child classes if needing access to every time user clicks, even if not a success/failure
    return;
  }

  handleClickNext() {
    this.navigation.goNext(this.route);
  }

  configureSlideWrapperParameters() {
    this.config = {
      title: this.getTitle(),
      audioPath: this.getAudioPath(),
      l10nNode: this.navigation.getL10nNodeForComponent(this.getComponentIdForL10nNode()),
      contentL10nNode: this.navigation.getL10nNodeForComponent(this.getComponentIdForContentL10nNode()),
      hasCaptions: this.hasCaptions,
      showNextButton: this.showNextButton,
      showProgressBar: this.showProgressBar,
      photoCreditConfig: this.photoCreditConfig,
      hasAudio: this.hasAudio,
      audioVariant: this.audioVariant,
      isNavigableSlide: this.getIsNavigableSlide(),

      // pass here rather than every slide component needing to define via EventEmitter handlers
      // on overview-slide component
      customHandleClickNext: this.handleClickNext.bind(this),
    };

    this.mergeL10nPhotoCredits();
  }

  /**
   * Look for photo credits in the l10n file's entry for this slide. Note that within slide component you can specify
   * `photoCreditConfig.expanded` if desired.
   *
   */
  mergeL10nPhotoCredits() {
    // pull photo credits from l10n if available
    const l10nPhotoCredit = this.l10nSlideContentPipe.transform('creditContent', this.config);

    if (l10nPhotoCredit) {
      this.config.photoCreditConfig = {
        creditContent: l10nPhotoCredit,
        expanded: (this.photoCreditConfig && typeof this.photoCreditConfig.expanded === 'boolean') ? this.photoCreditConfig.expanded : false
      };
    }
  }

  /**
   * Return the component ID that l10n data is saved under for purposes of title and slide summary (for nav menu).
   */
  getComponentIdForL10nNode(): string {
    return (<any>this.constructor).nsComponentId;
  }

  /**
   * Return the component ID that l10n data is saved under for purposes of slide content. Often the same as
   * getComponentIdForL10nNode() but need to override if template shared among multiple slides to keep l10n data DRY.
   */
  getComponentIdForContentL10nNode(): string {
    return this.getComponentIdForL10nNode();
  }

  getAudioPath() {
    return `${this.navigation.module.topic.slug}${this.navigation.module.softwareVersion.isBase ? '' : '-' + this.navigation.module.softwareVersion.slug}/${this.translocoService.getActiveLang()}/${this.getAudioPathSubfolder()}${this.getSlug()}`;
  }

  /**
   * For children classes, may want to organize audio files into subfolders, e.g. practice/
   * @returns {string}
   */
  protected getAudioPathSubfolder() {
    return '';
  }
}
