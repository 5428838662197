import {Component, OnInit, Input, ElementRef} from '@angular/core';
import {LoggerService} from "../../../core/services/logger.service";
import {getWidthOfText} from "../../../core/utilities/text";

@Component({
  selector: 'slide-title',
  templateUrl: './slide-title.component.html',
  styleUrls: ['./slide-title.component.scss']
})
export class SlideTitleComponent implements OnInit {
  @Input() text: string;

  hasLineBreak: boolean = false;
  numLines: number = 1;

  constructor(
    protected loggerService: LoggerService,
    protected elRef: ElementRef,
  ) {}

  ngOnInit() {
    if (!this.text) {
      this.loggerService.warn(['Title not yet set for this slide.']);
      return;
    }

    // this.hasLineBreak = this.text.indexOf('<br>') > -1;
    // @todo font size dependent upon breakpoint?
    const estimatedWidthOfText = getWidthOfText(this.text, '15px Lato'); // errs slightly on side of providing more lines than necessary, but fairly accurate

    // 170 empirical offset found to work well to allow nav and reply-audio buttons to show on right
    const usableWidth = this.elRef.nativeElement.clientWidth - 170;

    // @todo usableWidth only relevant for certain breakpoints, since hidden on mobile - update for mobile UI
    this.numLines = Math.ceil(estimatedWidthOfText / usableWidth);
    this.hasLineBreak = this.numLines > 1;
  }

}
