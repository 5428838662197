import {Component, OnInit, Inject} from '@angular/core';
import {sharedSlidesSlugs} from "../shared-slides-slugs.config";
import {ResponseCollectionService} from "../../../core/engagement/response-collection.service";
import {ActivatedRoute} from "@angular/router";
import {LoadingOverlayService} from "../../../core/engagement/loading-overlay.service";
import {BadgingService} from "../../../core/badging/badging.service";
import {ModalService} from "../../../core/modal/modal.service";
import {round, includes} from "lodash";
import {FadeInOut} from "../../animations";
import {Assessment, AssessmentModuleStandard, User} from "northstar-foundation";
import {IAppConfig, APP_CONFIG} from "../../../app.config";
import {ToastrService} from 'ngx-toastr';
import {SessionService} from "../../../core/services/session.service";
import {TranslocoService} from "@ngneat/transloco";
import {copyToClipboard} from "../../../core/utilities/text";
import {MediaQueryService} from "../../../core/environment/media-query.service";

@Component({
  templateUrl: './assessment-results.component.html',
  styleUrls: [
    './assessment-results.component.scss',
    '../../layout/slide-wrapper/_shared-slide-wrapper.component.scss',
    '../../layout/slide-wrapper/_shared-button-types.scss',
    '../../layout/slide-wrapper/_shared-controls-button.scss'
  ],
  animations: [FadeInOut()]
})
export class AssessmentResultsComponent implements OnInit {
  static nsComponentId: string = 'AssessmentResultsComponent';
  static slug: string = sharedSlidesSlugs.assessmentResults;

  assessment: Assessment;
  standardsMastered: AssessmentModuleStandard[];
  standardsNeedingImprovement: AssessmentModuleStandard[];
  finishDateTime: Date|null;
  showScoreExplanation: boolean = false;
  permanentUrl: string;
  userExpectedPercentage: number;
  moduleNumsInRound2Testing: number[] = [];
  requestFeedback: boolean = false;
  showPracticeLinks: boolean = false;
  templateSettings: any = {};

  constructor(
    public responseCollection: ResponseCollectionService,
    protected badgingService: BadgingService,
    private route: ActivatedRoute,
    private loadingOverlayService: LoadingOverlayService,
    private modalService: ModalService,
    @Inject(APP_CONFIG) protected appConfig: IAppConfig,
    protected toastr: ToastrService,
    protected sessionService: SessionService,
    protected translocoService: TranslocoService,
    public mediaQueryService: MediaQueryService,
  ) {}

  l10nBasePath: string = 'shared.slides.assessmentResults';
  slideContent: any = this.translocoService.translateObject(this.l10nBasePath);

  getComponentId() {
    return AssessmentResultsComponent.nsComponentId;
  }

  ngOnInit() {
    // response from API
    this.assessment = <Assessment>this.route.snapshot.data['results'];
    this.standardsMastered = this.assessment.getStandardsMastered();
    this.standardsNeedingImprovement = this.assessment.getStandardsNeedingImprovement();
    this.userExpectedPercentage = round((this.assessment.numCorrect / this.assessment.numPossible) * 100, 1);

    // Angular's DatePipe responsible for rendering in the browser's timezone
    this.finishDateTime = this.assessment.end;

    this.permanentUrl = this.assessment.getResultsUrl(window.location.origin + '/');

    // show feedback dialog box if module in round-2 field testing w/testing locations
    this.requestFeedback = this.moduleNumsInRound2Testing.length > 0
      && includes(this.moduleNumsInRound2Testing, this.assessment.moduleId)
      && !!this.assessment.sponsorId;

    this.loadingOverlayService.hide();
    this.modalService.returnValue$.subscribe(this.onBadgeEmailProvided.bind(this));

    this.showPracticeLinks = !this.assessment.module.isLegacy()
      && this.assessment.module.lessons
      && this.assessment.module.lessons.length > 0
      && this.assessment.sponsorId !== null
      && this.assessment.userId !== null
      && this.sessionService.userIsLoggedIn();
  }

  claimBadge() {
    if (this.assessment.user) {
      this.onBadgeEmailProvided(this.assessment.user.email);
      return;
    }

    this.modalService
        .setTitle(this.slideContent.claimYourBadge)
        .setBody(`
          <img class="float-right badge-image ml-3" src="${this.assessment.badgeImageUrl}">
          <p>${this.slideContent.youHaveEarnedBadge}</p>
          <p>${this.slideContent.withoutAccount}</p>
        `)
        .showPrompt(this.slideContent.yourEmailAddress)
        .setPromptConfirmText(this.slideContent.claimBadge)
        .open();
  }

  alertBadgeClaimed() {
    this.toastr.info(this.slideContent.youHaveAlreadyClaimed);
  }

  onBadgeEmailProvided(email) {
    if (!email) {
      return;
    }

    this.loadingOverlayService.show();

    this.badgingService.claim(
      email,
      this.assessment.verificationCode,
      this.assessment.id
    ).add(() => {
      // after response from our API, hide loading graphic
      this.loadingOverlayService.hide();
    });
  }

  print() {
    window.print();
  }

  copyLink(link: string) {
    const successMsgTimeout: number = 4000;

    try {
      copyToClipboard(link);
      this.toastr.success(this.slideContent.linkCopiedToClipboard, '', {timeOut: successMsgTimeout});
      this.templateSettings.showCopiedOk = true;

      setTimeout(() => {
        this.templateSettings.showCopiedOk = false;
      }, successMsgTimeout);

    } catch (err) {
      this.toastr.error('Problem copying URL.');
    }
  }
}
