import {Component, OnInit, Input, HostBinding} from '@angular/core';
import {LoadingOverlayService} from "./loading-overlay.service";
import {FadeInOut} from "../../shared/animations";

@Component({
  selector: 'loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss'],
  animations: [FadeInOut()]
})
export class LoadingOverlayComponent implements OnInit {
  constructor(public loadingOverlayService: LoadingOverlayService) {
  }

  ngOnInit() {}

  /**
   * Only show host element if overlay experience should be visible.
   *
   * https://stackoverflow.com/a/49883744/4185989
   *
   * @returns {boolean}
   */
  @HostBinding('class.show')
  public get showHost() {
    return this.loadingOverlayService.visible;
  }
}
