import {PipeTransform, Pipe} from "@angular/core";
import {PlatformService} from "../../core/environment/platform.service";
import {IOSDependentText} from "../../core/engagement/keyboard-event-interpreter.service";

/**
 * Provide Mac- or Windows-specific text, e.g. explaining shortcuts on different platforms.
 */
@Pipe({
  name: 'textForOS'
})
export class OSDependentTextPipe implements PipeTransform {
  constructor(
    protected platformService: PlatformService,
  ) {
  }

  transform(val: IOSDependentText): any {
    return this.platformService.isMac ? val.mac : val.windows;
  }
}
