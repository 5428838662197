import {Injectable} from "@angular/core";

@Injectable()
export class LoadingOverlayService {
  visible: boolean = false;
  mode: 'congrats'|'loading'|'checkmark' = 'loading';

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }

  setMode(mode: 'congrats'|'loading'|'checkmark') {
    this.mode = mode;
  }
}
