import {Injectable} from "@angular/core";
import {LoggerService} from "./logger.service";
import {each, camelCase, snakeCase, mapKeys} from "lodash";

@Injectable()
export class SerializationService {
  pkFieldNames: string[] = [
    'user',
    'module',
    'topic',
    'softwareVersion',
    'assessment',
    'sponsor',
    'country',
    'region',
    'network',
    'proctor',
    'standard',
    'modulePracticeLesson',
    'practiceSection',
    'modulePracticeSectionReview',
    'modulePracticeSectionReviewCompletion',
    'reviewQuestion'
  ];

  constructor(
    protected loggerService: LoggerService
  ) {}

  /**
   * Custom deserializer for NS API. Includes turning keys that are special to API into `Id`-appended keys,
   * so that the important key can be reserved for JSData's use. e.g. assessment.module returns an AssessmentModule
   * rather than a number.
   *
   * @param data
   * @returns {any}
   */
  deserialize(data) {
    // for simple strings, numbers, etc., just return -
    // most applicable when done during recursive call
    if (!data || typeof data !== 'object') {
      return data;
    }

    let deserialized = data;

    // make API's snake_case field keys be camelCase
    if (!Array.isArray(deserialized)) {
      deserialized = mapKeys(data, (value, key:string) => {
        return camelCase(key);
      });
    }

    // store ID values in `{resource}Id` field rather than `{resource}` field
    for (let i = 0; i < this.pkFieldNames.length; i++) {
      let key = this.pkFieldNames[i];

      if (typeof deserialized[key] !== 'undefined') {
        deserialized[key + 'Id'] = deserialized[key];

        delete deserialized[key];
      }
    }

    // handle nested data (e.g. bootstrap data's keys point to arrays of data that isn't yet camelcased, etc.)
    for (let key in deserialized) {

      // handle nested arrays, e.g. membership arrays during bootstrap
      if (typeof deserialized[key] === 'object' && Array.isArray(deserialized[key])) {
        for (let i = 0; i < deserialized[key].length; i++) {
          deserialized[key][i] = this.deserialize(deserialized[key][i]);
        }
      }

      // handle nested objects, e.g. `me` data during bootstrap
      if (typeof deserialized[key] === 'object' && !Array.isArray(deserialized[key])) {
        deserialized[key] = this.deserialize(deserialized[key]);
      }
    }

    return deserialized;
  }

  /**
   * Inverse of deserialize.
   *
   * @param data
   * @returns {any}
   */
  serialize(data) {
    for (let i = 0; i < this.pkFieldNames.length; i++) {
      let key = this.pkFieldNames[i];

      if (typeof data[key + 'Id'] !== 'undefined') {
        data[key] = data[key + 'Id'];

        delete data[key + 'Id'];
      }
    }

    let serialized = mapKeys(data, (value, key:string) => {
      return snakeCase(key);
    });

    for (let key in serialized) {
      // handle nested arrays, e.g. membership arrays during bootstrap
      if (typeof serialized[key] === 'object' && Array.isArray(serialized[key])) {
        for (let i = 0; i < (<Array<any>>serialized[key]).length; i++) {
          serialized[key][i] = this.serialize(serialized[key][i]);
        }
      }
    }

    return serialized;
  }

  /**
   * Takes a *single-level-deep* object and turns it into stringified query parameters for server. Currently used
   * to take assessment filters and send to a non-API server endpoint requesting CSV download with same parameters
   * as were used for API request with JSON response.
   *
   * @param paramsObject
   * @returns {string}
   */
  serializeQueryParams(paramsObject) {
    let urlParts = [];

    for (let key in paramsObject) {

      // modules: [1, 2, 3]  ===>   modules=1&modules=2&modules=3
      if (Array.isArray(paramsObject[key])) {
        each(paramsObject[key], eachValue => {
          urlParts.push(encodeURIComponent(snakeCase(key)) + '=' + encodeURIComponent(eachValue))
        });
      } else {
        urlParts.push(encodeURIComponent(snakeCase(key)) + '=' + encodeURIComponent(paramsObject[key]))
      }
    }

    return urlParts.join('&');
  }
}
