import {Component, OnInit, Input} from '@angular/core';
import {BaseSlideWrapperComponent} from "../base-slide-wrapper.component";

/**
 * Wrapper for assessment overview slides, e.g. introducing characters in an assessment. Has "Information Only"
 * text at top of the slide instead of a title, and a large (i) info icon in the background.
 */
@Component({
  selector: 'overview-slide-wrapper',
  templateUrl: './overview-slide-wrapper.component.html',
  styleUrls: [
    './_shared-slide-wrapper.component.scss',
    './overview-slide-wrapper.component.scss'
  ]
})
export class OverviewSlideWrapperComponent extends BaseSlideWrapperComponent {
  sources: string[] = [];
}
