import {Component, OnInit} from '@angular/core';
import {BaseTaskSlideComponent} from "./base-task-slide.component";
import {taskRecipes} from "../../app.config";
import {isEqual} from "lodash";
import {IMultipleChoiceOption} from "../interfaces/multiple-choice-option.interface";
import { translate } from '@ngneat/transloco';
import {IRecordedClick} from "../../core/engagement/interface-interaction-stager.service";

@Component({
  templateUrl: './base-task-slide.component.html'
})
export class BaseTaskRecipeMultipleChoiceMultipleSlide extends BaseTaskSlideComponent {
  recipeType = taskRecipes.multipleChoiceMultiple;
  showNextButton = true;

  /**
   * The # of selections to require before allowing `Next` button to be active.
   *
   * @type {number}
   */
  minNumSelectionsRequired: number = 1;

  /**
   * key => boolean pairs that populate the `selectedValues` and `correctValues` properties
   *
   * For example, to indicate that correct answer involves chrome = true and firefox = false:
   *
   * {
   *   "chrome": true,
   *   "firefox": false
   * }
   *
   *
   * @type {}
   */
  protected valueOptions = {};

  /**
   * Programmatic way of populating template with options and providing component w/logic for handling.
   * Replaces need for `valueOptions`. More evolved and DRY way of handling
   * hotspots in that template can then just use ngFor loop rather than each individual anchor, but may want to
   * use `valueOptions` property instead of `taskOptions` if ngFor loop is too simplistic for some reason.
   *
   * taskOptions: IMultipleChoiceOption[] = [
   *     {
   *       id: 'dotcom',
   *       label: 'spoonflower.dotcom'
   *     },
   *     {
   *       id: 'org',
   *       label: 'www.pbs.org',
   *       valid: true
   *     },
   *     {
   *       id: 'edu',
   *       label: 'd.umn.edu',
   *       valid: true
   *     },
   *     {
   *       id: 'fed',
   *       label: 'www.hhs.fed'
   *     }
   *   ];
   *
   *
   * @type {Array}
   */
  taskOptions: IMultipleChoiceOption[] = [];

  selectedValues: any = {};
  correctValues: string[] = [];

  protected setupRecipeMultipleChoiceMultiple() {
    if (this.taskOptions.length > 0) {
      this.setUpViaTaskOptions();
      return;
    }

    this.setUpViaValueOptions();
  }

  protected setUpViaTaskOptions() {
    const taskOptionsLength = this.taskOptions.length;

    for (let i = 0; i < taskOptionsLength; i++) {
      let option = this.taskOptions[i];

      this.selectedValues[option.id] = false;

      if (typeof option.valid !== 'undefined' && option.valid) {
        this.correctValues.push(option.id);
      }
    }
  }

  protected setUpViaValueOptions() {
    for (let key in this.valueOptions) {
      this.selectedValues[key] = false;

      if (this.valueOptions[key]) {
        this.correctValues.push(key);
      }
    }
  }

  toggleSelect(event: MouseEvent, key: string) {
    event.preventDefault();

    this.selectedValues[key] = !this.selectedValues[key];
  }

  handleClickNext() {
    if (!this.hasRequiredNumSelections()) {
      this.toastr.error(translate('common.selectAdditionalResponses'));

      return false;
    }

    this.responseCollectionService.addResponse(
      this.constructor,
      this.navigationService.getTaskForSlideComponentInstance(this),
      this.correctAnswersSelected()
    )
  }

  correctAnswersSelected() {
    let selectedValuesArray = [];

    for (let key in this.selectedValues) {
      if (this.selectedValues[key]) {
        selectedValuesArray.push(key);
      }
    }

    return isEqual(selectedValuesArray.sort(), this.correctValues.sort());
  }

  nextIsDisabled() {
    return !this.hasRequiredNumSelections();
  }

  protected hasRequiredNumSelections() {
    let numValuesSelected = 0;

    for (let key in this.selectedValues) {
      if (this.selectedValues[key]) {
        numValuesSelected++;
      }
    }

    return numValuesSelected >= this.minNumSelectionsRequired;
  }

  /**
   * In cases where hotspots spanning various components may be desirable to select, set it up.
   *
   * e.g. <phone-safari> component hotspots and ng-content hotspots
   */
  protected configureAllHotspotsSelectable() {
    this.subscriptions['stager'] = this.stagerService.recordedClick$.subscribe((recordedClick: IRecordedClick) => {

      const selectedClass= 'hotspot-multi-select-highlighted';
      const element = recordedClick.event.target;

      if (recordedClick.event.target.classList.contains(selectedClass)) {
        this.renderer2.removeClass(element, selectedClass);
        this.renderer2.setAttribute(element, 'aria-pressed', 'false');
      } else {
        this.renderer2.addClass(element, selectedClass);
        this.renderer2.setAttribute(element, 'aria-pressed', 'true');
      }
    });
  }
}
