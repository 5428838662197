  import {Injectable, Inject} from "@angular/core";
  import {APP_CONFIG, IAppConfig} from "../../app.config";

@Injectable()
export class LoggerService {
  constructor(
    @Inject(APP_CONFIG) protected appConfig: IAppConfig
  ) {}

  log(content) {
    if (!this.appConfig.debug && !this.appConfig.saveLogsToTrackJs) {
      return;
    }

    window.console && console.log(content);
  }

  warn(content) {
    if (!this.appConfig.debug) {
      return;
    }

    if (!window.console.warn) {
      this.log(content);
      return;
    }

    console.warn(content);
  }

  error(content) {
    if (!this.appConfig.debug) {
      return;
    }

    if (!window.console.error) {
      this.log(content);
      return;
    }

    console.error(content);
  }
}
