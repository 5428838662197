import {Injectable} from "@angular/core";
import {ProctorSessionForUser} from "northstar-foundation";
import {ApiService, apiEndpoints} from "./api.service";
import {ToastrService} from "ngx-toastr";
import {LoggerService} from "./logger.service";
import {CustomDataStore} from "../data-store/custom-data-store.service";
import {AssessmentTokenService} from "./assessment-token.service";

/**
 * Placeholder for cases where an assessment was saved as unproctored but then toggled to be proctored via admin portal,
 * need a placeholder value for UI purposes.
 *
 * @type {{}}
 */
export const placeholderProctorSessionForUser: ProctorSessionForUser = new ProctorSessionForUser({
  id: 0,
  start: (new Date()),
  end: (new Date()),
  sponsor: null,
  sponsorId: 0,
  proctorSession: null,
  pendingSession: null,
  proctorSessionId: 0,
  token: '',
  status: 'inactive',
  userName: '',
  user: null,
  userId: 0,
  startMethod: '',
  endMethod: '',
  currentModule: null,
  currentModuleStart: (new Date()),
  assessments: []
});

/**
 * Holder of proctor session for test taker. Keeps session alive while viewing assessment in case user closed
 * their launch screen, and also confirms that proctor hasn't canceled proctor mode.
 */
@Injectable()
export class ProctorSessionForUserService {
  static timers = {
    // TODO do this based on expires value from token instead
    keepSessionAlive: 60 * 10,
    checkActiveSessionStatus: 15
  };

  session: ProctorSessionForUser;

  timerForCheckActiveSessionStatus: number = null;
  timerForKeepAliveActiveSession: number = null;

  constructor(
    protected apiService: ApiService,
    protected toastr: ToastrService,
    protected loggerService: LoggerService,
    protected dataStore: CustomDataStore,
    protected assessmentTokenService: AssessmentTokenService,
  ) {
  }

  _setSession(session: ProctorSessionForUser) {
    this.session = <ProctorSessionForUser>this.dataStore.add('proctor_session_for_user', session);
  }

  setSession(session: ProctorSessionForUser) {
    this._setSession(session);

    // this.loggerService.log(['Setting ProctorSessionForUser', this.session]);

    this.listenForActiveUserSessionStatus();
    this.keepSessionAliveOnInterval();
  }

  getSession() {
    return this.session;
  }

  listenForActiveUserSessionStatus() {
      clearTimeout(this.timerForCheckActiveSessionStatus);
       (<any>this.apiService.httpGet(apiEndpoints.checkStatusProctorSessionForUser, {
                  map: {
                      sessionId: this.session.id,
                      sessionToken: this.session.token
                  }
          })).toPromise()
             .then(this.onActiveUserSessionRetrieve.bind(this))
             .catch(this.onActiveUserSessionRetrieveError.bind(this));
  }

  onActiveUserSessionRetrieve(sessionResponse: ProctorSessionForUser) {
    this._setSession(sessionResponse);

    if (sessionResponse.status !== 'active') {
      this.onProctorModeCanceled(sessionResponse);
      return;
    }

    this.timerForCheckActiveSessionStatus = setTimeout(() => {
        this.listenForActiveUserSessionStatus();
    }, ProctorSessionForUserService.timers.checkActiveSessionStatus * 1000)
  }

  onActiveUserSessionRetrieveError(response) {
      this.toastr.warning(`There was an error checking whether you are still in proctor mode. Please discuss with your proctor.`, null, {
          timeOut: 0,
          extendedTimeOut: 0
      });
  }

  onProctorModeCanceled(sessionResponse: ProctorSessionForUser) {
    clearTimeout(this.timerForCheckActiveSessionStatus);
    clearTimeout(this.timerForKeepAliveActiveSession);

    const endMethodExplanations = {
        proctor: 'The proctor ended your session.',
        mod_off: 'The proctor is no longer available for proctoring.',
        expire: 'Your session was too idle and expired.',
        user: 'You canceled the session.'
    };

    this.session = null;

    this.toastr.warning(`Your proctor session has ended. ${endMethodExplanations[sessionResponse.endMethod]} You may complete the assessment as unproctored.`, null, {
        timeOut: 0,
        extendedTimeOut: 0
    });

    this.assessmentTokenService.fetchNewUnproctored();
  }

  keepSessionAliveOnInterval() {
      clearTimeout(this.timerForKeepAliveActiveSession);
       (<any>this.apiService.httpPost(apiEndpoints.keepProctorSessionForUserAlive, {
              token: this.session.token
           },
           {
              map: {
                  sessionId: this.session.id
              }
          })).toPromise()
             .then(this.onActiveSessionKeptAliveSuccess.bind(this));
  }

  onActiveSessionKeptAliveSuccess(response) {
      this.timerForKeepAliveActiveSession = setTimeout(() => {
          this.keepSessionAliveOnInterval();
      }, ProctorSessionForUserService.timers.keepSessionAlive * 1000)
  }
}
