import {Component, OnDestroy, HostListener} from '@angular/core';
import {TransparentBackdropService} from "./transparent-backdrop.service";
import {Router, NavigationEnd} from "@angular/router";
import {Subscription} from "rxjs";

/**
 * Transparent div filling screen to react to clicks anywhere. For example,
 * when backdrop clicked, hide navigation menu.
 *
 * See also TransparentBackdropService.
 */
@Component({
  selector: 'transparent-backdrop',
  templateUrl: './transparent-backdrop.component.html',
  styleUrls: ['./transparent-backdrop.component.scss']
})
export class TransparentBackdropComponent implements OnDestroy {
  routerSubscription: Subscription;

  constructor(
    public transparentBackdropService: TransparentBackdropService,
    protected router: Router
  ) {
    this.routerSubscription = this.router.events.subscribe(this.onRouterEvents.bind(this))
  }

  disable(event: MouseEvent) {
    this.transparentBackdropService.disable();
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyPress(event: KeyboardEvent) {

    const keyPressed = event.key;

    if (keyPressed === 'Escape' && this.transparentBackdropService.enabled.value.status) {
      event.preventDefault();
      this.transparentBackdropService.disable();
    }
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

  /**
   * Auto-disable backdrop when switching pages, rather than, for example, the navigation menu
   * needing to manually call the disable method each time.
   *
   * @param routerValue
   */
  onRouterEvents(routerValue) {
    if (routerValue instanceof NavigationEnd) {
      this.transparentBackdropService.disable();
    }
  }
}
