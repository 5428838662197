import {Injectable, Inject} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {ApiService, apiEndpoints} from "../services/api.service";
import {SessionService} from "../services/session.service";
import {CustomDataStore} from "../data-store/custom-data-store.service";
import {LoggerService} from "../services/logger.service";
import {
  AssessmentModule,
  ModuleTopic,
  ModuleSoftwareVersion,
  ModuleTopicCategory
} from "northstar-foundation";
import {of} from "rxjs";
import {switchMap, catchError} from "rxjs/operators";
import {APP_CONFIG, IAppConfig} from "../../app.config";


export interface IApiModulesResponse {
  topics: ModuleTopic[],
  topicCategories: ModuleTopicCategory[],
  modules: AssessmentModule[],
  softwareVersions: ModuleSoftwareVersion[]
}

@Injectable()
export class ModulesResolve implements Resolve<any> {

  constructor(protected loggerService: LoggerService,
              protected dataStore: CustomDataStore,
              public sessionService: SessionService,
              protected apiService: ApiService,
              @Inject(APP_CONFIG) public appConfig: IAppConfig,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot) {
    if (this.appConfig.apiMocks) {
      return of({
        topics: this.dataStore.add('module_topic', this.appConfig.apiMocks.moduleTopics),
        topicCategories: this.dataStore.add('module_topic_category', this.appConfig.apiMocks.moduleTopicCategories),
        modules: this.dataStore.add('module', this.appConfig.apiMocks.modules),
        softwareVersions: this.dataStore.add('module_software_version', this.appConfig.apiMocks.moduleSoftwareVersions)
      })
    }

    return this.apiService.httpGet(apiEndpoints.modules)
                          .pipe(
                            switchMap(this.onResponse.bind(this)),
                            catchError(this.onError.bind(this))
                          );
  }

  onResponse(response: IApiModulesResponse) {
    return of({
      topics: this.dataStore.add('module_topic', response.topics),
      topicCategories: this.dataStore.add('module_topic_category', response.topicCategories),
      modules: this.dataStore.add('module', response.modules),
      softwareVersions: this.dataStore.add('module_software_version', response.softwareVersions)
    });
  }

  onError(response) {
    return of(false);
  }
}
