import {
  Component, ViewChild, AfterViewInit, Output, EventEmitter, ViewEncapsulation, ElementRef,
  Renderer2
} from '@angular/core';
import * as YouTubePlayer from 'youtube-player';
import {ToastrService} from 'ngx-toastr';
import {MediaQueryService} from "../../../core/environment/media-query.service";
import {TranslocoService} from "@ngneat/transloco";
import {ResponsiveVideoWrapperComponent} from "../../layout/ui-helpers/responsive-video-wrapper.component";

@Component({
  selector: 'orientation-video',
  templateUrl: './orientation-video.component.html',
  styleUrls: [
    './orientation-video.component.scss'
  ],
  // allow styles to apply to dynamically inject content from YouTube API,
  // otherwise CSS for iframe doesn't get applied because Angular didn't insert it w/a ng attribute
  encapsulation: ViewEncapsulation.None
})
export class OrientationVideoComponent implements AfterViewInit {
  static secondsBeforeEndConsideredFinished = 5;

  player: YouTubePlayer = null;
  videoDuration: number;

  @Output('nearFinished')
  nearFinished: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(ResponsiveVideoWrapperComponent, {read: ElementRef})
  responsiveVideoWrapperComponent: ElementRef;

  constructor(
    protected toastr: ToastrService,
    protected mediaQueryService: MediaQueryService,
    protected translocoService: TranslocoService,
    protected renderer: Renderer2,
  ){}

  ngAfterViewInit() {
    const videoIdForLang = {
      en: {
        mobile: 'hP7dHhD0gnU',
        desktop: 'NvVZfk0wXro',
      },
      es_419: {
        mobile: 'vDeqMi_pFSo',
        desktop: 'vDeqMi_pFSo',
      }
    };

    const shortScreen: boolean = this.mediaQueryService.screenShort();

    const innerPlayerWrapper = this.renderer.createElement('div');
    this.renderer.appendChild(this.responsiveVideoWrapperComponent.nativeElement, innerPlayerWrapper);

    const useMobileVideo = this.mediaQueryService.mediaBreakpointDown.md;

    this.player = YouTubePlayer(innerPlayerWrapper, {
      width: shortScreen ? 640 : 853,
      height: shortScreen ? 360 : 480,
      videoId: videoIdForLang[this.translocoService.getActiveLang()][useMobileVideo ? 'mobile' : 'desktop'],
      playerVars: {
        autoplay: 1,
        // don't show related videos
        rel: 0,
        showinfo: 0
      }
    });

    (<any>this.player).getDuration()
                      .then(duration => {
      this.videoDuration = duration;
    });

    const checkTimeInterval = setInterval(() => {
      (<any>this.player).getCurrentTime()
                        .then(this.onCurrentVideoTimeChecked.bind(this, checkTimeInterval));

    }, 1000);
  }

  onCurrentVideoTimeChecked(jsInterval, timeSeconds) {
    const videoNearFinished =
      timeSeconds > this.videoDuration - OrientationVideoComponent.secondsBeforeEndConsideredFinished;

    if (videoNearFinished) {
      this.onVideoNearFinished();
      clearInterval(jsInterval);
    }
  }

  onVideoNearFinished() {
    this.nearFinished.emit(null);
  }

}
