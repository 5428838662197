import {Injectable} from "@angular/core";
import {CanActivate, CanActivateChild, RouterStateSnapshot, ActivatedRouteSnapshot} from "@angular/router";
import {SlideNavigationService} from "../engagement/slide-navigation.service";
import {ToastrService} from 'ngx-toastr';
import { translate } from "@ngneat/transloco";

@Injectable()
export class CanActivateSlide implements CanActivate, CanActivateChild {

  constructor(
    protected slideNavigationService: SlideNavigationService,
    protected toastr: ToastrService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return true;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const canActivate = this.slideNavigationService.canActivateSlide(route.component);

    if (!canActivate) {
      this.toastr.error(translate('common.unableToViewThePage'));
    }

    return canActivate;
  }
}
