const currentYear = (new Date()).getFullYear();

/**
 * Params that are always available to the title's l10n text as params.
 *
 * cf. https://ngneat.github.io/transloco/docs/translation-api
 *
 * @type {{currentYear: number}}
 */
export const baseL10nParams = {
  currentYear: currentYear,
  lastYear: currentYear - 1,
  twoYearsAgo: currentYear - 2
};
