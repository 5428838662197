import {Component, AfterViewInit} from "@angular/core";
import {ResponseCollectionService} from "../../../core/engagement/response-collection.service";
import {SlideNavigationService} from "../../../core/engagement/slide-navigation.service";
import {ModulePracticeSection} from "northstar-foundation";

@Component({
  templateUrl: 'section-review-entry.component.html',
  styleUrls: ['section-review-entry.component.scss']
})
export class PracticeSectionReviewEntryComponent implements AfterViewInit {
  constructor(
    protected responseCollectionService: ResponseCollectionService,
    protected navigation: SlideNavigationService
  ) {
  }

  ngAfterViewInit() {
    this.responseCollectionService.activateNamespace(
      this.responseCollectionService.getNamespaceForSectionReview(
        <ModulePracticeSection>this.navigation.getCurrentSection()
      )
    );
  }
}
