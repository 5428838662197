import {Injectable, Inject} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {ApiService, apiEndpoints} from "../services/api.service";
import {SessionService} from "../services/session.service";
import {CustomDataStore} from "../data-store/custom-data-store.service";
import {LoggerService} from "../services/logger.service";
import {MeResolve} from "./me.resolve";
import {User, ModulePracticeUserCompletion, ModulePracticeLesson} from "northstar-foundation";
import {SlideNavigationService} from "../engagement/slide-navigation.service";
import {ToastrService} from 'ngx-toastr';
import {IAppConfig, APP_CONFIG} from "../../app.config";
import {of} from "rxjs";
import {switchMap, catchError} from "rxjs/operators";
import {GoogleTagManagerService} from "northstar-foundation/angular";
import {TranslocoService} from "@ngneat/transloco";
import {Angulartics2} from 'angulartics2';

declare var trackJs: any;

@Injectable()
export class ModulePracticeLessonCompleteResolve implements Resolve<any> {

  constructor(protected loggerService: LoggerService,
              protected dataStore: CustomDataStore,
              public sessionService: SessionService,
              protected apiService: ApiService,
              protected meResolver: MeResolve,
              protected slideNavigation: SlideNavigationService,
              protected toastr: ToastrService,
              protected angulartics2: Angulartics2,
              protected gtmService: GoogleTagManagerService,
              protected translocoService: TranslocoService,
              @Inject(APP_CONFIG) public appConfig: IAppConfig
  ) {
  }

  ga4LessonCompleteEventData: any = {};

  resolve(route: ActivatedRouteSnapshot) {
    // record which lesson user is currently active on now, prior to async call to server - lesson may have synchronously
    // switched by the time the server responds
    const practiceLesson: ModulePracticeLesson|boolean = this.slideNavigation.getLessonForCurrentComponent();
    const sponsor = this.sessionService.getSponsor();

    if (practiceLesson) {
      this.ga4LessonCompleteEventData = {
        module: (<ModulePracticeLesson>practiceLesson).moduleId,
        lesson: (<ModulePracticeLesson>practiceLesson).id,
        location: sponsor ? sponsor.id : 0,
        lang: this.translocoService.getActiveLang(),
      };

      // track all lesson completions for logged-out users; see further below for logged-in tracking
      if (!this.sessionService.userIsLoggedIn()) {
        this.gtmService.trackEvent('lesson_complete', this.ga4LessonCompleteEventData);
      }
    }

    return this.meResolver.resolve(route).pipe(
        switchMap(this.onMeResolveComplete.bind(this, practiceLesson))
    );
  }

  onMeResolveComplete(practiceLesson: ModulePracticeLesson|boolean, me: User|any) {
    if (!this.sessionService.userIsLoggedIn()) {
      return of(false);
    }

    if (!practiceLesson) {
      return of(false);
    }

    const completionData = {
      userId: this.sessionService.user.id,
      modulePracticeLessonId: (<ModulePracticeLesson>practiceLesson).id
    };

    const previousCompletions = this.dataStore.filter('module_practice_user_completion', completionData);

    if (previousCompletions.length > 0) {
      return of(previousCompletions[0]);
    }

    return this.apiService.httpPostWithRetry(apiEndpoints.recordPracticeLesson, completionData)
                          .pipe(
                            switchMap(this.onResponse.bind(this)),
                            catchError(this.onError.bind(this)),
                          );
  }

  onResponse(response) {
    const completion = <ModulePracticeUserCompletion>this.dataStore.add('module_practice_user_completion', response);

    if (this.appConfig.debug) {
      this.toastr.success(`Saved completion for ${completion.lesson.title}`);
    }

    // only save initial lesson completion for logged-in users
    this.gtmService.trackEvent('lesson_complete', this.ga4LessonCompleteEventData);

    this.angulartics2.eventTrack.next({
      action: 'Complete',
      properties: {
        category: 'NSOL Lesson',
        label: `${completion.lesson.id}`
      }
    });

    return of(completion);
  }

  onError(response) {
    this.loggerService.log(response);

    const problemRecordingMsg = `There was a problem recording your completion of the lesson.`;
    this.toastr.warning(problemRecordingMsg);

    if (typeof trackJs !== 'undefined') {
      trackJs.track(problemRecordingMsg);
    }

    return of(false);
  }
}
