import {Directive, TemplateRef, ViewContainerRef, NgZone} from "@angular/core";

/**
 * After initial bind, stop continuous watching for changes.
 *
 * https://github.com/angular/angular/issues/14033#issuecomment-307378389
 */
@Directive({
  selector: '[oneTimeBind]',
})
export class OneTimeBindDirective {
  constructor(template: TemplateRef<any>, container: ViewContainerRef, zone: NgZone) {
    zone.runOutsideAngular(() => {
      const view = container.createEmbeddedView(template);
      setTimeout(() => view.detach());
    })
  }
}
