import {Component, HostBinding} from "@angular/core";
import {TranslocoService} from "@ngneat/transloco";

/**
 * Close button for use in mobile modals like other-slides-navigation, photo credits.
 */
@Component({
  selector: 'mobile-close',
  templateUrl: './mobile-close.component.html',
  styleUrls: ['./mobile-close.component.scss']
})
export class MobileCloseComponent {
  @HostBinding('title')
  @HostBinding('attr.aria-label')
  text: string = this.translocoService.translate('common.close');

  @HostBinding('tabindex')
  tabIndex: number = 0;

  @HostBinding('attr.role')
  role: string = 'button';

  constructor(
    protected translocoService: TranslocoService
  ) {
  }
}
