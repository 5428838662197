import {Injectable} from "@angular/core";
import {ApiService, apiEndpoints} from "./api.service";
import {SessionService} from "./session.service";
import {LoggerService} from "./logger.service";

interface IGetAssessmentTokenResponse {
  token: string
}

/**
 * Holder of token that will be used to ID test taker info, proctor, testing location when submitting assessment.
 */
@Injectable()
export class AssessmentTokenService {

  token: string;

  constructor(
    protected apiService: ApiService,
    protected sessionService: SessionService,
    protected loggerService: LoggerService,
  ) {}

  setToken(token: string) {
    this.token = token;
  }

  getToken() {
    return this.token;
  }

  /**
   * Get non-PII part of unique token.
   *
   * @param token
   * @returns {string}
   */
  static getTokenHash(token: string) {
    return token.split('-')[0];
  }

  /**
   * Grab new token from server in order to pass validation at end of assessment. Used only in cases where
   * a proctor mode has been canceled.
   */
  fetchNewUnproctored() {
    (<any>this.apiService.httpPost(apiEndpoints.getAssessmentToken, {
      user: this.sessionService.userIsLoggedIn() ? this.sessionService.user.id : this.sessionService.getUserFullName(),
      testingLocation: this.sessionService.sponsor.id
    })).subscribe(
      (response: IGetAssessmentTokenResponse) => {
        this.setToken(response.token);
        this.loggerService.log(['Updated assessment token to unproctored.', AssessmentTokenService.getTokenHash(this.token)]);
      }
    );
  }
}
