import {Component, OnInit} from '@angular/core';
import {CaptionsSettingsService} from "../../../core/settings/captions-settings.service";
import {FadeInOut} from "../../animations";
import {AudioSettingsService} from "../../../core/settings/audio-settings.service";

@Component({
  selector: 'cc-overlay',
  templateUrl: './cc-overlay.component.html',
  styleUrls: ['./cc-overlay.component.scss'],
  animations: [FadeInOut()]
})
export class CCOverlayComponent implements OnInit {

  constructor(
    public captionsSettingsService: CaptionsSettingsService,
    public audioSettings: AudioSettingsService
  ) {

  }

  ngOnInit() {
  }
}
