import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {SlideNavigationService} from "../../../core/engagement/slide-navigation.service";
import {LoggerService} from "../../../core/services/logger.service";

@Component({
  selector: 'confirm-retry-overlay',
  templateUrl: './confirm-retry-overlay.component.html',
  styleUrls: [
    './_shared-controls-button.scss',
    './confirm-retry-overlay.component.scss'
  ]
})
export class ConfirmRetryOverlayComponent implements OnInit {
  @Input() userRetryResponseHandler: any;

  hasViewedAllQuestions: boolean = false;

  constructor(
    protected slideNavigationService: SlideNavigationService,
    protected loggerService: LoggerService
  ){}

  ngOnInit() {
    this.hasViewedAllQuestions = this.slideNavigationService.allSlidesVisited();
  }

  handleUserResponse(response, event) {
    this.userRetryResponseHandler(response);
    event.preventDefault();
    event.stopImmediatePropagation();
  }
}
