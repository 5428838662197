import {Component, OnInit} from '@angular/core';
import {includes} from "lodash";
import {BaseTaskRecipeMultipleChoiceMultipleSlide} from "./base-task-recipe-multiple-choice-multiple-slide.component";

@Component({
  templateUrl: './base-task-slide.component.html'
})
export class BaseTaskRecipeMultipleChoicePartialAcceptedSlide extends BaseTaskRecipeMultipleChoiceMultipleSlide {

  /**
   * Allow user to get credit for selecting (for example) 3 only out of 4 correct options,
   * but don't penalize 4 out of 4 correct.
   *
   * @returns {boolean}
   */
  correctAnswersSelected() {
    let numCorrectAnswers = 0;

    for (let key in this.selectedValues) {
      // skip options user didn't select
      if (!this.selectedValues[key]) {
        continue;
      }

      // is selected answer correct
      if (includes(this.correctValues, key)) {
        numCorrectAnswers++;
        continue;
      }

      // any incorrect answer negates credit
      return false;
    }

    return numCorrectAnswers >= this.minNumSelectionsRequired;
  }
}
