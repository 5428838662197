const isSponsorVersion = true;

export const environment = {
  production: true,
  debug: false,
  mainSiteBase: 'https://staging-lti13.digitalliteracyassessment.org/',
  apiBase: 'https://staging-lti13.digitalliteracyassessment.org/api/',
  adminPortalBase: 'https://admin.staging-lti13.digitalliteracyassessment.org/',
  isSponsorVersion: isSponsorVersion,
  isPublicVersion: !isSponsorVersion,
  assetsFolder: '/assets/',
  saveLogsToTrackJs: true,
  apiMocks: null
};
