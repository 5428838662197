import {Component, OnInit, Input, HostBinding} from '@angular/core';
import {FadeInOut, FadeOut} from "../../animations";
import {BaseSlideWrapperComponent} from "../base-slide-wrapper.component";

/**
 * Slide wrapper for assessment tasks/questions. Has progress bar and retry-overlay when appropriate.
 */
@Component({
  selector: 'task-slide-wrapper',
  templateUrl: './task-slide-wrapper.component.html',
  styleUrls: ['./_shared-slide-wrapper.component.scss'],
  animations: [FadeInOut(500), FadeOut(250)]
})
export class TaskSlideWrapperComponent extends BaseSlideWrapperComponent {
}
