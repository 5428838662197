import {Resolve, ActivatedRouteSnapshot, Router} from "@angular/router";
import {Injectable, Inject} from "@angular/core";
import {CustomDataStore} from "../data-store/custom-data-store.service";
import {IAppConfig, APP_CONFIG} from "../../app.config";
import {LoggerService} from "../services/logger.service";
import {of} from "rxjs";

@Injectable()
export class MocksForDevResolve implements Resolve<any> {

  constructor(
    protected router: Router,
    @Inject(APP_CONFIG) protected appConfig: IAppConfig,
    protected dataStore: CustomDataStore,
    protected loggerService: LoggerService,
  ) {
  }

  resolve(route:ActivatedRouteSnapshot) {
    if (!this.appConfig.apiMocks) {
      return of(true);
    }

    this.dataStore.add('module', this.appConfig.apiMocks.modules);
    this.dataStore.add('module_topic', this.appConfig.apiMocks.moduleTopics);
    this.dataStore.add('module_software_version', this.appConfig.apiMocks.moduleSoftwareVersions);
    return of(true);
  }
}
