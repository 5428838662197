import {kebabCase} from "lodash";
import {L10nNode} from "../../core/i18n/l10n-node";

/**
 * For a given assessment/NSOL slide's component ID, strip its boilerplate prefix and suffix
 * to find the unique name, and turn to kebab case. SlideFooBarComponent -> foo-bar
 *
 * @param componentId
 * @returns {string}
 */
export function getKebabCoreNameForComponent(componentId) {
  const coreName = getCoreNameForComponent(componentId);
  return kebabCase(coreName);
}


/**
 * For a given assessment/NSOL slide's component ID, strip its boilerplate prefix and suffix
 * to find the unique name.
 *
 * @param componentId
 * @returns {string}
 */
export function getCoreNameForComponent(componentId) {
  const prefix = 'Slide';
  const suffix = 'Component';

  const prefixRegex = new RegExp('^' + prefix);
  const suffixRegex = new RegExp(suffix + '$');

  return componentId.replace(prefixRegex, '').replace(suffixRegex, '');
}


/**
 * For a given slide's component, provide the Transloco path. Note that this is only a partial path, because
 * the prefix to this relative path is not known without more context.
 *
 * e.g.
 *
 * @param nsComponentId     The slide component ID whose l10n path is being requested. e.g. SlideBrowserClickBackComponent
 * @returns {string|string}
 */
export function getL10NPathForSlideContent(nsComponentId: string) {
  return (new L10nNode(getKebabCoreNameForComponent(nsComponentId))).getL10NPathForSlideContent();
}


/**
 * Convenience method for common string processing.
 * 
 * str is:
 *    Set empty if null
 *    Trimmed.
 *    Converted to lowercase.
 *    Spaces, commas and dashes are removed.
 *    Normalized to remove diacritic characters. 
 *       https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize
 * 
 * Returns:
 *    non-null string
 *    
 * @param str
 */
export function trimCleanLowerNormalize(str: string): string {
  const spacesCommasDashes = /[\s,-]/g;
  str = str ? str : "";
  return str.toLowerCase().replace(spacesCommasDashes, '').normalize("NFD").replace(/\p{Diacritic}/gu, "");
}
