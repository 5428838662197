import {Component, OnInit, Input, Output, EventEmitter, Inject} from '@angular/core';
import {SlideNavigationService} from "../../../core/engagement/slide-navigation.service";
import {IModuleTask, IAppConfig, APP_CONFIG} from "../../../app.config";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from 'ngx-toastr';
import {ModulePracticeSectionReview} from "northstar-foundation";
import {SessionService} from "../../../core/services/session.service";
import {translate} from "@ngneat/transloco";

@Component({
  selector: 'section-review-nav-link',
  templateUrl: './section-review-nav-link.component.html',
  styleUrls: [
    './section-review-nav-link.component.scss'
  ]
})
export class SectionReviewNavLinkComponent {
  @Input() sectionReview: ModulePracticeSectionReview;

  @Output('onToggleSelf')
  toggleSelf: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    protected navigation: SlideNavigationService,
    protected activatedRoute: ActivatedRoute,
    public sessionService: SessionService,
    protected router: Router,
    protected toastr: ToastrService,
    @Inject(APP_CONFIG) protected appConfig: IAppConfig
  ) {}

  goIfAllowed(routerLinkParts: string[]) {
    const sectionTitle = this.sectionReview.section.title;

    if (!this.sessionService.userIsLoggedIn()) {
      if (this.appConfig.debug) {
        this.toastr.warning('Allowing access since in debug - normally would need to be logged in and completed each lesson.');
        this.router.navigate(routerLinkParts);
        return;
      }

      this.toastr.warning(translate('common.needLoginForSectionReview', {title: sectionTitle}));
      return;
    }

    if (!this.sectionReview.section.lessonsCompletedByUser(this.sessionService.user.id)) {
      if (this.appConfig.debug) {
        this.toastr.warning('Allowing access since in debug.');
        this.router.navigate(routerLinkParts);
        return;
      }

      this.toastr.warning(translate('common.completeRemainingLessons', {title: sectionTitle}));
      return;
    }

    this.router.navigate(routerLinkParts);
  }
}
