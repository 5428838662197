import {Component, OnInit, ElementRef} from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import {AssetService} from "../../../core/services/asset.service";
import {VgAPI} from "videogular2/core";
import {BootstrapDataService} from "../../../core/bootstrap/bootstrap-data.service";
import {LoggerService} from "../../../core/services/logger.service";
import {MediaQueryService} from "../../../core/environment/media-query.service";

@Component({
  templateUrl: './audio-setup.component.html',
  styleUrls: [
    './audio-setup.component.scss',
    '../../layout/slide-wrapper/_shared-slide-wrapper.component.scss',
    '../../layout/slide-wrapper/next-button.component.scss',
    '../../layout/slide-wrapper/_shared-controls-button.scss',
    '../../layout/slide-wrapper/_shared-button-types.scss',
    '../../layout/slide-wrapper/i-dont-know-button.component.scss',
  ]
})
export class AudioSetupComponent implements OnInit {
  static nsComponentId: string = 'AudioSetupComponent';

  l10nBasePath: string = 'shared.slides.audioSetup.';

  showTooltip: boolean = false;
  api: VgAPI;
  sources: any[];
  customHandleClickNextHandler: any;

  onSoundPlayingTriggered: boolean = false;
  audioIsPlaying: boolean = false;
  audioHasPlayedForSpecifiedTime: boolean = false;

  /**
   * Flag to display different content on browsers/devices that have autoplay disabled. Testing is based on fact
   * that in Safari the audio is quickly started (due to autoplay in HTML) and stopped (due to anti-autoplay Safari policies),
   * so testing whether it's still playing moments after auto-started
   *
   * @type {boolean}
   */
  autoPlayStopped: boolean = false;

  autoPlayDisabled: boolean = false;

  constructor(
    public el: ElementRef,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    public assetService: AssetService,
    protected loggerService: LoggerService,
    public mediaQueryService: MediaQueryService,
  ) {
    this.customHandleClickNextHandler = this.customHandleClickNext.bind(this)
  }

  ngOnInit() {
    const audioPath = `shared/bach-cello-24kbps`;

    this.sources = [
      {
        src: this.assetService.getAudio(audioPath),
        type: 'audio/mp3'
      }
    ];
  }

  getComponentId() {
    return AudioSetupComponent.nsComponentId;
  }

  onPlayerReady(api:VgAPI) {
    this.api = api;

    // after a short period of time, playing should have started if it's allowed
    setTimeout(() => {
      if (!this.onSoundPlayingTriggered) {
        this.autoPlayDisabled = true;
      }
    }, 1000);

    this.api.getDefaultMedia().subscriptions.playing.subscribe(this.onSoundPlaying.bind(this));
    this.api.getDefaultMedia().subscriptions.pause.subscribe(this.onSoundPaused.bind(this));
    this.api.getDefaultMedia().subscriptions.ended.subscribe(this.onSoundEnded.bind(this));
  }

  togglePlay() {
    if (!this.audioIsPlaying) {
      if (this.api.canPlay) {
        this.api.play();
      }
    } else {
      this.api.pause();
    }

    this.audioIsPlaying = !this.audioIsPlaying;
  }

  onSoundPlaying() {
    this.onSoundPlayingTriggered = true;
    this.audioIsPlaying = true;

    // test whether audio continues to play for a very short bit before assuming it'll continue playing;
    // was having behavior on Safari desktop that `playing` event was immediately followed by `pause` event
    // due to Safari policies - https://webkit.org/blog/7734/auto-play-policy-changes-for-macos/
    if (!this.audioHasPlayedForSpecifiedTime) {
      setTimeout(() => {
        if (this.audioIsPlaying) {
          this.audioHasPlayedForSpecifiedTime = true;
        }
      }, 100)
    }
  }

  onSoundPaused() {
    this.audioIsPlaying = false;

    if (!this.audioHasPlayedForSpecifiedTime) {
      this.autoPlayStopped = true;
    }
  }

  onSoundEnded() {
    this.audioIsPlaying = false;
    this.api.seekTime(0);
  }

  toggleSoundTooltip(event) {
    event.preventDefault();

    this.showTooltip = !this.showTooltip;

    if (this.showTooltip) {
      // need to delay slightly so that ngIf can react to the showTooltip boolean change, then query an element
      // within the node
      setTimeout(() => this.el.nativeElement.querySelector('.will-try-again').focus(), 1);
    } else {
      this.el.nativeElement.querySelector('.i-dont-have-sound').focus();
    }
  }

  customHandleClickNext() {
    this.router.navigate(['./orientation'], {relativeTo: this.activatedRoute});
  }
}
