import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {VgAPI} from "videogular2/core";
import {CaptionsSettingsService, referToContentCaptionKey} from "../../../core/settings/captions-settings.service";
import {LoggerService} from "../../../core/services/logger.service";
import {AudioSettingsService, ISpeedOption} from "../../../core/settings/audio-settings.service";
import {AssetService} from "../../../core/services/asset.service";
import {YouTubePlayerService} from "../../../core/services/youtube-player.service";
import {TranslocoService} from '@ngneat/transloco';
import {defaultsDeep} from "lodash";
import {TranslateFade} from "../../animations";
import {environment} from "../../../../environments/environment";
import {L10nNode} from "../../../core/i18n/l10n-node";
import {baseL10nParams} from "../../../core/i18n/constants";
import {MediaQueryService} from "../../../core/environment/media-query.service";

@Component({
  selector: 'replay-audio-button',
  templateUrl: './replay-audio-button.component.html',
  styleUrls: [
    './_shared-slide-button.scss',
    './replay-audio-button.component.scss'
  ],
  animations: [TranslateFade(10)]
})
export class ReplayAudioButtonComponent implements OnInit, OnDestroy {
  api: VgAPI;
  sources: any[];
  trackSource: string;
  subscriptions: any = {};

  downloadingAudio: boolean = false;
  showAdvancedControls: boolean = false;

  @Input() slug: string;
  @Input() l10nNode: L10nNode = null;
  @Input() audioVariant: 'youtube' = null;
  @Input() hasCaptions: boolean = true;

  constructor(
    protected captionsSettings: CaptionsSettingsService,
    public audioSettings: AudioSettingsService,
    protected loggerService: LoggerService,
    protected assetService: AssetService,
    protected youTubePlayerService: YouTubePlayerService,
    protected translocoService: TranslocoService,
    protected mediaQueryService: MediaQueryService,
  ) {
  }

  ngOnInit() {
    this.resetMedia();
    this.subscriptions['audioOn'] = this.audioSettings.$audioOn.subscribe(this.onAudioEnabledChange.bind(this));
    // this.loggerService.log(['AssessmentAudioPlayer sources', this.sources, this.trackSource]);
  }

  ngOnDestroy() {
    for (let key in this.subscriptions) {
      if (this.subscriptions.hasOwnProperty(key)) {
        this.subscriptions[key].unsubscribe();
      }
    }
  }

  onPlayerReady(api:VgAPI) {
    this.api = api;
    this.audioSettings.setAPI(this.api);

    this.subscriptions['error'] = this.api.getMasterMedia().subscriptions.error.subscribe(this.onError.bind(this));
    this.subscriptions['canPlayThrough'] = this.api.getMasterMedia().subscriptions.canPlayThrough.subscribe(this.onDataLoaded.bind(this));
    this.subscriptions['seeked'] = this.api.getMasterMedia().subscriptions.seeked.subscribe(this.onUserSeeked.bind(this));
    this.subscriptions['playbackSpeed'] = this.audioSettings.$playbackSpeed.subscribe(this.onPlaybackRateSettingChange.bind(this));

    let tracks = Array.from(this.api.getMasterMedia().elem.textTracks);

    tracks.forEach(function(track:any) {
      track.mode = 'hidden';
    });

    // respect previous slides' mute/unmute settings
    if (!this.audioSettings.audioOn.value) {
      this.audioSettings.muteMasterAudio();
    }

    this.api.playbackRate = this.audioSettings.playbackSpeed.value.rate;
  }

  onEnterCuePoint(vttCue) {
    this.captionsSettings.queueCaption(vttCue.getCueAsHTML());
  }

  onExitCuePoint(vttCue) {}

  onError(error) {
    this.loggerService.error([error]);
  }

  onDataLoaded() {
    this.loggerService.log(['Loaded audio file.']);
    this.downloadingAudio = false;
  }

  onUserSeeked() {
    this.loggerService.log(['User seeked audio.']);

    if (this.api.canPlay) {
      this.api.play();
    }
  }

  onPlaybackRateSettingChange(speedOption: ISpeedOption) {
    (<any>this.api.getMasterMedia()).playbackRate = speedOption.rate;
  }

  onAudioEnabledChange(enabled: boolean) {
    if (enabled) {
      this.loadAudioSource();
    }

    if (this.hasCaptions) {
      this.loadCaptions();
    }
  }

  replayAudio(event) {
    event.preventDefault();

    if (this.audioVariant) {
      switch (this.audioVariant) {
        case "youtube":
          this.youTubePlayerService.replay();
          break;
      }

      return;
    }

    // user had audio muted, but now unmute since user choosing to play
    if (!this.audioSettings.audioOn.value) {
      this.audioSettings.toggleAudio(event);
      return;
    }

    // for mobile UI, allow show/hide of scrub bar - if user hides, assume they want to stop audio
    if (this.mediaQueryService.mediaBreakpointDown.md) {
      this.showAdvancedControls = !this.showAdvancedControls;

      if (!this.showAdvancedControls) {
        this.api.pause();
        return;
      }
    }

    if (this.api.canPlay) {
      this.api.seekTime(0);
      this.api.play();
    }
  }

  private resetMedia() {
    // clear prior content
    this.trackSource = null;
  }

  private loadAudioSource() {
    const audioSrc = this.assetService.getAudio(this.slug);

    if (!this.audioVariant) {
      this.downloadingAudio = environment.production; // otherwise have a constant spinning icon in dev till narration is provided
    }

    this.sources = [
      {
        src: audioSrc,
        type: 'audio/mp3'
      }
    ];
  }

  private loadCaptions() {
    this.loadCaptionsFromTranslationText(); // falls back to loading from WebVTT if translation not found
  }

  private loadCaptionsFromTranslationText() {
      this.captionsSettings.queueCaptionText('');
      this.loggerService.log([`Get translation path for ${this.l10nNode.pathSegments} in '${this.translocoService.getActiveLang()}' lang`, this.l10nNode]);
      const captionsL10n = this.translocoService.translate(
        this.l10nNode.getL10NPath('captions'),
        defaultsDeep({},
          // @todo may want to allow a slide-specific `config.l10nParams` to be passed here as well, similar to as in
          // TaskTitleComponent, but leaving out for now unless needed at some point
          baseL10nParams
        )
      );

      if (!captionsL10n) {
        // default to refer-to-content verbiage
        this.captionsSettings.queueCaption(document.createRange().createContextualFragment(
          this.translocoService.translate('shared.referToContent')
        ));
        return;
      }

      // content was found, show it
      this.captionsSettings.queueCaptionText(captionsL10n);
  }

  private loadCaptionsFromWebVTT() {
      this.trackSource = this.assetService.getCaption(this.slug);
  }
}
