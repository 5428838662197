import {PipeTransform, Pipe} from "@angular/core";
import {AssetService} from "../../core/services/asset.service";

@Pipe({
  name: 'moduleImage'
})
export class ModuleImagePipe implements PipeTransform {

  constructor(
    protected assetService: AssetService
  ) {}

  transform(filename: string): string {
    return this.assetService.getModuleImage(filename);
  }
}
