
import {filter} from 'rxjs/operators';
import {Component, ViewContainerRef, Inject, ErrorHandler, HostListener, HostBinding, Renderer2} from '@angular/core';
import {kebabCase} from "lodash";
import {LoggerService} from "./core/services/logger.service";
import {ToastrService} from 'ngx-toastr';
import {CaptionsSettingsService} from "./core/settings/captions-settings.service";
import {AudioSettingsService} from "./core/settings/audio-settings.service";
import {InterfaceInteractionStagerService} from "./core/engagement/interface-interaction-stager.service";
import {LoadingOverlayService} from "./core/engagement/loading-overlay.service";
import {IAppConfig, APP_CONFIG} from "./app.config";
import {TransparentBackdropService} from "./core/engagement/transparent-backdrop.service";
import {ApiService} from "./core/services/api.service";
import {SlideNavigationService} from "./core/engagement/slide-navigation.service";
import {KeyValueStoreService} from "./core/services/key-value-store.service";
import {AssetService} from "./core/services/asset.service";
import {PlatformService} from "./core/environment/platform.service";
import {KeyboardEventInterpreterService} from "./core/engagement/keyboard-event-interpreter.service";
import {EnvironmentService} from "./core/environment/environment.service";
import {CustomDataStoreConfig} from "./core/data-store/custom-data-store-config.service";
import {TrackJsErrorHandler, GoogleTagManagerService} from "northstar-foundation/angular";
import * as location_origin from "location-origin";
import {SessionService} from "./core/services/session.service";
import {PopoverService} from "./core/popover/popover.service";
import {RouterHistoryService} from "./core/services/router-history.service";
import {TimeOnTaskService} from "./core/services/time-on-task.service";
import {Router, NavigationEnd} from "@angular/router";
import {TranslocoService} from "@ngneat/transloco";
import {ProctorSessionForUserService} from "./core/services/proctor-session-for-user.service";
import {AssessmentTokenService} from "./core/services/assessment-token.service";
import {MediaQueryService} from "./core/environment/media-query.service";
import {ContentShadowManagerService} from "./core/engagement/content-shadow-manager.service";
import {ServerSettingsService} from "./core/settings/server-settings.service";
import {Angulartics2GoogleAnalytics} from "angulartics2/ga";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  hasMinHeight: boolean = true;

  /**
   * Store currently active language in a class, in case of needing to make lang-specific changes across app,
   * e.g. sizing of buttons
   */
  @HostBinding('class')
  lang: string;

  constructor(
    captionsSettingsService: CaptionsSettingsService,
    protected loggerService: LoggerService,
    protected apiService: ApiService,
    protected audioSettingsService: AudioSettingsService,
    protected dataStoreConfig: CustomDataStoreConfig,
    public sessionService: SessionService,
    public proctorSessionForUserService: ProctorSessionForUserService,
    public assessmentTokenService: AssessmentTokenService,
    public toastr: ToastrService,
    public vcr: ViewContainerRef,
    protected interfaceInteractionStagerService: InterfaceInteractionStagerService,
    public angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    protected loadingOverlayService: LoadingOverlayService,
    protected transparentBackdropService: TransparentBackdropService,
    protected keyValueStoreService: KeyValueStoreService,
    protected assetService: AssetService,
    protected platformService: PlatformService,
    protected keyboardEventInterpreterService: KeyboardEventInterpreterService,
    protected environmentService: EnvironmentService,
    public navigation: SlideNavigationService,
    @Inject(APP_CONFIG) public appConfig: IAppConfig,
    @Inject(ErrorHandler) protected trackJsErrorHandler: TrackJsErrorHandler,
    protected popoverService: PopoverService,
    protected routerHistory: RouterHistoryService,
    protected timeOnTaskService: TimeOnTaskService,
    protected router: Router,
    protected translocoService: TranslocoService,
    protected mediaQueryService: MediaQueryService,
    protected contentShadowManagerService: ContentShadowManagerService,
    protected serverSettingsService: ServerSettingsService,
    protected renderer2: Renderer2,
    protected gtmService: GoogleTagManagerService
  ) {
    this.angulartics2GoogleAnalytics.startTracking();
    this.trackJsErrorHandler.setAppConfig(this.appConfig);

    this.translocoService.langChanges$.subscribe(lang => {
      this.lang = `current-lang-${lang}`;

      this.renderer2.setAttribute(document.querySelector('html'), 'lang', kebabCase(lang));
    });

    this.router.events.pipe(
          filter(event => event instanceof NavigationEnd))
          .subscribe((end: NavigationEnd) => {
            if (this.timeOnTaskService.urlIsValidForTimeOnTask(end.url)) {
              this.timeOnTaskService.confirmLocationInfoInSessionOrRedirect();
              this.loggerService.log(['User navigated and URL valid for time on task', end.url]);
              this.timeOnTaskService.registerKeptAliveLocal();
            }
          });
  }

  @HostListener('document:click')
  keepSessionAliveViaClick() {
    // timeout tick of 1ms in order to avoid race condition with router events subscription above where time-on-task
    // keep-alive would happen 2x simultaneously
    setTimeout(() => {
      this.sessionService.registerKeptAliveLocal();

      if (this.timeOnTaskService.urlIsValidForTimeOnTask(this.router.routerState.snapshot.url)) {
        // this.loggerService.log(['User clicked and URL valid for time on task', this.router.routerState.snapshot.url]);
        this.timeOnTaskService.registerKeptAliveLocal();
      }
    }, 1);
  }
}
