import {Component, OnInit} from '@angular/core';
import {BaseTaskSlideComponent} from "./base-task-slide.component";
import {taskRecipes} from "../../app.config";
import {IMultipleChoiceOption} from "../interfaces/multiple-choice-option.interface";
import {IRecordedClick} from "../../core/engagement/interface-interaction-stager.service";
import {includes, each, findLastIndex, find} from "lodash";

export interface ITaskPathStepConfig {
  clickIds: (string|any)[],
  popoverIds?: string[],
  onIncorrectAnswer?: any,
  onCorrectAnswer?: any
}

/**
 * For simple sequential-click slides, represents one step in the sequence.
 */
export class TaskPathStep {
  completed: boolean = false;

  /**
   * Either correct click IDs as strings, or methods to take IRecordedClick and return boolean.
   *
   * @type {Array}
   */
  clickIds: (string|any)[] = [];

  /**
   * Popovers to show on incorrect click.
   *
   * @type {Array}
   */
  popoverIds: string[] = [];

  /**
   * Handler method for if click is incorrect. Accepts IRecordedClick argument.
   */
  onIncorrectAnswer: any;

  /**
   * Handler method for if click is correct. Accepts IRecordedClick argument.
   */
  onCorrectAnswer: any;

  constructor(config: ITaskPathStepConfig) {
    this.clickIds = config.clickIds;
    this.popoverIds = config.popoverIds;
    this.onIncorrectAnswer = config.onIncorrectAnswer;
    this.onCorrectAnswer = config.onCorrectAnswer;
  }

  complete() {
    this.completed = true;
  }

  clickValid(recordedClick: IRecordedClick) {
    let valid = false;

    each(this.clickIds, (clickId: string|any) => {
      // compare straight clickIds as strings (most common case)
      if (typeof clickId === 'string' && clickId === recordedClick.id) {
        valid = true;
        return false; // break out of "each" early
      }

      // compare recordecClick against a function, for more flexible behavior
      // e.g. accept upper or lowercase strings as names in File > Save UIs
      if (typeof clickId === 'function' && clickId(recordedClick)) {
        valid = true;
        return false; // break out of "each" early
      }
    });

    return valid;
  }
}

/**
 * For simple sequential-click slides, represents a series of steps to successful completion of slide. The last step in
 * a sequence equals success for the slide's completion.
 */
export class TaskPath {
  /**
   * In case of multiple TaskPaths on a single slide, track which one is active.
   * @type {boolean}
   */
  started: boolean = false;

  constructor(
    public steps: TaskPathStep[]
  ) {
  }

  start() {
    this.started = true;
  }

  getLastCompletedStepIndex(): number {
    return findLastIndex(this.steps, (step: TaskPathStep) => step.completed);
  }

  getCurrentStepIndex(): number {
    return this.getLastCompletedStepIndex() + 1;
  }

  /**
   * Returns whether a click is a valid choice for the current state of the TaskPath.
   *
   * @param recordedClick
   * @returns {boolean}
   */
  clickValid(recordedClick: IRecordedClick): boolean {
    const index = this.getCurrentStepIndex();

    if (typeof this.steps[this.getCurrentStepIndex()] === 'undefined') {
      return false;
    }

    return this.steps[this.getCurrentStepIndex()].clickValid(recordedClick);
  }

  /**
   * Returns whether a click is a valid way of starting this path.
   *
   * @param recordedClick
   * @returns {boolean}
   */
  startsWithClick(recordedClick: IRecordedClick): boolean {
    return this.steps[0].clickValid(recordedClick);
  }
}

/**
 * Generator for properly formed TaskPath[] from a shortcut method.
 */
export class TaskPathBuilder {
  constructor() {}

  build(taskPathsData: ITaskPathStepConfig[][]): TaskPath[] {
    let builtPaths: TaskPath[] = [];

    each(taskPathsData, (taskPathData: ITaskPathStepConfig[]) => {
      let builtPathSteps: TaskPathStep[] = [];

      each(taskPathData, (taskStepData: ITaskPathStepConfig) => {
        builtPathSteps.push(
          new TaskPathStep(taskStepData)
        );
      });

      builtPaths.push(new TaskPath(builtPathSteps));
    });

    return builtPaths;
  }
}

export interface IMultipleChoiceSingleInterpreterConfig {
  taskSuccessClickIds: string[],
  taskPermittedClickIds: string[],
}

@Component({
  templateUrl: './base-task-slide.component.html'
})
export class BaseTaskRecipeMultipleChoiceSingleSlide extends BaseTaskSlideComponent {
  recipeType = taskRecipes.multipleChoiceSingle;

  /**
   * Clickable IDs that should result in passing the task.
   * Relates to `recordClick` attribute on element.
   *
   * @type {Array}
   */
  taskSuccessClickIds: string[] = [];

  /**
   * Clickable IDs that are neither successes nor failures, e.g. button for a menu.
   *
   * @type {Array}
   */
  taskPermittedClickIds: string[] = [];

  /**
   * Shortcut way of defining taskSuccessClickIds and taskPermittedClickIds for sequential interactions,
   * to simplify onRecordedClick and onIncorrectAnswer in basic cases.
   * e.g. PracticeLogOutMacComponent: 'menu-bar-apple' needed then 'apple-menu-log-out' for success.
   *
   * @type {Array}
   */
  taskPaths: TaskPath[] = [];

  /**
   * Generator for TaskPaths so that `new TaskPath()` and `new TaskPathStep()` don't populate everywhere.
   *
   * @type {TaskPathBuilder}
   */
  taskPathBuilder: TaskPathBuilder = new TaskPathBuilder();

  /**
   * Programmatic way of populating template with options and providing component w/logic for handling. Obviates need
   * for explicitly setting `taskSuccessClickIds`. More DRY way of handling
   * hotspots in that template can then just use ngFor loop rather than each individual anchor.
   *
   * taskOptions: IMultipleChoiceOption[] = [
   *     {
   *       id: 'browser',
   *       label: 'Internet browser'
   *     },
   *     {
   *       id: 'antivirus',
   *       label: 'Antimalware, or antivirus',
   *       valid: true
   *     },
   *     {
   *       id: 'os',
   *       label: 'Operating system'
   *     },
   *     {
   *       id: 'multimedia',
   *       label: 'Multimedia software'
   *     }
   *   ];
   *
   *
   * @type {Array}
   */
  taskOptions: IMultipleChoiceOption[] = [];

  /**
   * Convenience method for setting up a slide that's a multiple-choice click question,
   * e.g. Identify the desktop computer.
   */
  protected setupRecipeMultipleChoiceSingle() {
    // set up sequential paths' shortcut configuration
    if (this.taskPaths.length > 0) {
      this.subscriptions['stager'] = this.stagerService.recordedClick$.subscribe(
        this.handleMultipleChoiceSingleResponseClickWithPaths.bind(this)
      );
      return;
    }

    // set up non-sequential configuration
    if (this.taskOptions.length > 0) {
      this.subscriptions['stager'] = this.stagerService.recordedClick$.subscribe(
        this.handleMultipleChoiceSingleResponseClick.bind(this, this.generateTaskOptionsHandlerConfig())
      );
      return;
    }

    // set up basic low-level configuration
    this.subscriptions['stager'] = this.stagerService.recordedClick$.subscribe(
      this.handleMultipleChoiceSingleResponseClick.bind(this, this.getDefaultHandlerConfig())
    );
  }

  protected generateTaskOptionsHandlerConfig(): IMultipleChoiceSingleInterpreterConfig {
    const length = this.taskOptions.length;
    let successClickIds = [];

    for (let i = 0; i < length; i++) {
      let option = this.taskOptions[i];

      if (typeof option.valid !== 'undefined' && option.valid) {
        successClickIds.push(option.id);
      }
    }

    return {
      taskPermittedClickIds: [],
      taskSuccessClickIds: successClickIds
    }
  }

  protected getDefaultHandlerConfig(): IMultipleChoiceSingleInterpreterConfig {
    return {
      taskPermittedClickIds: this.taskPermittedClickIds,
      taskSuccessClickIds: this.taskSuccessClickIds
    };
  }

  protected handleMultipleChoiceSingleResponseClick(
    config: IMultipleChoiceSingleInterpreterConfig,
    recordedClick: IRecordedClick
  ) {
    const task = this.navigationService.getTaskForSlideComponent(this.constructor);

    if (this.multipleChoiceSingleResponseClickIsSuccess(config, recordedClick)) {
      // track the successful click's ID for data purposes but also because the way we respond to the success
      // may differ from one to another
      this.responseCollectionService.addSuccess(this.constructor, task, recordedClick.id);
      return;
    }

    if (this.multipleChoiceSingleResponseClickIsFailure(config, recordedClick)) {
      this.responseCollectionService.addFailure(this.constructor, task, recordedClick.id);
    }

    this.onRecordedClick(recordedClick);
  }

  protected handleMultipleChoiceSingleResponseClickWithPaths(
    recordedClick: IRecordedClick
  ) {
    const activePath: TaskPath = find(this.taskPaths, {started: true});

    if (!activePath) {
      // user hasn't yet started a path, so see if current click matches one of the paths
      const newPath = find(this.taskPaths, (path: TaskPath) => path.startsWithClick(recordedClick));

      if (!newPath) {
        // user clicked incorrect area and hasn't started on legit path
        this.setResponseAsFailure(recordedClick.id);
        return;
      }

      newPath.start();
      newPath.steps[0].complete();

      if (newPath.steps.length <= 1) {
        this.setResponseAsSuccess();
        return;
      }

      // do side effects if relevant for multi-step path
      if (typeof newPath.steps[0].onCorrectAnswer !== 'undefined') {
        setTimeout(() => newPath.steps[0].onCorrectAnswer(recordedClick), 1);
      }

      this.onRecordedClick(recordedClick);

      return;
    }

    // user has started a path, so check if made right next choice on that path
    const currentIndex = activePath.getCurrentStepIndex();
    const currentStep = activePath.steps[currentIndex];

    if (typeof currentStep === 'undefined') {
      // user probably already succeeded and is clicking wrong answer afterward - avoid needing various handlers below
      return;
    }

    if (!activePath.clickValid(recordedClick)) {

      // use custom onIncorrectAnswer() per step if available
      if (typeof activePath.steps[currentIndex].onIncorrectAnswer !== 'undefined') {
        setTimeout(() => currentStep.onIncorrectAnswer(recordedClick), 1);
      }

      this.setResponseAsFailure(recordedClick.id);
      return;
    }

    if (typeof activePath.steps[currentIndex].onCorrectAnswer !== 'undefined') {
      setTimeout(() => currentStep.onCorrectAnswer(recordedClick), 1);
    }

    currentStep.complete();

    if (activePath.steps.length - 1 === currentIndex) {
      this.setResponseAsSuccess();
    } else {
      this.onRecordedClick(recordedClick);
    }
  }

  protected onRecordedClick(recordedClick: IRecordedClick) {
    // override in child classes if needing access to every time user clicks, even if not a success/failure

    // if just needing to respond to success or failure, it'd be better
    // to override `onResponseCompleteSuccess(response: ResponseModel)`

    return;
  }

  protected multipleChoiceSingleResponseClickIsSuccess(
    config: IMultipleChoiceSingleInterpreterConfig,
    recordedClick: IRecordedClick
  ) {
    return includes(config.taskSuccessClickIds, recordedClick.id);
  }

  protected multipleChoiceSingleResponseClickIsFailure(
    config: IMultipleChoiceSingleInterpreterConfig,
    recordedClick: IRecordedClick
  ) {
    return !includes(config.taskPermittedClickIds, recordedClick.id);
  }
}
