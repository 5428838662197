
import {of} from 'rxjs';
import {switchMap, catchError} from "rxjs/operators";
import {Injectable, Inject} from "@angular/core";
import {Router} from "@angular/router";
import {LoggerService} from "../services/logger.service";
import {trim, assign, each} from "lodash";
import {APP_CONFIG, IAppConfig} from "../../app.config";
import {SlimLoadingBarService} from "ng2-slim-loading-bar";
import {ApiService, apiEndpoints} from "../services/api.service";
import {EnvironmentService} from "../environment/environment.service";
import {AssessmentModule, Sponsor, ModuleTopic, IModuleSoftwareVersion} from "northstar-foundation";
import {CustomDataStore} from "../data-store/custom-data-store.service";
import {analyticsValues, ProctorSessionForUser} from "northstar-foundation";
import {SessionService} from "../services/session.service";
import {Angulartics2} from "angulartics2";
import {ProctorSessionForUserService} from "../services/proctor-session-for-user.service";
import {AssessmentTokenService} from "../services/assessment-token.service";
import {GoogleTagManagerService} from "northstar-foundation/angular";

declare var trackJs: any;

interface IRemoteValidationResponse {
  valid: boolean,
}

interface IRemoteValidationResponseSuccess extends IRemoteValidationResponse {
  site: any,
  user_name: string,
  start: string,
  currentModule: AssessmentModule,
  currentTopic: ModuleTopic,
  currentSoftwareVersion: IModuleSoftwareVersion,
  currentUserName: string,
  currentSponsor: Sponsor,
  currentProctorSessionForUser: ProctorSessionForUser,
  currentProctor: any
}

interface IRemoteValidationResponseError extends IRemoteValidationResponse {
  token: string[],
  module: string[]
}

interface ICleanedData {
  moduleNum: number|null,
  token: string|null,
  cacheVersion: number
}

/**
 * Service to bootstrap data based on query parameters
 *
 * Initializes services involved with such data, with idea that components or other services
 * in the app may interact w/those services directly afterward.
 *
 * e.g. ?name=Joe&module=1&p=0
 */
@Injectable()
export class BootstrapDataService {
  private user_name: string;
  private proctor: any; // @todo better typing
  private module: AssessmentModule;
  private start: string;
  private token: string;
  private cacheVersion: number = 0;
  private valid: boolean = false;

  public errors: any;

  constructor(
    protected logger: LoggerService,
    protected apiService: ApiService,
    protected router: Router,
    @Inject(APP_CONFIG) protected appConfig: IAppConfig,
    protected slimLoadingBarService: SlimLoadingBarService,
    protected environmentService: EnvironmentService,
    protected dataStore: CustomDataStore,
    private angulartics2: Angulartics2,
    private gtmService: GoogleTagManagerService,
    protected sessionService: SessionService,
    protected assessmentTokenService: AssessmentTokenService,
    protected proctorSessionForUserService: ProctorSessionForUserService,
  ) {}

  /**
   * Validate data provided in query params and initialize service props.
   *
   * @param queryParams
     */
  bootstrap(queryParams: Object) {
    // in case user clicking back in browser or something, prevent historical data
    // from affecting page presentation
    this.reset();

    const cleanedData: ICleanedData = BootstrapDataService.cleanRequestData(queryParams);
    const tokenHash = cleanedData.token ? AssessmentTokenService.getTokenHash(cleanedData.token) : '';

    this.logger.log(['cleanedData', {
      moduleNum: cleanedData.moduleNum,
      token: cleanedData.token ? tokenHash : 'none',
      cacheVersion: cleanedData.cacheVersion
    }]);

    if (typeof trackJs !== 'undefined') {
      trackJs.configure({
        version: cleanedData.cacheVersion || '0',
        sessionId: tokenHash || '0'
      });
    }

    return this.bootstrapRequest(cleanedData);
  }

  /**
   *
   * @param cleanedData
   * @returns {any}
     */
  protected bootstrapRequest(cleanedData: ICleanedData) {
    this.cacheVersion = cleanedData.cacheVersion;
    this.assessmentTokenService.setToken(cleanedData.token);

    return this.validateRemoteParams(cleanedData);
  }

  isBootstrapped() {
    return this.valid;
  }

  hasErrors() {
    return !this.valid;
  }

  protected reset() {
    this.user_name = null;
    this.module = null;
    this.errors = {
      module: false,
      token: false
    };
    this.valid = false;
  }

  protected static cleanRequestData(queryParams: Object): ICleanedData {
    return {
      moduleNum: BootstrapDataService.cleanQueryParamInt(queryParams['module']),
      token: BootstrapDataService.cleanQueryParamString(queryParams['token']),
      cacheVersion: BootstrapDataService.cleanQueryParamString(queryParams['cacheversion'])
    };
  }

  protected static cleanQueryParamString(toClean: string, defaultValue = null) {
    return trim(toClean) || defaultValue;
  }

  protected static cleanQueryParamInt(toClean: any, defaultValue = null) {
    return parseInt(toClean, 10) || defaultValue;
  }

  /**
   *
   * @returns {any}
   */
  protected validateRemoteParams(cleanedData: ICleanedData) {
    if (!cleanedData.moduleNum) {
      return of(true).pipe(
        switchMap(this.onRemoteParamValidationError.bind(this, {
          valid: false,
          module: ['invalid']
        }))
      );
    }

    let data = {
      module: cleanedData.moduleNum
    };

    if (cleanedData.token) {
      assign(data, {
        token: cleanedData.token
      });
    }

    if (this.appConfig.apiMocks) {
      return this.mockRemoteValidation(data);
    }

    return this.apiService.httpPost(apiEndpoints.validateAssessmentParameters, data)
      .pipe(
        switchMap(this.onRemoteParamValidationSuccess.bind(this)),
        catchError(this.onRemoteParamValidationError.bind(this))
      );
  }

  protected mockRemoteValidation(data) {
    this.setModule(this.dataStore.get('module', data.module));

    this.valid = true;

    return of(true);
  }

  protected onRemoteParamValidationSuccess(response: IRemoteValidationResponseSuccess) {
    this.dataStore.add('module_software_version', response.currentSoftwareVersion);
    this.dataStore.add('module_topic', response.currentTopic);

    this.start = response.start;

    // sessionService.user might have already been set by MeResolve
    // @todo refactor to make MeResolve and bootstrap sequence more logically integrated; current setup remnant of very original logged-out sequence during V2 launch
    if (response.currentUserName && !this.sessionService.user) {
      this.sessionService.setUser(response.currentUserName);
    } else {
      this.logger.warn('No user data provided to bootstrap.');
    }

    if (response.currentProctorSessionForUser) {
      this.proctorSessionForUserService.setSession(response.currentProctorSessionForUser);
    }

    this.sessionService.setSponsor(response.currentSponsor);
    this.setProctor(response.currentProctor);
    this.setModule(<AssessmentModule>this.dataStore.add('module', response.currentModule));

    // custom dimensions and metrics
    // https://support.google.com/analytics/answer/2709828?hl=en#example-metrics
    (<any>window).ga('set', analyticsValues.dimensions.locationIsPublic.analyticsKey, this.sessionService.sponsor ? 'N' : 'Y');
    (<any>window).ga('set', analyticsValues.dimensions.location.analyticsKey, this.sessionService.sponsor ? `${this.sessionService.sponsor.id} - ${this.sessionService.sponsor.name}` : 'N/A');

    if (typeof trackJs !== 'undefined') {
      trackJs.addMetadata('NS Location', this.sessionService.sponsor ? '' + this.sessionService.sponsor.id : '0'); // coerce int to str
    }

    this.valid = true;

    return of(this.valid);
  }

  protected onRemoteParamValidationError(response: IRemoteValidationResponseError) {
    const responseDataErrorKeys = [
      'module',
      'token'
    ];

    each(responseDataErrorKeys, key => {
      let valueValid = typeof response[key] === 'undefined';

      if (!valueValid) {
        this.errors[key] = true;

        if (typeof trackJs !== 'undefined') {
          trackJs.track(`Bootstrap error: '${key}' - ${response[key].toString()}`);
        }
      }
    });

    this.logger.log('Finishing remotelyValid observable sequence.');

    return of(this.valid);
  }

  setProctor(proctor: any) {
    if (proctor) {
      this.proctor = proctor;
    } else {
      this.logger.warn('No proctor data provided to bootstrap.');
    }
  }

  setModule(module: AssessmentModule) {
    this.module = module;

    if (typeof trackJs !== 'undefined') {
      trackJs.addMetadata('NS Module', `${module.id} - ${module.summary}`);
    }
  }

  getModule(): AssessmentModule {
    return this.module;
  }

  getProctor(): any {
    return this.proctor;
  }

  getStartTime() {
    return this.start;
  }

  getCacheVersion() {
    return this.cacheVersion;
  }

}
