import {Component, Input, HostBinding} from "@angular/core";
import {FadeOut} from "../../animations";

/**
 * Overlay of shadow/gradient to hint at more content being available past the scroll.
 */
@Component({
  selector: 'content-shadow',
  templateUrl: 'content-shadow.component.html',
  styleUrls: ['content-shadow.component.scss'],
  animations: [FadeOut(250)]
})
export class ContentShadowComponent {
  @HostBinding('class')
  @Input()
  anchor: 'top'|'bottom'|'right'|'left';

  @HostBinding('class.active')
  active: boolean = false;

  setShadowActive(test: boolean) {
    this.active = test;
  }
}
